.comment-detail {
  padding: 20px 16px calc(env(safe-area-inset-bottom) + 65px);
}
.comment-detail .comment {
  position: relative;
}
.comment-detail .comment-reward {
  position: absolute;
  z-index: 11;
  right: 15px;
  top: 5px;
  background: #fff center no-repeat;
  background-size: cover;
  width: 67px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  color: #5574ff;
}
.comment-detail .comment-user {
  display: flex;
  align-items: center;
}
.comment-detail .comment-user .avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 50%;
}
.comment-detail .comment-user .avatar img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comment-detail .comment-user .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.comment-detail .comment-user .info .name {
  font-size: 0.28rem;
  font-weight: bold;
  color: #3d3d3d;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.comment-detail .comment-user .info .name img {
  margin-left: 5px;
  height: 25px;
}
.comment-detail .comment-user .info .time {
  font-size: 0.26rem;
  color: #999999;
}
.comment-detail .comment-main {
  padding-left: 40px;
}
.comment-detail .comment-content {
  margin-top: 9px;
  font-size: 0.26rem;
  color: #3d3d3d;
}
.comment-detail .comment-img {
  margin-top: 11px;
  display: flex;
  flex-wrap: wrap;
}
.comment-detail .comment-img .item {
  width: 1.86rem;
  height: 1.28rem;
  margin-top: 5px;
  margin-right: 5px;
}
.comment-detail .comment-img .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.comment-detail .comment-phone-info {
  margin-top: 22px;
  display: flex;
  align-items: center;
}
.comment-detail .comment-phone-info .device {
  flex: 1;
  width: 0;
  overflow: hidden;
  font-size: 0.22rem;
  color: #999999;
}
.comment-detail .comment-phone-info .count {
  display: flex;
  align-items: center;
}
.comment-detail .comment-phone-info .count .like {
  margin-left: 26px;
}
.comment-detail .comment-phone-info .count .item {
  display: flex;
  align-items: center;
}
.comment-detail .comment-phone-info .count .item .icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.comment-detail .comment-phone-info .count .icon {
  margin-right: 5px;
}
.comment-detail .comment-detail-line {
  width: 100%;
  height: 10px;
  margin-top: 25px;
  margin-bottom: 16px;
  border-radius: 1px;
  background: #f6f5f5;
}

.comment-detail .comment-info {
  display: flex;
  align-items: center;
}

.comment-detail .comment-info .title {
  font-size: 0.28rem;
  font-weight: bold;
  color: #3d3d3d;
}
.comment-detail .comment-info .count {
  font-size: 0.26rem;
}
.comment-detail .comment-reply .reply-item {
  margin-top: 26px;
  background-color: #fff;
  position: relative;
}
.comment-detail .comment-reply .reply-item .avatar {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.comment-detail .comment-reply .reply-item .avatar img {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-detail .comment-reply .reply-item .name {
  font-size: 0.28rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  color: #3d3d3d;
}
.comment-detail .comment-reply .reply-item .name img {
  margin-left: 5px;
  height: 25px;
}
.comment-detail .comment-reply .reply-item .time {
  margin-top: 2.5px;
  font-size: 0.26rem;
}
.comment-detail .comment-reply .reply-item .comment-second-main {
  margin-top: 16px;
  padding-left: 40px;
}
.comment-detail .comment-reply .reply-item .content {
  font-size: 0.26rem;
  color: #3d3d3d;
}
.comment-detail .comment-reply .reply-item .like {
  display: flex;
  align-items: center;
}
.comment-detail .comment-reply .reply-item .like .icon {
  margin-right: 5px;
}
.comment-detail .comment-reply .reply-item .like.active .icon {
  color: #ffc000;
}
.comment-detail .comment-operate {
  position: fixed;
  z-index: 999;
  bottom: 0;
  background-color: #fff;
  left: 0;
  right: 0;
  padding-bottom: env(safe-area-inset-bottom);
}
.comment-detail .comment-operate.inputing {
  padding-bottom: 0;
}
.comment-detail .comment-operate .container {
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: #fff;
}
.comment-detail .comment-operate .input {
  flex: 1;
  width: 0;
  overflow: hidden;
  background-color: #f6f5f5;
  border-radius: 7px;
  display: flex;
  align-items: center;
}
.comment-detail .comment-operate .input input {
  flex: 1;
  width: 0;
  padding: 13px 15px;
  background: none;
  border: none;
  font-size: 0.24rem;
  color: #999999;
}
.comment-detail .comment-operate .input .icon {
  padding: 5px 10px;
  font-size: 16px;
}
.comment-detail .comment-operate .input .btn {
  font-size: 16px;
  padding: 5px 10px;
}
.comment-detail .comment-operate .input .btn:before {
  content: "发布";
}
.comment-detail .comment-operate .input .btn.active {
  color: #ffc000;
}
.comment-detail .comment-operate .count {
  margin-left: 19px;
  display: flex;
  align-items: center;
}
.comment-detail .comment-operate .count .item {
  font-size: 0.2rem;
  color: #3d3d3d;
  text-align: center;
}
.comment-detail .comment-operate .islike {
  margin-left: 27px;
}
.comment-detail .comment-operate .count .item .icon {
  width: 20px;
  height: 20px;
  font-size: 18px;
}
.comment-detail .comment-operate .count .item.active .icon {
  color: #ffc000;
}
