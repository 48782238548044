.gift-detail-container {
  padding: 24px 16px calc(env(safe-area-inset-bottom) + 75px);
  background-color: #fff;
  position: relative;
}

.gift-detail-container .detail-top {
  display: flex;
  align-items: center;
}

.gift-detail-container .detail-top img {
  width: 1.4rem;
  height: 1.4rem;
}

.gift-detail-container .detail-top .top-right {
  margin-left: 8px;
}

.gift-detail-container .detail-top .gift-name {
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
}

.gift-detail-container .detail-top .gift-time {
  margin-top: 4px;
  font-size: 0.2rem;
  color: rgba(61, 61, 61, 0.7);
}

.gift-detail-container .detail-every {
  margin-top: 20px;
}

.gift-detail-container .detail-every .every-title {
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
}

.gift-detail-container .detail-every .every-content {
  margin-top: 4px;
  font-size: 0.24rem;
  color: rgba(61, 61, 61, 0.7);
}

.gift-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 14px 14px;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.gift-btn .btn {
  display: block;
  width: 93.35%;
  height: 44px;
  margin: 0 auto;
  border-radius: 22px;
  background-color: #fcf15d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.32rem;
  font-weight: 500;
  color: #3d3d3d;
}

.gift-code {
  flex: 1 1;
  padding: 10px 0 10px 30px;
  background-color: #f3f3f3;
  text-align: left;
  font-size: 0.28rem;
  border-radius: 5px;
}

.gift-copy {
  padding: 10px 25px;
  margin-left: -15px;
  font-size: 0.28rem;
  border-radius: 5px;
  background-color: #fcf15d;
  color: #000;
  text-align: center;
}

.gift-desc {
  margin-top: 20px;
  text-align: center;
  font-size: 0.24rem;
  color: #999999;
}
