.welfareCircle {
  width: 10px;
  height: 10px;
  background: #fb7574;
  border-radius: 50%;
}

.game-index .game-title {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  padding-top: env(safe-area-inset-top);
  background-color: #ffc000;
  padding-bottom: 5px;
}
.game-index .game-title .container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  position: relative;
}
.game-index .game-title .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.36rem;
  color: #fff;
  font-weight: bold;
}
.game-index .game-title .title .anticon {
  font-size: 0.3rem;
  color: #fff;
  margin-left: 2.5px;
}
.game-index .game-title .search {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 10px;
  font-size: 20px;
  color: #fff;
}

.game-detail .video-main {
  width: 90%;
  height: 4rem;
  display: inline-block;
  overflow: hidden;
  position: relative;
  background-color: #202737;
  border-radius: 10px;
}

.game-detail .game-video {
  /* padding-top: 60%; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #202737;
  border-radius: 10px;
}
.game-detail .game-video .video-react .video-react-video {
  box-shadow: inset 0 -4px 8px rgba(0, 0, 0, 0.8);
  object-fit: cover;
  border-radius: 10px;
}
.game-detail .game-video > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #202737;
}

.game-detail .game-imgs {
  background-color: #202737;
  padding-left: 11px;
  position: relative;
}
.game-detail .game-imgs .container {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  display: flex;
  margin-left: -10px;
}
.game-detail .game-imgs .container::-webkit-scrollbar {
  display: none;
}

.game-detail .game-imgs .container .scrollbar {
  position: absolute;
  bottom: 19px;
  left: 50%;
  height: 2px;
  width: 48px;
  transform: translateX(-50%);
  background-color: #dbdfe2;
  border-radius: 110px;
  overflow: hidden;
}
.game-detail .game-imgs .container .scrollbar .thumb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #ffc000;
  transform: translateX(-50%);
}

.game-detail .game-imgs .container .img {
  padding-left: 11px;
  padding-bottom: 34px;
}

.game-detail .game-imgs .bannerImg {
  position: relative;
  display: inline-block;
  width: 33.333%;
  height: 4rem;
  margin-left: 8px;
}

.top-shadow,
.bottom-shadow {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 10px;
  left: 0;
  right: 0;
}

.top-shadow {
  top: 0;
  background: linear-gradient(to bottom, #202737, transparent);
}

.bottom-shadow {
  bottom: 0;
  background: linear-gradient(to top, #202737, transparent);
}

.game-detail .game-imgs img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.game-index .game.active .mask {
  visibility: visible;
}
.game-index .game.active .types {
  visibility: visible;
}
.game-index .game-menu {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 45px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.game-index .game-menu-item {
  width: 33.333%;
  position: relative;
  text-align: center;
  font-size: 0.28rem;
  padding-bottom: 7.5px;
  transition: all 0.25s;
}
.game-index .game-menu-item:before {
  content: "";
  display: block;
  width: 25px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s;
}
.game-index .game-menu-item.active {
  font-size: 0.34rem;
  color: #000;
  font-weight: bold;
}
.game-index .game-menu-item.active:before {
  background-color: #ffc000;
}
.game-index .game-main {
  padding-top: calc(env(safe-area-inset-top) + 45px);
}
.game-index .game-main-item {
  background: #f6f7f9;
  display: none;
}
.game-index .game-main-item.active {
  display: block;
  min-height: calc(100vh - env(safe-area-inset-top) - 110px);
}
.game-index-type .types {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 45px);
  bottom: calc(env(safe-area-inset-bottom) + 65px);
  width: 1.46rem;
  background-color: #f5f6f8;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s;
}
.game-index-type .types .type {
  height: 1.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  overflow: hidden;
  position: relative;
  color: rgba(61, 61, 61, 0.7);
  background-color: #f6f7f9;
  font-size: 0.28rem;
}
.game-index-type .types .type:before {
  content: "";
  display: none;
  width: 3px;
  height: 20px;
  background-color: #fcc433;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 166px;
}
.game-index-type .types .type.active {
  color: #fec106;
  background-color: #fff;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}
.game-index-type .types .type.active:before {
  display: block;
}
.game-index-type .mains {
  padding-left: 1.46rem;
}
.game-index-type .mains .main {
  height: 100%;
  position: relative;
  outline: hidden;
  display: none;
}
.game-index-type .mains .main.active {
  display: block;
}
.game-index .game-good {
  padding: 13px 14px 20px;
}

.game-index .game-good .groups {
  margin-bottom: 0.1rem;
}
.game-index .game-good .groups .group {
  margin-top: 16px;
}
.game-index .game-good .groups .group:first-child {
  margin-top: auto;
}
.game-index .game-good .groups .head {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.game-index .game-good .groups .head .name {
  flex: 1;
  font-weight: bold;
  font-size: 0.34rem;

  color: #333333;
}
.game-index .game-good .groups .head .more {
  font-size: 0.24rem;
  color: #999;
}
.game-index .game-good .groups .body {
  padding: 5px 0;
  background: #fff;
  border-radius: 8px;
}
.game-index .game-good .groups .body .item {
  padding: 5px 0;
  width: 33.333%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.game-index .game-good .groups .body .item .icon {
  flex-shrink: 0;
  width: 1.32rem;
  height: 1.32rem;
  margin-bottom: 0.14rem;
  position: relative;
}
.game-index .game-good .groups .body .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.game-index .game-good .groups .body .item .icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: deeppink;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  max-width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  padding: 1px 2.5px;
}
.game-index .game-good .groups .body .item .name {
  width: 90%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 0.24rem;
  font-weight: 500;
  color: #3d3d3d;
}
.game-index .game-good .groups .body .item .branch {
  margin-top: 2.5px;
  font-size: 12px;
  color: #66554d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid rgba(102, 85, 77, 0.4);
  border-radius: 8px;
  padding: 2.5px 5px;
  line-height: 1.25;
  font-weight: bold;
}
.game-index .game-type {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.game-index .game-type .banners {
  padding: 13px 14px 0;
}
.game-index .game-type .head {
  position: sticky;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 45px);
  left: 1.58rem;
  right: 0;
  height: 45px;
  background-color: #f5f6f8;
  display: flex;
  align-items: center;
}
.game-index .game-type .head .item {
  width: 33.333%;
  text-align: center;
  padding: 6px 19px;
  font-size: 0.28rem;
  color: #999;
}
.game-index .game-type .head .item.active {
  font-weight: bold;
  color: #fec106;
}
.game-index .game-type .body {
  /* min-height: calc(100vh - env(safe-area-inset-top) - 150px); */
  /* margin-top: 45px; */
  padding: 4px 11px 0;
}

.game-index .game-type.notag .head {
  display: none;
}
.game-index .game-type.notag .body {
  margin-top: 10px;
}
.game-index .game-hot-top-bg {
  background-color: #fff;
}
.game-index .game-hot-top {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding-bottom: 10px;
  background: center top no-repeat;
  background-size: 100% auto;
  display: flex;
}
.game-index .game-hot-top-item {
  padding: 10px;
  flex: 1;
  width: 33.333%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.game-index .game-hot-top-item .icon {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-top: 20px;
  padding: 7.5px 20px 0 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-bottom: 2.5px;
  background: center center no-repeat;
  background-size: contain;
}
.game-index .game-hot-top-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}
.game-index .game-hot-top-item:nth-child(2) .icon {
  width: 100px;
  height: 100px;
  margin-top: 0;
  padding: 10px 25px 0 25px;
}
.game-index .game-hot-top-item .name {
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-index .game-hot-top-item .info {
  color: #ccc;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  padding: 2.5px 0;
}
.game-index .game-hot-top-item .btn {
  color: #fff;
  background-color: #fe8900;
  border-radius: 4px;
  padding: 3.5px 7.5px;
  font-size: 12px;
}
.game-index .game-hot-list {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.game-index .game-hot-list-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.2rem;
  border-radius: 8px;
}
.game-index .game-hot-list-item .index {
  width: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 5px;
}
.game-index .game-hot-list-item .icon {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.game-index .game-hot-list-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.game-index .game-hot-list-item .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
}
.game-index .game-hot-list-item .icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: deeppink;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  max-width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  padding: 1px 2.5px;
}
.game-index .game-hot-list-item .subject {
  flex: 1;
  width: 0;
  /* border-bottom: 1px solid #dedede;
               padding-bottom: .2rem;*/
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.game-index .game-hot-list-item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.game-index .game-hot-list-item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.28rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.game-index .game-hot-list-item .desc {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
  font-size: 13px;
}
.game-index .game-hot-list-item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.game-index .game-hot-list-item .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ff5e00;
  margin-bottom: 0.05rem;
  font-size: 12px;
}
.game-index .game-hot-list-item .tag {
  display: inline-block;
  margin-right: 5px;
}
.game-index .game-hot-list-item .tag:nth-child(1) {
  color: #ff4200;
}
.game-index .game-hot-list-item .tag:nth-child(2) {
  color: #75d100;
}
.game-index .game-hot-list-item .tag:nth-child(3) {
  color: #2893ff;
}
.game-index .game-hot-list-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.game-index .game-new {
  padding: 10px 10px 0 10px;
}
.game-index .game-new-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.2rem;
  border-radius: 8px;
}
.game-index .game-new-item .index {
  width: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 5px;
}
.game-index .game-new-item .icon {
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.game-index .game-new-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.game-index .game-new-item .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
}
.game-index .game-new-item .icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: deeppink;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  max-width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  padding: 1px 2.5px;
}
.game-index .game-new-item .subject {
  flex: 1;
  width: 0;
  /*border-bottom: 1px solid #dedede;
            padding-bottom: .2rem;*/
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.game-index .game-new-item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.game-index .game-new-item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.28rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.game-index .game-new-item .desc {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
  font-size: 13px;
}
.game-index .game-new-item .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ff5e00;
  font-size: 12px;
}
.game-index .game-new-item .tag {
  display: inline-block;
  margin-right: 5px;
}
.game-index .game-new-item .tag:first-child {
  margin-left: 01px;
}
.game-index .game-new-item .tag:nth-child(1) {
  color: #ff4200;
}
.game-index .game-new-item .tag:nth-child(2) {
  color: #75d100;
}
.game-index .game-new-item .tag:nth-child(3) {
  color: #2893ff;
}
.game-index .game-new-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.game-index .search {
  background-color: #fff;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  align-content: center;
  padding: calc(env(safe-area-inset-top) + 4px) 16px 4px;
}
.game-index .search .searched {
  flex: 1;
  width: 0;
  background-color: #f7f7f7;
  display: flex;
  padding: 9px 16px 10px;
  align-items: center;
  border-radius: 299px;
}
.game-index .search .searched .icon {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}
.game-index .search .searched .text {
  color: #d1d1d1;
  font-size: 12px;
  font-weight: 500;
}
.game-index .search .opt {
  display: flex;
  align-items: center;
}
.game-index .search .opt .item {
  position: relative;
  padding: 2.5px;
}
.game-index .search .opt .item.active:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
}
.game-index .search .opt .icon {
  font-size: 18px;
}

.server-region-content .server-mask {
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.server-region-content .server-main {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 70vh;
  padding: 0 16px calc(env(safe-area-inset-bottom));
  overflow-y: scroll;
  background-color: #fff;
  z-index: 400;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: all 0.25s;
}

.server-region-content .server-main .gift-top {
  position: fixed;
  left: 0;
  right: 0;
  padding-top: 20px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.server-region-content .server-main .gift-top img {
  margin-right: 30px;
}

.server-region-content .server-main .game-server {
  padding-top: 60px;
  padding-bottom: 20px;
}

.server-region-content .game-server .tips {
  color: #999;
  text-align: center;
}

.server-region-content .game-server .introImg,
.server-region-content .game-server .introImg img {
  width: 79px;
  height: 79px;
  margin: 0 auto;
}

.server-region-content .game-server .intro01 {
  font-size: 0.32rem;
  text-align: center;
  color: #3d3d3d;
}

.server-region-content .game-server .intro02,
.server-region-content .game-server .intro03 {
  font-size: 0.24rem;
  text-align: center;
  color: #666666;
}

.server-region-content .game-server .intro02 {
  margin-top: 12px;
}

.server-region-content .game-server .container {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 3.34rem 3.34rem;
  /* 列与列间的距离 */
  grid-column-gap: 9px;
  margin-top: 16px;
}
.server-region-content .game-server .item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 9px 12px 8px;
  font-size: 13px;
  background-color: #f5f6f8;
  border-radius: 5px;
}

.server-region-content .game-server .item .item-left {
  flex: 1 1;
  /* margin-right: 16px; */
  overflow: hidden;
}

.server-region-content .game-server .item .time {
  margin-bottom: 4px;
  text-align: left;
  font-size: 0.28rem;
  font-weight: bold;
  color: #333333;
}
.server-region-content .game-server .item .name {
  flex: 1;
  text-align: left;
  font-size: 0.24rem;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.server-region-content .game-server .item .operate {
  width: 1.02rem;
  height: 0.42rem;
  line-height: 0.42rem;
  border-radius: 207px;
  color: #3d3d3d;
  background-color: #fcf15d;
  text-align: center;
  font-size: 0.24rem;
}
.server-region-content .game-server .item .operate.disable {
  background-color: #ccc;
  color: #fff;
}

.gift-pack-code {
  margin-top: -3px;
  padding: 8px 36px;
  border-radius: 15px;
  background-color: #f4f4f4;
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
  text-align: center;
}

.gift-pack-code .code {
  color: #fb6c31;
}

.gift-pack-code-font {
  margin-top: 16px;
  font-size: 0.24rem;
  color: #999999;
  text-align: center;
}

.game-vip-content {
  min-height: calc(100vh - env(safe-area-inset-top) - 48px);
  padding: 16px;
  background-color: #f6f7f9;
}

.game-vip-content .content table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}
.game-vip-content .content tr {
  margin: 0;
  padding: 0;
}

.game-vip-content .content td {
  /* width: 50%; */
  padding: 12px 0.5em;
  font-size: 0.24rem;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: rgba(61, 61, 61, 0.8);
  background-color: #fff;
}

.game-vip-content .content tr:first-child td {
  padding: 0.5em;
  background-color: #46444e;
  font-size: 0.28rem;
  font-weight: bold;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  white-space: nowrap;
}

.game-vip-content .content tr:last-child td {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.game-vip-content.content .empty {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateX(-50%);
}

.game-detail .game-manufacturer {
  overflow: hidden;
  margin: 8px 12px 0;
  padding: 14px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  background-color: #fff;
  color: rgba(61, 61, 61, 0.8);
}

.game-detail .game-manufacturer .body {
  max-height: 19px;
  position: relative;
  overflow: hidden;
  transition: all 0.25s;
}

.game-detail .game-manufacturer .body.more {
  max-height: 2048px;
}
.game-detail .game-manufacturer .body .more {
  display: block;
  position: absolute;
  top: 0;
  right: 2px;
  z-index: 22;
  width: 20px;
  height: 20px;
  background-color: #fff;
}

.game-detail .game-manufacturer .body .entity {
  font-size: 0.26rem;
  font-weight: 500;
  color: rgba(61, 61, 61, 0.8);
}

.game-detail .game-manufacturer .body .entity .every {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.game-detail .game-manufacturer .body .entity .every:last-child {
  margin-bottom: 0;
}

.game-detail .game-manufacturer .body .entity .every .title {
  margin-right: 20px;
  white-space: nowrap;
}

.game-detail .game-manufacturer .body .entity .every .btn {
  margin-left: 8px;
  color: #f3ae00;
}

.server-region-content .jur-main {
  padding-top: 58px;
  padding-bottom: 92px;
}

.server-region-content .jur-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
  background-color: #fff;
}

.server-region-content .jur-btn .btn-main {
  width: 155px;
  height: 53px;
  margin: 22px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 116px;
  background: #fcf15d;

  font-size: 0.32rem;
  font-weight: 500;
  color: #3d3d3d;
}

.game-detail .game-games {
  padding: 12px;
  margin: 8px 12px 16px;
  background-color: #fff;
  border-radius: 10px;
}
.game-detail .game-games .head {
  display: flex;
  align-items: center;
}
.game-detail .game-games .head .text {
  flex: 1;

  font-size: 0.32rem;
  font-weight: bold;
  color: #3d3d3d;
}
.game-detail .game-games .head .operate {
  display: flex;
  align-items: center;

  font-size: 0.24rem;
  color: #999999;
}
.game-detail .game-games .head .operate .icon {
  font-size: 14px;
  color: #ffc000;
  margin-right: 5px;
}
.game-detail .game-games .body {
  margin-top: -12px;
  display: grid;
  grid-template-columns: 1.4rem 1.4rem 1.4rem 1.4rem;
  /* 列与列间的距离 */
  grid-column-gap: 16px;
}
.game-detail .game-games .body .item {
  margin-top: 21px;
}
.game-detail .game-games .body .item .icon {
  width: 1.4rem;
  height: 1.4rem;
  margin: 0 auto;
  position: relative;
}
.game-detail .game-games .body .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.game-detail .game-games .body .item .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 15px;
}
.game-detail .game-games .body .item .name {
  margin-top: 5px;
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
  text-align: center;
  overflow: hidden;
  /* text-overflow: ellipsis;*/
  white-space: nowrap;
}

.game-detail .game-games .body .item .branch {
  width: fit-content;
  max-width: 100%;
  margin: 2px auto 0;
  padding: 0 5px;
  border-radius: 2px;
  background-color: #f3f5f9;
  font-size: 0.2rem;
  font-weight: bold;
  color: #86878b;
  text-align: center;
}

.game-detail .game-games .body .item .branch p {
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
}

.game-detail .game-help {
  display: block;
  margin-bottom: 16px;

  font-size: 0.24rem;
  text-align: center;
  color: #999999;
}
.game-detail .game-help span {
  color: #f3ae00;
}

.game-detail .game-operate {
  position: fixed;
  z-index: 200;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 12px 16px calc(env(safe-area-inset-bottom) + 18px) 16px;
}
.game-detail .game-operate .game-tips {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff9ed;
  padding: 5px;
}
.game-detail .game-operate .game-tips .text {
  flex: 1;
  width: 0;
  color: #ff3c3c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-detail .game-operate .container {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.game-detail .game-operate .favorite-container,
.game-detail .game-operate .appraise {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.1s;
  line-height: 1;

  font-size: 0.2rem;
  font-weight: 500;
  color: #3d3d3d;
}

.game-detail .game-operate .favorite-container {
  margin-right: 10px;
}
.game-detail .game-operate .favorite-container .icon {
  width: 26px;
  margin-bottom: 4px;
}
.game-detail .game-operate .favorite-container .favorite {
  display: block;
}
.game-detail .game-operate .favorite-container .favorited {
  display: none;
}
.game-detail .game-operate .favorite-container.active .favorite {
  display: none;
}
.game-detail .game-operate .favorite-container.active .favorited {
  display: block;
}
.game-detail .game-operate .appraise {
  margin-left: 10px;
}
.game-detail .game-operate .appraise .icon {
  width: 23px;
  margin-bottom: 4px;
}

.game-detail .game-operate .btns {
  flex: 1;
  width: 0;
  display: flex;
  align-items: stretch;
  transition: all 0.1s;
  position: relative;
}
.game-detail .game-operate .btns .btn {
  flex: 1;
  background-color: #fcf15d;
  margin: 0 5px;
  border-radius: 22px;
  transition: all 0.1s;
  text-align: center;
  padding: 5px 0;
  min-height: 40px;
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
}
.game-detail .game-operate .btns .btn.disable {
  background-color: #ccc;
}
.game-detail .game-operate .btns .btn .tips {
  margin: 0;
  position: absolute;
  top: -10px;
  height: 25px;
  transform: translateY(-100%);
  background: #3c4046;
  padding: 2.5px 7.5px;
  line-height: 1;
  border-radius: 4px;
  color: #fff;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 2.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.game-detail .game-operate .btns .btn .tips:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #3c4046;
}
.game-detail .game-operate .btns .btn .text {
  font-size: 15px;
}
.game-detail .game-operate .btns .btn .size {
  font-size: 0.2rem;
  color: #999;
}
.game-detail .game-operate .btns .btn-cloud {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2760e4;
  color: #fff;
}
.game-detail .game-operate .btns .btn-cloud .icon {
  margin-top: 2.5px;
  margin-right: 5px;
}
.game-detail .game-operate .btns .btn-cloud .icon img {
  height: 20px;
}
.game-detail .game-operate .btns .btn-cloud .text {
  margin-bottom: 2.5px;
}
.game-detail .game-operate .btns .btn-reserve {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  background-color: cornflowerblue;
  color: #fff;
  line-height: 1.25;
}
.game-detail .game-operate .btns .btn-reserve h1 {
  font-weight: bold;
}
.game-detail .game-operate .btns .btn-reserve h2 {
  font-size: 13px;
}
.game-detail .game-operate .btns .btn-reserve p {
  font-size: 12px;
}
.game-detail .game-operate .btns.multiple {
  height: 100%;
  background: url(https://webbox.9917yx.com/resources/game/btns.png) center
    center no-repeat;
  background-size: 100% 100%;
}
.game-detail .game-operate .btns.multiple .btn {
  margin-left: 0;
  margin-right: 0;
  background: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 51%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-detail .game-operate .btns.multiple .btn:first-child {
  width: 33.33%;
  left: 0;
  padding-right: 2.5%;
}
.game-detail .game-operate .btns.multiple .btn:last-child {
  width: 66.66%;
  right: 0;
  padding-left: 2.5%;
}
.game-detail .game-operate .test {
  padding: 5px 5px 23px 5px;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}
.game-detail .game-operate .test .btn {
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
  padding: 12.5px 7.5px;
  line-height: 1.25;
  background-color: blue;
  color: #fff;
  text-align: center;
}
