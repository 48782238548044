.service-content {
  background-color: #f6f7f9;
  min-height: calc(100vh - env(safe-area-inset-top) - 48px);
}
.service-top-img {
  width: 100%;
  height: auto;
}
.service-index {
  overflow: hidden;
  padding: 0 16px 16px;
}
.service-index .service-contact {
  margin-top: 10px;
}
.service-index .service-contact .item {
  width: 100%;
  height: 89px;
  margin-bottom: 8px;
  padding: 0 19.66px 0 17px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.service-index .service-contact .data {
  flex: 1;
  width: 0;
  overflow: hidden;
}

.service-index .service-contact .icon {
  width: 0.96rem;
  height: 0.96rem;
  background: left center no-repeat;
  background-size: auto 100%;
}
.service-index .service-contact .line {
  width: 1px;
  height: 63px;
  margin-left: 10px;
  margin-right: 16px;
  background-color: #f3f3f3;
}
.service-index .service-contact .name {
  font-size: 0.32rem;
  color: #3d3d3d;
  font-weight: 500;
  letter-spacing: 0.036000000000000004em;
}
.service-index .service-contact .value {
  margin-top: 6px;
  color: #999999;
  font-size: 0.24rem;
  font-weight: normal;
}
.service-index .service-contact .opt .more {
  color: #3d3d3d;
  font-size: 14px;
}
.service-index .service-bt {
  margin: 24px auto 9px;

  font-size: 0.32rem;
  font-weight: bold;
  letter-spacing: 0.07200000000000001em;
  color: #262525;
}
.service-index .service-faq {
  background-color: #fff;
  border-radius: 8px;
}
.service-index .service-faq dl {
  border-bottom: 1px solid #eee;
  border-radius: 4px;
}
.service-index .service-faq dt {
  padding: 10px;
  display: flex;
  align-items: center;
  transition: all 0.25s;
}
.service-index .service-faq dt .full {
  flex: 1;
  display: flex;
  align-items: center;
}
.service-index .service-faq dt .icon {
  width: 35px;
  height: 25px;
  background: center no-repeat;
  background-size: contain;
  margin-right: 5px;
}
.service-index .service-faq dt .text {
  font-size: 0.3rem;
  color: #282828;
  font-weight: bold;
  white-space: nowrap;
  flex: 1;
  width: 0;
}
.service-index .service-faq dt .more {
  color: rgba(154, 154, 154, 0.7);
}
.service-index .service-faq dt .tel-info {
  flex: 1;
  width: 0;
  text-align: center;

  font-size: 14px;
  color: #767676;
}
.service-index .service-faq dt .tel-info .tel-info-tips {
  font-size: 10px;
  letter-spacing: 0.07200000000000001em;
  color: #767676;
}
.service-index .service-faq dt .tel-btn {
  width: 43px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  font-size: 0.2rem;
  border-radius: 236px;
  background-color: #fdc003;
  text-align: center;
}
.service-index .service-faq dd {
  max-height: 0;
  overflow: hidden;
  transition: all 0.25s;
}
.service-index .service-faq dl.active dt {
  border-bottom: 1px solid #eee;
}
.service-index .service-faq dl.active dd {
  max-height: 333px;
}
.service-index .service-faq a {
  display: block;
  color: #333;
  font-size: 13px;
  /* padding: 0.1rem 0.3rem; */
}
