@import "reset.css";
@import "search.css";
@import "index.css";
@import "service.css";
@import "game.css";
@import "comment.css";
@import "commentDetail";
@import "setting.css";
@import "gameIndex.css";
@import "article.css";
@import "credit.css";
@import "gift.css";
@import "common.css";
@import "hangup.css";
@import "user.css";

/* rem计算  设计图中px单位/50 */

#banner {
  width: 100%;
  height: 30px;
  overflow: hidden;
  position: relative;
  text-align: left;
}

#ul-imgs.transition {
  transition: all 0.5s linear;
}

#ul-imgs > li {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #000;
  font-size: 14px;
}

html,
body {
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
}

.root,
.width {
  /* max-width: 720px; */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.safeareatest {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
.dialog {
  position: fixed;
  z-index: 10000001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.dialog-mask {
  position: fixed;
  z-index: 10000002;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
}
.dialog-content {
  position: relative;
  z-index: 10000003;
}
.dialog-toast {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px 20px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: fade-in;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}
.dialog-toast .toast-icon {
  font-size: 32px;
}
.dialog-toast .toast-text {
  font-size: 16px;
  color: #fff;
}
.dialog-toast .toast-text:nth-child(2) {
  margin-top: 5px;
}
.dialog-loading .dialog-toast {
  background-color: transparent;
}
.dialog-loading .dialog-toast .toast-icon {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-modal {
  border-radius: 15px;
  min-width: 80vw;
  max-width: 85vw;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.25s;
}
.dialog-modal .modal-body {
  padding: 0 20px 25px;
  max-height: 50vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.dialog-modal .modal-title {
  margin-top: 31px;
  font-size: 0.36rem;
  font-weight: bold;
  text-align: center;
  color: #3d3d3d;
}
.dialog-modal .modal-content {
  margin-top: 20px;
  color: #333;
  font-size: 15px;
}
.dialog-modal .modal-btns {
  border-top: 1px solid #eee;
  display: flex;
}
.dialog-modal .modal-btn {
  flex: 1;
  padding: 15px 0 17px;
  display: flex;
  justify-content: center;
  font-size: 0.32rem;
  color: #000;
  border-left: 1px solid #eee;
}
.dialog-modal .modal-btn:first-child {
  border-left: none;
}
.dialog-modal .modal-btn.success {
  color: #fb6c31;
}
.dialog-modal .modal-btns.column {
  flex-direction: column;
}
.dialog-modal .modal-btns.column .modal-btn {
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #333;
  border-left: none;
  border-top: 1px solid #eee;
}
.dialog-modal .modal-btns.column .modal-btn:first-child {
  border-top: none;
}

.dialog-modal .wel-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 23px 10px 0;

  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #3d3d3d;
}
.dialog-modal .apply-title {
  padding-top: 38px;

  font-size: 0.36rem;
  font-weight: bold;
  text-align: center;
  color: #3d3d3d;
}
.dialog-modal .applyGame {
  width: 175px;
  height: 40px;
  margin: 35px auto 14px;
  text-align: center;
  line-height: 40px;
  border-radius: 22px;
  background: #fcf15d;

  font-size: 14px;
  font-weight: 500;
  color: #3d3d3d;
}
.dialog-modal .modal-wel-btns {
  margin-top: 10px;
  padding-bottom: 21px;
}
.dialog-modal .modal-wel-btns .goToCollect {
  width: 255px;
  height: 44px;
  margin: 20px auto 0;
  text-align: center;
  line-height: 44px;
  border-radius: 22px;
  background: #fcf15d;

  font-size: 14px;
  font-weight: 500;
  color: #3d3d3d;
}
.dialog-modal .modal-wel-btns .download {
  width: fit-content;
  margin: 0 auto;
  margin-top: 16px;

  font-size: 14px;
  font-weight: 500;
  color: #999999;
  border-bottom: 1px solid #999999;
}
.be-downloading {
  margin-top: 42px;
}
.be-downloading .progress-bar {
  width: 300px;
  height: 22px;
  margin: 0 auto;
  border-radius: 168px;
  background: #f4f4f4;
}
.son {
  width: 0;
  height: 22px;
  line-height: 22px;
  border-radius: 168px;
  background: #fcf15d;
  text-align: right;

  font-size: 14px;
  font-weight: bold;
  color: #3d3d3d;
  /* transition: all 1.5s; */
}
.be-downloading .attention {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #999999;
}
.be-downloading .attention img {
  width: 12.69px;
  height: 12.69px;
  margin-right: 2.66px;
  margin-top: 2px;
}
.dialog.enter {
  opacity: 0;
}
.dialog.enter .dialog-modal {
  transform: scale(1.25, 1.25);
}
.dialog.enter-active,
.dialog.enter-done,
.dialog.exit {
  opacity: 1;
}
.dialog.enter-active .dialog-modal,
.dialog.enter-done .dialog-modal,
.dialog.exit .dialog-modal {
  transform: scale(1, 1);
}
.dialog.exit-active {
  opacity: 0;
}
.dialog.exit-active .dialog-modal {
  transform: scale(1.25, 1.25);
}
.activity-tips {
  position: fixed;
  z-index: 777;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.25s;
}
.activity-tips img {
  display: block;
  max-width: 75vw;
  max-height: 75vh;
}
.to-safari-copy {
  position: fixed;
  z-index: 917917917;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.to-safari-copy .container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.to-safari-copy .img {
  display: flex;
  max-width: 150%;
}
.to-safari-copy .close {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-top: -95.5px;
  margin-bottom: 70px;
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.to-safari-copy .close:before {
  content: "X";
  font-size: 18px;
  color: #fff;
}

.swiper-tips-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-end;
}

.swiper-tips-container .swiper-tips-main {
  width: 100%;
  padding: 28px 24px 44px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
}

.swiper-tips-container .swiper-link-tips-main.swiper-tips-main {
  width: 100%;
  padding: 0;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
}

.swiper-tips-main .swiper-container {
  padding-bottom: 20px;
  min-height: 3.66rem;
}

.swiper-link-tips-main.swiper-tips-main .swiper-container {
  padding-bottom: 0;
  min-height: 3.66rem;
}

.swiper-tips-main .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.swiper-tips-main .swiper-container .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  margin: 0 5px;
  background: #999;
}
.swiper-tips-main .swiper-container .swiper-pagination-bullet-active {
  background-color: #f34902;
  width: 12px;
  border-radius: 110px;
}

.swiper-tips-container img {
  display: block;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.swiper-tips-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.swiper-tips-img {
  margin-right: 7px;
}

.swiper-tips-img img {
  width: 0.72rem;
  height: 0.72rem;
  display: block;
  object-fit: cover;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.swiper-tips-name {
  font-size: 0.32rem;
  font-weight: bold;
  color: #1d1c21;
}

.swiper-tips-name span {
  margin-left: 20px;
  font-size: 0.24rem;
  font-weight: normal;
  color: #999;
}

.swiper-tips-font {
  color: #333;
  font-size: 0.24rem;
}

.swiper-tips-font span {
  color: #f34902;
}

.desktop-tips {
  position: fixed;
  z-index: 777;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.desktop-tips:before {
  content: "";
  display: block;
  position: fixed;
  z-index: 888;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.detail-desktop.desktop-tips:before {
  background-color: rgba(0, 0, 0, 1);
}
.desktop-tips .container {
  max-width: 75vw;
  max-height: 80vh;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.desktop-tips .container > img {
  display: block;
  width: 100%;
}
.desktop-tips .container .body {
  width: 100%;
  padding: 10px;
  /* overflow: hidden; */
  background-color: #fff;
  display: flex;
  align-items: center;
}
.desktop-tips .container .body .icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 7.5px;
}
.desktop-tips .container .body .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.desktop-tips .container .body .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
}
.desktop-tips .container .body .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
}
.desktop-tips .container .body .url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  color: #666;
  margin-top: 5px;
}
.desktop-tips .img2 {
  margin-top: 1vh;
}
.desktop-tips .img4 {
  margin-top: 1vh;
}
.desktop-tips .text4 {
  margin-top: 1vh;
  font-size: 14px;
  color: #fff;
}
@media (width: 375px) {
  .desktop-tips .text4 {
    font-size: 13px;
  }
}
@media (width: 320px) {
  .desktop-tips .text4 {
    font-size: 12px;
  }
}
.desktop-tips .text4 span {
  color: #ffc000;
}
.mobileconfig-tips {
  position: fixed;
  z-index: 777;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.mobileconfig-tips:before {
  content: "";
  display: block;
  position: fixed;
  z-index: 888;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.mobileconfig-tips .container {
  max-width: 75vw;
  max-height: 80vh;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.mobileconfig-tips .container img {
  display: block;
  width: 100%;
}
.mobileconfig-tips .container a {
  color: #fff;
  border-radius: 20px;
  padding: 12.5px 20px;
  background-color: #ffc000;
  font-size: 16px;
  line-height: 1.15;
  margin-top: 10px;
}
.pull-to-refresh {
  padding: 10px;
  color: #ccc;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-200%);
}
.pull-to-refresh:before {
  content: "释放刷新";
}
.pull-to-load {
  padding: 10px;
  color: #ccc;
  text-align: center;
}
.pull-to-load:before {
  content: "拉下加载更多";
}
.pull-to-load.nomore:before {
  content: "没有更多的数据了";
}
.screen-index {
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  transition: all 0.5s;
  display: none;
}
.screen-index.active {
  display: block;
}
.screen-index .screen-body {
  padding-bottom: 65px;
  position: relative;
}
.screen-index .screen-body-item {
  position: relative;
  display: none;
}
.screen-index .screen-body-item.active {
  display: block;
}
.screen-index .screen-menu {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  border-top: 1px solid #eee;
  padding-top: 4px;
  transition: all 0.25s;
}
.screen-index .screen-menu-container {
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.screen-index .screen-menu-item {
  flex: 1;
  flex-shrink: 0;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  transition: all 0.25s;
}
.screen-index .screen-menu-item .icon {
  margin-bottom: 2.5px;
  display: none;
  height: 32px;
  background: center no-repeat;
  background-size: contain;
}
.screen-index .screen-menu-item .icon-dark {
  display: block;
}
.screen-index .screen-menu-item.active {
  color: #f5842d;
}
.screen-index .screen-menu-item.active .icon-dark {
  display: none;
}
.screen-index .screen-menu-item.active .icon-highlight {
  display: block;
}
.theme-dark .screen-index {
  background-color: #000;
}
.theme-dark .screen-menu {
  background-color: #000;
}
.theme-dark .screen-menu-item {
  color: #fff;
}
.theme-dark .screen-menu-item .icon-dark {
  display: none;
}
.theme-dark .screen-menu-item .icon-white {
  display: block;
}
.theme-dark .screen-menu-item.active .icon-white {
  display: none;
}
.theme-dark .screen-menu-item.active .icon-highlight {
  display: block;
}

.user-phone-verify {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  opacity: 0;
}
.user-phone-verify .entity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  transition: all 0.25s;
  opacity: 0;
  transform: scale(1.25);
}
.user-phone-verify.appear-active,
.user-phone-verify.appear-done,
.user-phone-verify.enter-done {
  opacity: 1;
}
.user-phone-verify.appear-active .entity,
.user-phone-verify.appear-done .entity,
.user-phone-verify.enter-done .entity {
  opacity: 1;
  transform: scale(1);
}
.user-phone-verify.exit-active {
  opacity: 0;
}
.user-phone-verify.exit-active .entity {
  opacity: 0;
  transform: scale(1.25);
}
.user-phone-verify .container {
  background-color: #fff;
  border-radius: 8px;
}
.user-phone-verify .body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-phone-verify .icon {
  color: cornflowerblue;
  font-size: 48px;
}
.user-phone-verify .title {
  font-weight: bold;
  color: #000;
  font-size: 0.32rem;
  text-align: center;
  padding: 7.5px;
}
.user-phone-verify .desc {
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
}
.user-phone-verify .input {
  margin-top: 15px;
  display: flex;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 8px;
}
.user-phone-verify .input input {
  flex: 1;
  background: none;
  border: none;
}
.user-phone-verify .input .btn {
  background-color: #ffc000;
  padding: 2.5px 5px;
  color: #fff;
  border-radius: 10px;
  font-size: 13px;
}
.user-phone-verify .input .btn.disable {
  background-color: #ccc;
}
.user-phone-verify .submit {
  border-top: 1px solid #ccc;
  color: #ffc000;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px;
}
.user-phone-verify .close {
  border: 2px solid #ccc;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.image-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.image-picker .item {
  position: relative;
  width: 30%;
  padding-top: 25%;
  margin: 10px 10px 0 0;
}
.image-picker .item-file .img {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.image-picker .item-file .img img {
  display: block;
  width: 100%;
  height: 100%;
}
.image-picker .item-file .icon {
  position: absolute;
  z-index: 22;
  top: 5px;
  right: 5px;
  color: #fff;
  border: 1px solid #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.image-picker .item-input {
  border: 1px solid #ccc;
}
.image-picker .item-input .icon {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}
.image-picker .item-input input {
  display: block;
  position: absolute;
  z-index: 22;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.activity-first {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}
.activity-first:before {
  content: "";
  display: block;
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.activity-first .container {
  position: relative;
  z-index: 777;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 65vh;
}
.activity-first a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activity-first .img {
  display: flex;
  justify-content: center;
}
.activity-first .img img {
  display: block;
  max-width: 75vw;
  max-height: 60vh;
}
.activity-first .btn {
  background-color: #ffc000;
  color: red;
  font-size: 0.3rem;
  padding: 7.5px;
  border-radius: 20px;
  width: 75%;
  text-align: center;
}
.activity-first .close {
  margin-top: 25px;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
}
.app-udid {
  position: fixed;
  z-index: 5555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-udid:before {
  content: "";
  display: block;
  position: fixed;
  z-index: 6666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.app-udid .container {
  position: relative;
  z-index: 7777;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 65vh;
}
.app-udid .img {
  display: flex;
  justify-content: center;
}
.app-udid .img img {
  display: block;
  max-width: 75%;
}
.app-udid .btn {
  background-color: #ffc000;
  font-size: 0.3rem;
  padding: 7.5px;
  border-radius: 20px;
  width: 75%;
  text-align: center;
  margin-top: 2.5vh;
  color: #fff;
}
.app-udid .close {
  margin-top: 2.5vh;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
}
.app-alert {
  position: fixed;
  z-index: 5555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}
.app-alert:before {
  content: "";
  display: block;
  position: fixed;
  z-index: 6666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.app-alert .container {
  position: relative;
  z-index: 7777;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 65vh;
}
.app-alert .img {
  display: flex;
  justify-content: center;
}
.app-alert .img img {
  display: block;
  max-width: 75%;
}
.app-alert .btn {
  background-color: #ffc000;
  font-size: 0.3rem;
  padding: 7.5px;
  border-radius: 20px;
  width: 75%;
  text-align: center;
  margin-top: 2.5vh;
  color: #fff;
}
.app-alert .close {
  margin-top: 2.5vh;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
}
.act-datas {
  position: fixed;
  z-index: 444;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 5px;
  transition: all 0.25s;
}
.act-datas-113 {
  bottom: calc(env(safe-area-inset-bottom) + 113px);
}
.act-datas-60 {
  bottom: calc(env(safe-area-inset-bottom) + 60px);
}
@keyframes act-animation {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(15deg);
  }
  45% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.act-datas .item {
  padding: 5px;
  display: flex;
  justify-content: center;
  animation: act-animation 4s infinite;
}
.act-datas .item img {
  display: block;
  max-width: 65px;
  max-height: 65px;
}
.act-datas .item img.btwan {
  animation: index-float-size 1.5s 0s infinite;
}
.act-datas .item.first img {
  width: 50px;
  height: 50px;
}
.act-data {
  position: fixed;
  z-index: 917917917917;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.act-data:before {
  content: "";
  display: block;
  position: fixed;
  z-index: 6666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.act-data .container {
  position: relative;
  z-index: 7777;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 65vh;
}
.act-data .img {
  display: flex;
  justify-content: center;
}
.act-data .img img {
  display: block;
  max-width: 93vw;
  max-height: 60vh;
}
.act-data .btn {
  background-color: #ffc000;
  font-size: 0.3rem;
  padding: 7.5px;
  border-radius: 20px;
  width: 75%;
  text-align: center;
  margin-top: 2.5vh;
  color: #fff;
}
.act-data .close {
  margin-top: 2.5vh;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
}

.top-head {
  position: fixed;
  z-index: 233;
  left: 0;
  right: 0;
}
.top-head .width {
  /* margin-top: 5px; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  background-color: #f6f7f9;
}
.top-head .item {
  font-size: 16px;
  height: 37.5px;
  padding: 5px;
  margin-left: 5%;
  margin-right: 5%;
  color: rgba(61, 61, 61, 0.7);
  position: relative;
  cursor: pointer;
}
.top-head .item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.25s;
  height: 3px;
}
.top-head .item.active {
  color: #3d3d3d;
  font-weight: bold;
}
.top-head .item.active:after {
  width: 30px;
  margin: 0 auto;
  background-color: #fcf15d;
}
.top-body {
  padding-top: 37.5px;
}
.top-group {
  display: none;
}
.top-group.active {
  display: block;
  margin-top: 18.5px;
}
.top-item {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.1rem 0.2rem;
  border-radius: 8px;
  margin-bottom: 0.1rem;
}
.top-item .index {
  width: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 10px;
  font-size: 18px;
}
.top-item .icon {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.top-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.top-item .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
}
.top-item .icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: deeppink;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  max-width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  padding: 1px 2.5px;
}
.top-item .subject {
  flex: 1;
  width: 0;
  /*border-bottom: 1px solid #dedede;
      padding-bottom: .2rem;*/
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.top-item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.top-item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.28rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.top-item .desc {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
  font-size: 13px;
}
.top-item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.top-item .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ff5e00;
  font-size: 12px;
}
.top-item .tag {
  display: inline-block;
  margin-right: 5px;
}
.top-item .tag:nth-child(1) {
  color: #ce38b5;
}
.top-item .tag:nth-child(2) {
  color: #006464;
}
.top-item .tag:nth-child(3) {
  color: #1f48c5;
}
.top-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}

.game-article-group {
  min-height: calc(100vh - env(safe-area-inset-top) - 40px);
  background-color: #f6f7f9;
  padding: 0 10px 10px 10px;
  overflow: hidden;
}
.game-article-group .group {
  overflow: hidden;
  margin-top: 10px;
}
.game-article-group .group .title {
  display: flex;
  align-items: center;
}
.game-article-group .group .title .name {
  color: #3d3d3d;
  font-weight: bold;
  font-size: 0.32rem;
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-article-group .group .title .opt {
  color: #999999;
}
.game-article-group .article {
  margin-top: 7.5px;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.game-article-group .article .title {
  color: #3d3d3d;
  width: 0;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 7.5px;
}
.game-article-group .article .opt {
  background: #fcf15d;
  color: #3d3d3d;
  padding: 6.5px 11.5px;
  line-height: 1.1;
  font-size: 0.24rem;
  border-radius: 20px;
}
.game-article {
  min-height: 100vh;
  background-color: #eee;
  padding: 15px;
}
.game-article .item {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.game-article .item .container {
  flex: 1;
  width: 0;
  padding-right: 10px;
}
.game-article .item .desc {
  display: flex;
  align-items: center;
}
.game-article .item .desc .type {
  background-color: #ffa21c;
  color: #fff;
  font-size: 12px;
  padding: 2.5px;
  line-height: 1;
  border-radius: 2px;
  margin-right: 5px;
}
.game-article .item .desc .type-1 {
  background-color: #ff5e00;
}
.game-article .item .desc .state {
  flex: 1;
  color: #999;
  font-size: 13px;
}
.game-article .item .desc .time {
  font-size: 12px;
  color: #ccc;
}
.game-article .item .title {
  color: #000;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-article .item .more {
  color: #ccc;
  font-size: 16px;
}
.comment-submit {
  padding: 10px;
}
.comment-submit .content textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
  resize: none;
}
.comment-submit .imgs {
  margin-top: 10px;
}
.comment-submit-btn {
  color: #ff5e00;
}
.comment-submit-btn.disable {
  color: #ccc;
}
.game-project .project-img img {
  display: block;
  width: 100%;
}
.game-project .project-info {
  padding: 10px;
  font-size: 0.28rem;
  color: #000;
}
.game-project .games {
  padding: 10px;
}
.game-project .item {
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
}
.game-project .item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.game-project .item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px;
}
.game-project .item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
}
.game-project .item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.game-project .item .desc {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.game-project .item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.game-project .item .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.24rem;
  color: #ff5e00;
}
.game-project .item .info .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.game-project .item .tag {
  display: inline-block;
  padding-left: 5px;
}
.game-project .item .tag:first-child {
  padding-left: 0;
}
.game-project .item .tag:nth-child(1) {
  color: #ff4200;
}
.game-project .item .tag:nth-child(2) {
  color: #75d100;
}
.game-project .item .tag:nth-child(3) {
  color: #2893ff;
}
.game-project .item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.game-ios-guide {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.game-ios-guide .giu-container {
  background-color: #fff;
  padding: 12.5px;
  border-radius: 12px;
  box-shadow: 0 0 3px #ccc;
  min-width: 75%;
  max-width: 85%;
}
.game-ios-guide .giu-container .btn {
  flex: 1;
  background-color: #fcf15d;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 55px;
  border-radius: 20px;
  color: #3d3d3d;
  transition: all 0.1s;
  flex-direction: column;
  text-align: center;
  padding: 11.5px 0;
}

.game-getter {
  position: fixed;
  z-index: 917;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.game-getter .gt-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 10px env(safe-area-inset-bottom) 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.game-getter .gt-head {
  text-align: right;
  color: #666;
  padding: 10px 10px 0 10px;
}
.game-getter .gt-head:before {
  content: "暂不安装";
}
.game-getter .gt-body {
  color: #333;
  padding: 25px 10px;
  font-size: 15px;
}
.game-getter .gt-body .free {
  color: green;
}
.game-getter .gt-body .warn {
  color: red;
}
.game-getter .gt-foot {
  padding: 10px;
}
.game-getter .gt-foot .btn {
  display: block;
  background-color: #ffc000;
  width: 50%;
  margin: auto;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
  text-align: center;
}
.game-getter .gt-foot .btn:before {
  content: "确认安装";
}
.game-select-head {
  position: fixed;
  z-index: 333;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0 10px;
  height: 40px;
  background-color: #fff;
}
.game-select-head input {
  flex: 1;
  width: 0;
  overflow: hidden;
  background: none;
  border: none;
}
.game-select-head .buttton {
  border-left: 1px solid #ccc;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.game-select-head .buttton .icon {
  font-size: 18px;
  margin-right: 5px;
}
.game-select-body {
  margin-top: 40px;
  padding: 7.5px;
}
.game-select-body .item {
  margin-top: 7.5px;
  border-bottom: 1px solid #eee;
  padding-bottom: 7.5px;
  display: flex;
  align-items: center;
}
.game-select-body .item:first-child {
  margin-top: 0;
}
.game-select-body .item .type {
  background-color: #ffc000;
  padding: 2.5px 7.5px;
  font-size: 0.26rem;
  margin-right: 7.5px;
  color: #fff;
}
.game-select-body .item .type-1:before {
  content: "BT";
}
.game-select-body .item .type-2:before {
  content: "折扣";
}
.game-select-body .item .type-3:before {
  content: "H5";
}
.game-select-body .item .type-4:before {
  content: "GM";
}
.game-select-body .item .name {
  font-size: 0.28rem;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.game-cloud-index {
  position: fixed;
  z-index: 9900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}
.game-cloud-desktop-1 {
  position: fixed;
  z-index: 9990;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}
.game-cloud-desktop-1 img {
  display: block;
  width: 100%;
}
.game-cloud-desktop-1 img:last-child {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
}
.game-cloud-desktop-2 {
  position: fixed;
  z-index: 9990;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.game-cloud-desktop-2 .mask {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.game-cloud-desktop-2 .container {
  position: relative;
  z-index: 22;
  background-color: #fff;
  padding: 12.5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.game-cloud-desktop-2 .head {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.game-cloud-desktop-2 .head .icon {
  width: 65px;
  margin-right: 10px;
}
.game-cloud-desktop-2 .head .icon img {
  display: block;
  width: 100%;
}
.game-cloud-desktop-2 .head .info {
  flex: 1;
  width: 0;
}
.game-cloud-desktop-2 .head .info img {
  display: block;
  width: 100%;
}
.game-cloud-desktop-2 .last .icon {
  width: 15%;
  float: left;
}
.game-cloud-desktop-2 .last .icon img {
  display: block;
}
.game-cloud-desktop-2 .last .text {
  width: 85%;
  float: right;
  padding-left: 10px;
}
.game-cloud-desktop-2 .last .text span {
  color: orange;
}
.game-cloud-realname {
  position: fixed;
  z-index: 9950;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.game-cloud-realname .gcr-container {
  width: 85%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  position: relative;
}
.game-cloud-realname .gcr-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  font-size: 18px;
}
.game-cloud-realname .gcr-icon img {
  display: block;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
}
.game-cloud-realname .gcr-title {
  color: #3d3d3d;
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.game-cloud-realname .gcr-desc {
  color: #666666;
  margin-top: 5px;
}
.game-cloud-realname .gcr-input {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 7.5px;
}
.game-cloud-realname .gcr-input label {
  font-size: 15px;
}
.game-cloud-realname .gcr-input input {
  flex: 1;
  width: 0;
  font-size: 15px;
  border: none;
  padding-left: 10px;
  padding-right: 7.5px;
}
.game-cloud-realname .gcr-input .close {
  font-size: 16px;
}
.game-cloud-realname .gcr-btn {
  margin-top: 15px;
  height: 37.5px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  justify-items: center;
  background: #fcf15d;
}
.game-cloud-launch {
  position: fixed;
  z-index: 9985;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #e4e4e4;
  display: flex;
  flex-direction: column;
}
.game-cloud-launch .gcl-main {
  flex: 1;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: calc(5% + env(safe-area-inset-bottom));
}
.game-cloud-launch .gcl-main .icon {
  width: 100px;
}
.game-cloud-launch .gcl-main .icon img {
  display: block;
  width: 100%;
}
.game-cloud-launch .gcl-main .name {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.9);
  font-size: 16px;
  margin-top: 10px;
}
.game-cloud-launch .gcl-main .progress {
  margin-top: 25px;
  position: relative;
  background: rgba(255, 207, 121, 0.3);
  height: 10px;
  width: 75%;
  border-radius: 5px;
}
.game-cloud-launch .gcl-main .progress .speed {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #ffcf79;
  border-radius: 5px;
}
.game-cloud-launch .gcl-main .progress .speed .cursor {
  position: absolute;
  top: -5px;
  bottom: -5px;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 5px solid #ffcf79;
  border-radius: 50%;
}
.game-cloud-launch .gcl-main .progress .speed .cursor .text {
  position: absolute;
  left: 50%;
  bottom: 150%;
  transform: translateX(-50%);
  color: #666666;
  font-weight: bold;
}
.game-cloud-launch .gcl-main .info {
  margin-top: 10px;
  color: #999999;
}
.game-cloud-launch .gcl-tips {
  flex: 1;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.game-cloud-launch .gcl-tips dt {
  margin-bottom: 5%;
}
.game-cloud-launch .gcl-tips dt img {
  display: block;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
}
.game-cloud-launch .gcl-tips dd {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  color: #666666;
  margin-top: 5px;
  padding-left: 15px;
  position: relative;
}
.game-cloud-launch .gcl-tips dd:before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  background: #f5c060;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.game-cloud-ys {
  position: fixed;
  z-index: 9980;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.game-cloud-ys .ys-state {
  position: absolute;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12.5px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  font-size: 14px;
}
.game-cloud-ys iframe {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.game-cloud-sq {
  position: fixed;
  z-index: 9980;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  background-color: #fff;
}
.game-cloud-sq iframe {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.game-cloud-sq .moveable-control-box {
  --moveable-color: unset;
}
.game-cloud-sq .moveable-origin {
  display: none;
}
.game-cloud-sq .sq-bar {
  position: fixed;
  z-index: 9990;
  top: 5%;
  left: 5%;
  display: flex;
  align-items: center;
}
.game-cloud-sq .sq-bar > .hide,
.game-cloud-sq .sq-bar > .speed {
  width: 44px;
  height: 44px;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}
.game-cloud-sq .sq-bar > .hide .icon img,
.game-cloud-sq .sq-bar > .speed .icon img {
  display: block;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.game-cloud-sq .sq-bar > .hide .text,
.game-cloud-sq .sq-bar > .speed .text {
  font-size: 12px;
  text-align: center;
  margin-top: 2.5px;
  line-height: 1;
  color: #12cdb0;
}
.game-cloud-sq .sq-bar .ext {
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  background: center no-repeat;
  background-size: 100% 100%;
  height: 44px;
  padding: 2.5px 5px 0 10px;
}
.game-cloud-sq .sq-bar .ext .item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  position: relative;
}
.game-cloud-sq .sq-bar .ext .item .icon {
  width: 100%;
  flex: 1;
  height: 0;
  background: center no-repeat;
  background-size: contain;
}
.game-cloud-sq .sq-bar .ext .item .text {
  margin-top: 5px;
  line-height: 1;
  text-align: center;
  color: #fff;
  white-space: nowrap;
}
.game-cloud-sq .sq-bar .ext .item.speed .text {
  color: #12cdb0;
}
.game-cloud-sq .sq-bar .ext .item .s {
  position: absolute;
  top: 100%;
  left: -10px;
  right: -10px;
  background-color: rgba(0, 0, 0, 0.75);
}
.game-cloud-sq .sq-bar .ext .item .a {
  text-align: center;
  font-size: 14px;
  color: #fff;
  padding: 7.5px 10px;
  line-height: 1;
  cursor: pointer;
}
.game-cloud-sq .sq-bar .ext .item .a.o {
  background-color: rgba(0, 0, 0, 0.85);
}
.game-cloud-sq .sq-bar .ext .item .tips {
  position: absolute;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  background: #3c4046;
  padding: 6.5px 7.5px 7.5px 7.5px;
  border-radius: 8px;
  line-height: 1;
  width: auto;
  color: #fff;
  white-space: nowrap;
  font-size: 13px;
}
.game-cloud-sq .sq-bar .ext .item .tips:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #3c4046;
  transform: translate(-50%, -100%);
}
.game-cloud-sq .sq-bar.horizontal {
  left: auto;
  right: 5%;
}
.game-cloud-sq .sq-bar.horizontal .ext {
  top: 100%;
  transform-origin: top left;
  transform: rotate(90deg);
}
.game-vip-reserve {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.game-vip-reserve .container {
  background-color: #fff;
  border-radius: 8px;
  padding: 12.5px;
  max-width: 85vw;
  max-height: 75vh;
}
.game-vip-reserve .img img {
  display: block;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.game-vip-reserve .tips {
  margin-top: 5px;
  color: #f5842d;
}
.game-vip-reserve .label {
  margin-top: 5px;
  font-size: 15px;
}
.game-vip-reserve .input {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.game-vip-reserve .input input {
  flex: 1;
  width: 0;
  border: 1px solid #d6d6d6;
  padding: 7.5px 10px;
  border-radius: 8px;
  font-size: 16px;
}
.game-vip-reserve .input .btn {
  margin-left: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 20px;
  padding: 10px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
  text-align: center;
  font-size: 12px;
  color: #f5842d;
}
.game-vip-reserve .input .btn.disable:before {
  color: #d6d6d6;
}
.game-vip-reserve .btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}
.game-vip-reserve .btns .btn {
  border: 1px solid #d6d6d6;
  border-radius: 20px;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
  text-align: center;
  color: #000;
}
.game-vip-reserve .btns .btn:first-child {
  width: 40%;
}
.game-vip-reserve .btns .btn:last-child {
  width: 55%;
  background-color: #fcf15d;
  border: 0;
}
.game-intercept {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.game-intercept .gi-container {
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.game-intercept .gi-head {
  background: #fdecb8;
  padding: 12.5px;
  line-height: 1;
  font-size: 16px;
  text-align: center;
  color: #3d3d3d;
}
.game-intercept .gi-body {
  min-height: 125px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.game-intercept .gi-body .text {
  font-size: 15px;
}
.game-intercept .gi-foot {
  padding: 12.5px;
  display: flex;
  align-items: center;
}
.game-intercept .gi-foot .btn {
  flex: 1;
  width: 0;
  height: 37.5px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  justify-items: center;
}
.game-intercept .gi-foot .btn:first-child {
  background: #0256ff;
  color: #fff;
}
.game-intercept .gi-foot .btn:last-child {
  flex: 1;
  background: #fcf15d;
  color: #000;
}
.game-ios-tips {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
}
.game-ios-tips .git-container {
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.game-ios-tips .git-head {
  padding: 12.5px;
  line-height: 1;
  font-size: 16px;
  text-align: center;
  color: #3d3d3d;
  border-bottom: 1px solid #eee;
  position: relative;
}
.game-ios-tips .git-head .title {
  font-weight: bold;
}
.game-ios-tips .git-head .close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}
.game-ios-tips .git-body {
  padding: 12.5px;
}
.game-ios-tips .git-body dl {
  margin-bottom: 12.5px;
}
.game-ios-tips .git-body dl dt {
  font-size: 16px;
}
.game-ios-tips .git-body dl dd span {
  color: #eb661b;
}
.game-ios-tips .git-body dl dd .copy {
  color: blue;
}
.root-nosafearea {
  background-color: #000;
}
.task-index {
  overflow: hidden;
  min-height: 100vh;
  padding: 12.5px;
  padding-top: env(safe-area-inset-top);
  background: #eee center top no-repeat;
  background-size: contain;
}
.task-index .task-title {
  font-size: 0.3rem;
  padding-bottom: 10px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
}
.task-index .task-money {
  display: flex;
  align-items: center;
}
.task-index .task-money .money {
  flex: 1;
  width: 0;
  color: #fff;
}
.task-index .task-money .money .value {
  font-size: 0.4rem;
  font-weight: bold;
}
.task-index .task-money .btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #ffc000;
  padding: 5px;
  border-radius: 8px;
}
.task-index .task-money .btn img {
  height: 20px;
  margin-right: 5px;
}
.task-index .task-sign {
  display: block;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
}
.task-index .task-sign .title img {
  display: block;
  margin: 0 auto;
  height: 30px;
}
.task-index .task-sign .info {
  margin-top: 10px;
}
.task-index .task-sign .info span {
  color: #ffc000;
}
.task-index .task-sign .progress {
  margin-top: 10px;
  padding: 15px 0 35px 0;
  margin-left: 10px;
  margin-right: 20px;
  position: relative;
}
.task-index .task-sign .progress > div {
  background-color: #eee;
  width: 100%;
  height: 2px;
  overflow: hidden;
}
.task-index .task-sign .progress > div > span {
  display: block;
  height: 100%;
  background-color: #ffc000;
}
.task-index .task-sign .progress li {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50px;
  font-size: 12px;
}
.task-index .task-sign .progress li .value {
  background-color: #eee;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task-index .task-sign .progress li .value img {
  display: block;
  height: 20px;
}
.task-index .task-sign .progress li .label {
  margin-top: 2.5px;
}
.task-index .task-sign .progress li.active .value {
  background-color: #ffc000;
  color: #fff;
}
.task-index .task-sign .progress li.active .label {
  color: #ffc000;
}
.task-index .task-sign .btn {
  width: 100px;
  margin: 0 auto;
  background-color: #ffc000;
  border-radius: 10px;
  color: #fff;
  padding: 5px 7.5px;
  text-align: center;
}
.task-index .task-sign .btn.disable {
  background-color: #ccc;
}
.task-index .task-groups {
  margin-top: 10px;
  overflow: hidden;
}
.task-index .task-groups > .container {
  margin-left: -10px;
}
.task-index .task-group {
  float: left;
  width: 50%;
  padding-left: 10px;
  margin-top: 10px;
}
.task-index .task-group .container {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  position: relative;
}
.task-index .task-group .icon img {
  display: block;
  height: 40px;
}
.task-index .task-group .title {
  font-weight: bold;
  font-size: 0.3rem;
}
.task-index .task-group .info {
  color: #ccc;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.task-index .task-group .state {
  position: absolute;
  z-index: 22;
  top: 15px;
  right: 15px;
}
.task-index .task-group .state.progress {
  color: red;
}
.task-index .task-group .state.completed {
  top: 5px;
  right: 5px;
}
.task-index .task-group .state.completed img {
  display: block;
  width: 50px;
}
.task-index .cdk {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}
.task-index .cdk .mask {
  display: block;
  position: absolute;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.task-index .cdk .entity {
  position: relative;
  z-index: 777;
  background: center top no-repeat;
  background-size: 100% 100%;
  width: 325px;
  padding: 100px 40px 20px 40px;
  overflow: hidden;
}
.task-index .cdk .title {
  font-size: 0.32rem;
  color: #000;
  text-align: center;
}
.task-index .cdk .info {
  color: #ccc;
  font-size: 13px;
  padding: 10px 0;
}
.task-index .cdk .desc {
  color: #000;
}
.task-index .cdk .desc h1 {
  font-size: 0.3rem;
}
.task-index .cdk .desc p {
  font-size: 13px;
}
.task-index .cdk .input {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.task-index .cdk .input input {
  border: 1px solid #ccc;
  border-radius: 20px;
  flex: 1;
  width: 0;
  padding: 5px 10px;
  font-size: 0.28rem;
}
.task-index .cdk .input .btn {
  background-color: #ffc000;
  color: #fff;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 20px;
  text-align: center;
  margin-left: 10px;
}
.task-index .cdk .close {
  position: relative;
  z-index: 777;
  margin-top: 20px;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 0.3rem;
}
.sign-index {
  overflow: hidden;
  min-height: 100vh;
  padding: 12.5px;
  padding-top: calc(env(safe-area-inset-top) + 48px);
  background: #eee center top no-repeat;
  background-size: contain;
}
.sign-index .sign-title {
  padding-top: 20%;
  padding-bottom: 20%;
  padding-right: 30%;
  font-size: 12px;
  color: #fff;
}
.sign-index .sign-title img {
  display: block;
  margin-left: -14px;
  margin-bottom: 10px;
}
.sign-index .sign-calendar {
  background-color: #fff;
  border-radius: 8px;
  padding: 12.5px;
}
.sign-index .sign-calendar .title {
  font-size: 0.28rem;
  color: #000;
  text-align: center;
  font-weight: bold;
}
.sign-index .sign-calendar .main {
  margin-top: 10px;
}
.sign-index .sign-calendar .item {
  float: left;
  width: 14.285%;
  overflow: hidden;
  text-align: center;
  height: 40px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background: center no-repeat;
  background-size: contain;
}
.sign-index .sign-calendar .item.disable {
  color: #ccc;
}
.sign-index .sign-calendar .item.active {
  color: #fff;
}
.sign-index .sign-operate {
  margin-top: 30px;
  text-align: center;
}
.sign-index .sign-operate .vip {
  display: block;
  background-color: #ffc000;
  width: 200px;
  margin: 10px auto;
  font-size: 0.3rem;
  color: #fff;
  border-radius: 20px;
  padding: 7.5px 10px;
}
.sign-index .sign-operate .role {
  display: block;
  padding: 10px;
  color: #ffc000;
  font-size: 0.28rem;
}
.sign-index .sign-alert {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}
.sign-index .sign-alert .mask {
  display: block;
  position: absolute;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.sign-index .sign-alert .entity {
  position: relative;
  z-index: 777;
  background: center top no-repeat;
  background-size: 100% 100%;
  width: 300px;
  padding: 100px 35px 20px 35px;
  color: #fff;
  overflow: hidden;
  text-align: center;
}
.sign-index .sign-alert .info {
  font-size: 0.3rem;
}
.sign-index .sign-alert .desc {
  width: 185px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
}
.sign-index .sign-alert .desc:before {
  content: "";
  width: 30px;
  height: 1px;
  background-color: #fff;
  margin-right: 10px;
}
.sign-index .sign-alert .desc:after {
  content: "";
  width: 30px;
  height: 1px;
  background-color: #fff;
  margin-left: 10px;
}
.sign-index .sign-alert .btn {
  background-color: #ffc000;
  padding: 5px 10px;
  width: 200px;
  margin: 0 auto;
  border-radius: 20px;
  text-align: center;
  margin-top: 25px;
}
.task-tasks {
  min-height: calc(100vh + env(safe-area-inset-bottom));
  overflow: hidden;
  /* calc(22px + env(safe-area-inset-bottom)) */
  background: #f35207 center calc(22px + env(safe-area-inset-bottom)) no-repeat;
  background-size: 100% auto;
  flex-direction: column;
  padding: calc(env(safe-area-inset-top) + 47.5%) 10px 10px 10px;
}
.task-tasks .container {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  min-height: 55vh;
  overflow: hidden;
}
.task-tasks .item {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #d8d8d8;
}
.task-tasks .item:first-child {
  border-top: none;
}
.task-tasks .item .icon {
  width: 60px;
  height: 60px;
  background: center no-repeat;
  background-size: 100% 100%;
  margin-right: 7.5px;
}
.task-tasks .item .data {
  flex: 1;
  width: 0;
  padding-left: 7.5px;
  padding-right: 7.5px;
  line-height: 1.35;
}
.task-tasks .item .data .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #3d3d3d;
  font-size: 0.28rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.task-tasks .item .data .name .progress {
  color: #ccc;
  font-size: 13px;
  margin-left: 5px;
}
.task-tasks .item .data .name .reward {
  color: #ffc000;
  font-size: 13px;
  margin-left: 5px;
}
.task-tasks .item .data .desc {
  color: rgba(61, 61, 61, 0.7);
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
}
.task-tasks .item .data .amount {
  color: #ff772d;
  font-size: 12px;
  margin-top: 2.5px;
}
.task-tasks .item .option .btn {
  margin-top: 2.5px;
  padding: 7.5px 10px;
  border-radius: 25px;
  background: #fcf15d;
  color: #3d3d3d;
  line-height: 1;
  font-size: 13px;
  transition: all 0.25s;
}
.task-tasks .item .option .btn:after {
  content: "去完成";
  transition: all 0.25s;
}
.task-tasks .item .option .btn.completed:after {
  content: "领奖励";
}
.task-tasks .item .option .btn.ing:after {
  content: "领取中";
}
.task-tasks .item .option .btn.taked {
  background: #d1d1d1;
}
.task-tasks .item .option .btn.taked:after {
  content: "已完成";
}
.task-cdk {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}
.task-cdk .mask {
  display: block;
  position: absolute;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.task-cdk .entity {
  position: relative;
  z-index: 777;
  background: center top no-repeat;
  background-size: 100% 100%;
  width: 325px;
  padding: 100px 40px 20px 40px;
  overflow: hidden;
}
.task-cdk .title {
  font-size: 0.32rem;
  color: #000;
  text-align: center;
}
.task-cdk .info {
  color: #ccc;
  font-size: 13px;
  padding: 10px 0;
}
.task-cdk .desc {
  color: #000;
}
.task-cdk .desc h1 {
  font-size: 0.3rem;
}
.task-cdk .desc p {
  font-size: 13px;
}
.task-cdk .input {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.task-cdk .input input {
  border: 1px solid #ccc;
  border-radius: 20px;
  flex: 1;
  width: 0;
  padding: 5px 10px;
  font-size: 0.28rem;
}
.task-cdk .input .btn {
  background-color: #ffc000;
  color: #fff;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 20px;
  text-align: center;
  margin-left: 10px;
}
.task-cdk .input .btn:before {
  content: "确认";
}
.task-cdk .input .btn.ing:before {
  content: "兑换中";
  opacity: 0.75;
}
.task-cdk .option {
  background-color: #ffc000;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  display: block;
  width: 100px;
  padding: 5px;
  margin: 10px auto 0;
}
.task-cdk .close {
  position: relative;
  z-index: 777;
  margin-top: 20px;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 0.3rem;
}

.faq-index {
  padding: 0.2rem;
}
.faq-index dl {
  padding: 5px;
}
.faq-index dt {
  background-size: 0.237rem auto;
  font-size: 0.32rem;
  color: #282828;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.faq-index dt .icon {
  margin-right: 5px;
  color: #fe8900;
}
.faq-index dd {
  line-height: 1.25;
  font-size: 0.28rem;
  color: #666;
}

.trade-confirm {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 3s;
}
.trade-confirm:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 901;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.25s;
  opacity: 0;
}
.trade-confirm .container {
  position: relative;
  z-index: 902;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff center top no-repeat;
  background-size: contain;
  transition: all 0.25s;
  opacity: 0;
  transform: scale(0.75);
}
.trade-confirm.appear-active:before,
.trade-confirm.appear-done:before,
.trade-confirm.enter-active:before,
.trade-confirm.enter-done:before {
  opacity: 1;
}
.trade-confirm.appear-active .container,
.trade-confirm.appear-done .container,
.trade-confirm.enter-active .container,
.trade-confirm.enter-done .container {
  opacity: 1;
  transform: scale(1);
}
.trade-confirm.exit-active:before {
  opacity: 0;
}
.trade-confirm.exit-active .container {
  opacity: 0;
  transform: scale(0.75);
}
.trade-confirm .head {
  display: flex;
  align-items: center;
  color: #3d3d3d;
  padding: 15px;
  text-align: center;
}
.trade-confirm .head .title {
  flex: 1 1;
  font-weight: bold;
  font-size: 0.3rem;
}
.trade-confirm .main {
  flex: 1;
  height: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.trade-confirm .main .title {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 0.28rem;
  font-weight: bold;
}
.trade-confirm .process {
  padding: 10px 0;
}
.trade-confirm .process img {
  display: block;
  width: 85%;
  margin: 0 auto;
}
.trade-confirm .process ul {
  margin-top: 10px;
}
.trade-confirm .process ul li {
  float: left;
  width: 25%;
  text-align: center;
}
.trade-confirm .body {
  flex: 1;
  height: 0;
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.trade-confirm .body p {
  padding-left: 15px;
  position: relative;
  margin-bottom: 5px;
  font-size: 12px;
}
.trade-confirm .body p:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #fcf15d;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.trade-confirm .operate .input {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.trade-confirm .operate .input input {
  margin-right: 10px;
}
.trade-confirm .operate .buy {
  display: block;
  background-color: #ffc000;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
  padding: 10px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-bottom: 7.5px;
  transition: all 0.25s;
}
.trade-confirm .operate .buy.disable {
  background-color: #eee;
}
.trade-detail {
  padding-bottom: calc(env(safe-area-inset-bottom) + 47.5px);
  background-color: #f5f5f8;
}
.trade-detail .trade-game {
  overflow: hidden;
  padding: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.trade-detail .trade-game .icon {
  flex-shrink: 0;
  width: 1.08rem;
  height: 1.08rem;
  border-radius: 0.2rem;
  overflow: hidden;
  margin-right: 0.19rem;
}
.trade-detail .trade-game .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.trade-detail .trade-game .data {
  flex: 1;
  width: 0;
}
.trade-detail .trade-game .name {
  font-size: 0.28rem;
  color: #000;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.trade-detail .trade-game .branch {
  color: red;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
  font-weight: bold;
}
.trade-detail .trade-game .desc {
  font-size: 0.26rem;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.trade-detail .trade-game .desc span {
  padding-right: 5px;
}
.trade-detail .trade-game .opt .btn {
  background: #fcf15d;
  border-radius: 20px;
  padding: 7.5px 12.5px;
  color: #3d3d3d;
  font-size: 13px;
  line-height: 1;
}
.trade-detail .trade-box {
  background-color: #fff;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}
.trade-detail .trade-title {
  font-size: 15px;
  font-weight: 600;
  color: #3d3d3d;
  margin-top: 10px;
}
.trade-detail .trade-title:first-child {
  margin-top: 0;
}
.trade-detail .trade-title span {
  color: red;
}
.trade-detail .trade-content {
  margin-top: 5px;
  color: #999999;
}
.trade-detail .trade-content span {
  color: red;
}
.trade-detail .trade-content.err {
  color: red;
}
.trade-detail .trade-price {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  padding: 10px;
}
.trade-detail .trade-price span {
  font-size: 20px;
}
.trade-detail .trade-state {
  position: absolute;
  top: 25%;
  right: 5%;
}
.trade-detail .trade-state img {
  display: block;
  width: 75px;
}
.trade-detail .trade-imgs .item {
  display: block;
  float: left;
  width: 33.333%;
  padding-right: 12.5px;
  padding-top: 12.5px;
}
.trade-detail .trade-imgs .item .img {
  display: block;
  width: 100%;
}
.trade-detail .trade-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 5px;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  display: flex;
}
.trade-detail .trade-operate .btn {
  flex: 1;
  margin: 0 5px;
  background-color: #ffc000;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 0.28rem;
  border-radius: 20px;
}
.nav-view-head .operate .trade-operates .anticon {
  font-size: 22px;
  margin-left: 10px;
}
.trade-trades {
  background-color: #f6f7f9;
}
.trade-trades > .trade-head {
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  padding-top: env(safe-area-inset-top);
  background-color: #f6f7f9;
  display: none;
}
.trade-trades > .trade-head .container {
  position: relative;
}
.trade-trades > .trade-head .container .text {
  height: 40px;
  font-size: 18px;
  color: #3d3d3d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trade-trades > .trade-head .container .option {
  position: absolute;
  right: 2.5%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #3d3d3d;
}
.trade-trades > .trade-body {
  padding-top: calc(env(safe-area-inset-top) + 40px);
}
.trade-trades > .trade-body > .trade-head {
  position: fixed;
  z-index: 22;
  left: 0;
  right: 0;
  background-color: #f6f7f9;
}
.trade-trades > .trade-body .trade-body-item {
  display: none;
}
.trade-trades > .trade-body .trade-body-item.active {
  display: block;
}
.trade-trades .trade-nav {
  display: flex;
  padding: 10px 5px 5px 5px;
}
.trade-trades .trade-nav .item {
  flex: 1;
  margin: 0 5px;
  border-radius: 8px;
}
.trade-trades .trade-nav .item img {
  display: block;
  width: 100%;
}
.trade-trades .trade-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  margin: 10px 0 10px 0;
}
.trade-trades .trade-tab .item {
  width: 33.333%;
  position: relative;
  text-align: center;
  font-size: 0.3rem;
  padding-bottom: 7.5px;
  transition: all 0.25s;
  color: #999999;
}
.trade-trades .trade-tab .item:before {
  content: "";
  display: block;
  width: 25px;
  height: 2px;
  background-color: #f6f7f9;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s;
}
.trade-trades .trade-tab .item.active {
  font-size: 0.34rem;
  color: #3d3d3d;
  font-weight: bold;
}
.trade-trades .trade-tab .item.active:before {
  background-color: #ffc000;
}
.trade-trades .trade-bar {
  background-color: #000;
  position: fixed;
  z-index: 22;
  left: 0;
  right: 0;
}
.trade-trades .trade-bar .container {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #f6f7f9;
  padding: 0 10px;
}
.trade-trades .trade-bar .sort {
  font-size: 0.3rem;
  color: #000;
}
.trade-trades .trade-bar .sort .icon {
  margin: 0 5px;
}
.trade-trades .trade-bar .sorts {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 555;
  top: 100%;
  left: 0;
  box-shadow: 0 0 3px #eee;
  transition: all 0.25s;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top center;
}
.trade-trades .trade-bar .sorts.enter-active,
.trade-trades .trade-bar .sorts.enter-done {
  opacity: 1;
  transform: scaleY(1);
}
.trade-trades .trade-bar .sorts.exit-active {
  opacity: 0;
  transform: scaleY(0);
}
.trade-trades .trade-bar .sorts .item {
  padding: 5px;
  font-size: 0.28rem;
  color: #000;
}
.trade-trades .trade-bar .sorts .item.active {
  color: #ffc000;
}
.trade-trades .trade-bar .filter {
  color: #000;
  font-size: 0.34rem;
  margin-right: 10px;
}
.trade-trades .trade-bar .input {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #eee;
  border-radius: 20px;
  padding: 7.5px 12.5px;
}
.trade-trades .trade-bar .input input {
  flex: 1;
  background: none;
  border: none;
}
.trade-trades .trade-bar .input .close {
  padding-right: 5px;
}
.trade-trades .trade-bar .style {
  padding: 5px;
}
.trade-trades .trade-bar .style img {
  display: block;
  width: 20px;
  height: 20px;
}
.trade-trades .trade-filter {
  position: fixed;
  z-index: 777;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.trade-trades .trade-filter .mask {
  position: fixed;
  z-index: 888;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.25s;
  opacity: 0;
}
.trade-trades .trade-filter .container {
  position: relative;
  z-index: 999;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.trade-trades .trade-filter.enter-active .mask,
.trade-trades .trade-filter.enter-done .mask {
  opacity: 1;
}
.trade-trades .trade-filter.enter-active .container,
.trade-trades .trade-filter.enter-done .container {
  opacity: 1;
  transform: translateY(0);
}
.trade-trades .trade-filter.exit-active .mask {
  opacity: 0;
}
.trade-trades .trade-filter.exit-active .container {
  opacity: 0;
  transform: translateY(100%);
}
.trade-trades .trade-filter .title {
  font-size: 0.3rem;
  color: #000;
  font-weight: bold;
  margin: 10px auto;
}
.trade-trades .trade-filter .type {
  display: flex;
  align-items: center;
}
.trade-trades .trade-filter .type .item {
  width: 65px;
  margin-right: 10px;
  text-align: center;
  padding: 5px 0;
  background-color: #ccc;
  color: #000;
  font-size: 0.28rem;
  border-radius: 4px;
}
.trade-trades .trade-filter .type .item.active {
  background-color: #ffc000;
  color: #fff;
}
.trade-trades .trade-filter .prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade-trades .trade-filter .prices input {
  border: 1px solid #eee;
  padding: 5px;
  color: #000;
  border-radius: 4px;
  text-align: center;
}
.trade-trades .trade-filter .tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -10px;
}
.trade-trades .trade-filter .tag .item {
  flex: 0 0 25%;
}
.trade-trades .trade-filter .tag .item span {
  display: block;
  margin-left: 10px;
  margin-top: 10px;
  text-align: center;
  padding: 5px 0;
  background-color: #ccc;
  color: #000;
  font-size: 0.28rem;
  border-radius: 4px;
}
.trade-trades .trade-filter .tag .item.active span {
  background-color: #ffc000;
  color: #fff;
}
.trade-trades .trade-filter .operate {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.trade-trades .trade-filter .operate .btn {
  flex: 1;
  background-color: #ffc000;
  border: 1px solid #ffc000;
  color: #fff;
  border-radius: 20px;
  padding: 7.5px 0;
  text-align: center;
}
.trade-trades .trade-filter .operate .btn:first-child {
  margin-right: 10px;
  background-color: #fff;
  color: #ffc000;
}
.trade-trades .trade-list {
  padding: 40px 10px 10px 10px;
}

.trade-trades .trade-list-block {
  display: flex;
  flex-wrap: wrap;
}
.trade-trades .trade-list .item-block {
  width: 50%;
  padding: 0 5px;
}
.trade-trades .trade-list .item-block:nth-child(1),
.trade-trades .trade-list .item-block:nth-child(2) {
  margin-top: 10px;
}
.trade-trades .trade-list .item-block .body {
  margin-bottom: 10px;
  background-color: #fff;
  display: block;
  border-radius: 8px;
  overflow: hidden;
}
.trade-trades .trade-list .item-block .main .icon {
  width: 100%;
  padding-top: 55%;
  overflow: hidden;
  position: relative;
}
.trade-trades .trade-list .item-block .main .icon img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}
.trade-trades .trade-list .item-block .main .info {
  padding: 5px 7.5px;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.trade-trades .trade-list .item-block .main .info .name {
  font-size: 0.28rem;
  color: #000;
  display: flex;
  align-items: center;
}
.trade-trades .trade-list .item-block .main .info .name .type {
  background-color: #ffc000;
  border-radius: 2px;
  padding: 2px 3px;
  color: #fff;
  font-size: 12px;
  margin-right: 5px;
}
.trade-trades .trade-list .item-block .main .info .name .type-1:after {
  content: "BT版";
}
.trade-trades .trade-list .item-block .main .info .name .type-2:after {
  content: "折扣版";
}
.trade-trades .trade-list .item-block .main .info .name .type-3:after {
  content: "H5版";
}
.trade-trades .trade-list .item-block .main .info .name .type-4:after {
  content: "GM版";
}
.trade-trades .trade-list .item-block .main .info .name .named {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-trades .trade-list .item-block .main .info .title {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.trade-trades .trade-list .item-block .main .info .title .ios {
  font-size: 16px;
  color: #666666;
  margin-right: 2.5px;
}
.trade-trades .trade-list .item-block .main .info .title .android {
  font-size: 16px;
  color: green;
  margin-right: 2.5px;
}
.trade-trades .trade-list .item-block .main .info .title .titled {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-trades .trade-list .item-block .data {
  padding: 5px 7.5px;
}
.trade-trades .trade-list .item-block .data .time {
  display: none;
}
.trade-trades .trade-list .item-block .data .prices {
  display: flex;
  align-items: center;
  margin-top: -5px;
  text-align: center;
}
.trade-trades .trade-list .item-block .data .prices .amount {
  text-decoration: line-through;
  color: #ccc;
  font-size: 12px;
  line-height: 15px;
}
.trade-trades .trade-list .item-block .data .prices .price {
  color: #ffa21c;
  font-size: 0.3rem;
  margin-right: 10px;
}
.trade-trades .trade-list .item-list {
  background-color: #fff;
  display: block;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
  padding: 7.5px;
}
.trade-trades .trade-list .item-list:first-child {
  margin-top: 10px;
}
.trade-trades .trade-list .item-list .main {
  display: flex;
  align-items: flex-start;
}
.trade-trades .trade-list .item-list .main .icon {
  width: 135px;
  height: 75px;
  margin-right: 10px;
  border-radius: 8px;
  overflow: hidden;
}
.trade-trades .trade-list .item-list .main .icon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.trade-trades .trade-list .item-list .main .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.trade-trades .trade-list .item-list .main .info .name {
  font-size: 0.3rem;
  color: #000;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-trades .trade-list .item-list .main .info .name .type {
  background-color: #ffc000;
  border-radius: 2px;
  padding: 2px 3px;
  color: #fff;
  font-size: 12px;
  margin-right: 5px;
}
.trade-trades .trade-list .item-list .main .info .name .type-1:after {
  content: "BT版";
}
.trade-trades .trade-list .item-list .main .info .name .type-2:after {
  content: "折扣版";
}
.trade-trades .trade-list .item-list .main .info .name .type-3:after {
  content: "H5版";
}
.trade-trades .trade-list .item-list .main .info .name .type-4:after {
  content: "GM版";
}
.trade-trades .trade-list .item-list .main .info .branch {
  color: red;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  font-weight: bold;
}
.trade-trades .trade-list .item-list .main .info .title {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-trades .trade-list .item-list .main .info .title .ios {
  font-size: 16px;
  color: #666666;
  margin-right: 2.5px;
}
.trade-trades .trade-list .item-list .main .info .title .android {
  font-size: 16px;
  color: green;
  margin-right: 2.5px;
}
.trade-trades .trade-list .item-list .data {
  display: flex;
  align-items: center;
}
.trade-trades .trade-list .item-list .data .time {
  flex: 1;
  width: 0;
  color: #ccc;
  font-size: 12px;
  padding-top: 5px;
}
.trade-trades .trade-list .item-list .data .prices {
  position: relative;
  text-align: right;
}
.trade-trades .trade-list .item-list .data .prices .amount {
  text-decoration: line-through;
  color: #ccc;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: -15px;
  right: 0;
}
.trade-trades .trade-list .item-list .data .prices .price {
  color: #ffa21c;
  font-size: 0.3rem;
}
.nav-view .trade-trades > .trade-body {
  padding-top: 0;
}
.screen-body .trade-head {
  display: block;
}
.screen-body .trade-index {
  padding-top: calc(env(safe-area-inset-top) + 48px);
}
.trade-notice .notice-info {
  font-size: 0.26rem;
  padding: 10px;
  color: rgba(61, 61, 61, 0.7);
}
.trade-notice .notice-process {
  padding-top: 10px;
  border-bottom: 2px solid #ccc;
}
.trade-notice .notice-process:last-child {
  border-bottom: 0;
}
.trade-notice .notice-process .title {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 0.28rem;
  padding: 0 10px;
  font-weight: bold;
}
.trade-notice .notice-process .process {
  padding: 10px 0;
}
.trade-notice .notice-process .process img {
  display: block;
  width: 85%;
  margin: 0 auto;
}
.trade-notice .notice-process .process ul {
  margin-top: 10px;
}
.trade-notice .notice-process .process ul li {
  float: left;
  width: 25%;
  text-align: center;
}
.trade-notice .notice-process .body {
  padding: 10px;
  overflow: hidden;
}
.trade-notice .notice-process .body p {
  padding-left: 15px;
  position: relative;
  margin-bottom: 5px;
  font-size: 12px;
}
.trade-notice .notice-process .body p:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #fcf15d;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.trade-good {
  background-color: #f8f8f8;
  padding: 10px;
}
.trade-good .good-img {
  padding: 0 10px;
}
.trade-good .good-img img {
  display: block;
  margin: 0 auto;
}
.trade-submit .trade-body {
  padding: 10px;
}
.trade-submit .trade-input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.trade-submit .trade-input.develop {
  flex-direction: column;
  align-items: flex-start;
}
.trade-submit .trade-input .name {
  flex: 1;
  width: 0;
  font-size: 0.28rem;
  color: #333;
}
.trade-submit .trade-input .value {
  overflow: hidden;
  position: relative;
}
.trade-submit .trade-input.develop .name {
  width: 100%;
}
.trade-submit .trade-input.develop .value {
  width: 100%;
  margin-top: 5px;
}
.trade-submit .trade-input select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.trade-submit .trade-input input {
  width: 100%;
  background: none;
  border: none;
  text-align: right;
  padding: 5px;
}
.trade-submit .trade-input textarea {
  width: 100%;
  padding: 5px;
  background: none;
  border: none;
}
.trade-submit .trade-input.develop input {
  text-align: left;
}
.trade-submit .trade-tips {
  font-size: 13px;
  padding-bottom: 60px;
}
.trade-submit .trade-tips p {
  margin-top: 10px;
}
.trade-submit .trade-tips span {
  color: #ffc000;
}
.trade-submit .trade-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 7.5px 10px env(safe-area-inset-bottom) 10px;
  display: flex;
  align-items: center;
}
.trade-submit .trade-operate .btn {
  flex: 1;
  background-color: #ffc000;
  border-radius: 20px;
  padding: 7.5px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-bottom: 7.5px;
}
.trade-record .record-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.trade-record .record-head .item {
  flex: 1;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  color: #333;
}
.trade-record .record-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.trade-record .record-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.trade-record .record-head .item.active:after {
  display: block;
}
.trade-record .record-body {
  margin-top: 45px;
  padding: 12.5px;
  position: relative;
  background-color: #f8f8f8;
}
.trade-record .record-body .type-item {
  display: none;
}
.trade-record .record-body .type-item.active {
  display: block;
}
.trade-record .record-body .type-item-submit {
  padding-top: 30px;
}
.trade-record .record-body .type-item .am-swipe-btn {
  color: #fff;
  background-color: red;
}
.trade-record .record-body .type-item .items .item {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
}
.trade-record .record-body .type-item .items .body {
  background-color: #fff;
  padding: 10px;
  flex: 1;
  transition: 0.1s;
}
.trade-record .record-body .type-item .items .operate {
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  max-width: 0;
  padding: 0;
}
.trade-record .record-body .type-item .items .operable .operate {
  padding: 15px;
  max-width: 111px;
}
.trade-record .record-body .type-item .items .main {
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .main .icon {
  width: 75px;
  height: 55px;
  margin-right: 10px;
}
.trade-record .record-body .type-item .items .main .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.trade-record .record-body .type-item .items .main .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.trade-record .record-body .type-item .items .main .info .name {
  font-size: 0.3rem;
  color: #000;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-record .record-body .type-item .items .main .info .name .type {
  background-color: #ffc000;
  border-radius: 2px;
  padding: 2px 3px;
  color: #fff;
  font-size: 12px;
  margin-right: 5px;
}
.trade-record .record-body .type-item .items .main .info .name .type-1:after {
  content: "BT版";
}
.trade-record .record-body .type-item .items .main .info .name .type-2:after {
  content: "折扣版";
}
.trade-record .record-body .type-item .items .main .info .name .type-3:after {
  content: "H5版";
}
.trade-record .record-body .type-item .items .main .info .name .type-4:after {
  content: "GM版";
}
.trade-record .record-body .type-item .items .main .info .branch {
  color: red;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  font-weight: bold;
}
.trade-record .record-body .type-item .items .main .info .title {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-record .record-body .type-item .items .main .info .title .ios {
  font-size: 16px;
  color: #666666;
  margin-right: 2.5px;
}
.trade-record .record-body .type-item .items .main .info .title .android {
  font-size: 16px;
  color: green;
  margin-right: 2.5px;
}
.trade-record .record-body .type-item .items .data {
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .data .time {
  flex: 1;
  width: 0;
  color: #ccc;
  font-size: 12px;
  padding-top: 5px;
}
.trade-record .record-body .type-item .items .data .prices {
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .data .prices .amount {
  text-decoration: line-through;
  color: #ccc;
  font-size: 12px;
  margin-right: 10px;
}
.trade-record .record-body .type-item .items .data .prices .price {
  color: #ffa21c;
  font-size: 0.3rem;
}
.trade-record .record-tips {
  position: fixed;
  z-index: 333;
  top: calc(env(safe-area-inset-top) + 48px + 45px);
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 0 5px;
  text-align: center;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.server-index .server-type {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.server-index .server-type .item {
  width: 33.333%;
  float: left;
  text-align: center;
  font-size: 0.28rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.server-index .server-type .item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 2px;
  background-color: #ffc000;
  opacity: 0;
}
.server-index .server-type .item.active {
  font-size: 0.3rem;
  color: #000;
}
.server-index .server-type .item.active:after {
  opacity: 1;
}
.server-index .server-body {
  margin-top: 40px;
}
.server-index .server-body-item {
  overflow: hidden;
  display: none;
}
.server-index .server-body-item.active {
  display: block;
}
.server-index .server-time {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 48px + 48px);
  left: 0;
  right: 0;
  height: 40px;
  padding: 0 10px;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-items: center;
}
.server-index .server-time .item {
  flex-shrink: 0;
  margin-left: 10px;
  background-color: #eee;
  padding: 3.5px 6.5px;
  border-radius: 12px;
}
.server-index .server-time .item.active {
  background-color: #ffc000;
  color: #fff;
}
.server-index .server-time .item:first-child {
  margin-left: 0;
}
.server-index .server-groups {
  padding: 0 10px 10px 10px;
}
.server-index .server-group {
  margin-top: 10px;
}
.server-index .server-group .group-title {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
}
.server-index .server-group .group-title .icon {
  font-size: 18px;
  margin-right: 5px;
  color: #ffc000;
}
.server-index .server-item {
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
}
.server-index .server-item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.server-index .server-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.server-index .server-item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
}
.server-index .server-item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.server-index .server-item .desc {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.server-index .server-item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: 700;
}
.server-index .server-item .info {
  display: flex;
  align-items: center;
  color: #ffc000;
}
.server-index .server-item .info .server {
  color: #ff5e00;
  margin-right: 10px;
}
.server-index .server-item .info .time {
  color: #ffc000;
  margin-right: 10px;
}
.server-index .server-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.server-index .server-tag {
  position: fixed;
  z-index: 555;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.server-index .server-tag .mask {
  position: fixed;
  z-index: 666;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
  opacity: 0;
}
.server-index .server-tag .container {
  position: fixed;
  z-index: 777;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.server-index .server-tag.enter-active .mask,
.server-index .server-tag.enter-done .mask {
  opacity: 1;
}
.server-index .server-tag.enter-active .container,
.server-index .server-tag.enter-done .container {
  opacity: 1;
  transform: translateY(0);
}
.server-index .server-tag.exit-active .mask {
  opacity: 0;
}
.server-index .server-tag.exit-active .container {
  opacity: 0;
  transform: translateY(100%);
}
.server-index .server-tag .title {
  font-size: 0.28rem;
  color: #000;
}
.server-index .server-tag .tags {
  margin-left: -5px;
  margin-right: -5px;
}
.server-index .server-tag .tag {
  margin-top: 0;
  float: left;
  width: 25%;
  padding: 10px;
  color: #000;
}
.server-index .server-tag .tag div {
  background-color: #eee;
  padding: 5px;
  text-align: center;
}
.server-index .server-tag .tag.active {
  color: #fff;
}
.server-index .server-tag .tag.active div {
  background-color: #ffc000;
}
.server-index .server-today {
  margin-top: 40px;
}
.user-voucher-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
}
.user-voucher-head .items {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.user-voucher-head .item {
  width: 33.333%;
  position: relative;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.user-voucher-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.user-voucher-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.user-voucher-head .item.active:after {
  display: block;
}
.user-voucher-head .tips {
  font-size: 13px;
  text-align: center;
  color: #ccc;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-voucher-body {
  background-color: #f8f8f8;
  min-height: calc(100vh - 40px - 80px - env(safe-area-inset-top));
  margin-top: 45px;
  padding: 12.5px;
  position: relative;
}
.user-voucher-body .type-item {
  display: none;
}
.user-voucher-body .type-item.active {
  display: block;
}
.user-voucher-body .type-item .empty {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  background: center no-repeat;
}
.user-voucher-body .voucher-item {
  margin-top: 10px;
  display: flex;
  border-radius: 8px;
  background: center no-repeat;
  background-size: 100% 100%;
}
.user-voucher-body .voucher-item:first-child {
  margin-top: 0;
}
.user-voucher-body .voucher-item .amounts {
  width: 26.811%;
  flex-shrink: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
}
.user-voucher-body .voucher-item .amounts .amount {
  font-size: 0.32rem;
}
.user-voucher-body .voucher-item .amounts .amount-meet {
  margin-top: 5px;
  font-size: 12px;
}
.user-voucher-body .voucher-item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  padding: 10px 15px 10px 15px;
}
.user-voucher-body .voucher-item .info .name {
  color: #000;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-voucher-body .voucher-item .info .time {
  color: #999;
}
.user-voucher-body .voucher-item .info .game {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-voucher-body .voucher-item .info .use {
  color: #f5842d;
}
.user-voucher-body .voucher-item .operate {
  width: 14.492%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.35;
  color: #f5842d;
}
.user-voucher-body .voucher-item .operate:before {
  content: "立即使用";
  display: block;
  width: 20px;
  text-align: center;
}
.user-voucher-body .voucher-item.usable .operate:before {
  content: "立即使用";
}
.user-voucher-body .voucher-item.used .operate:before {
  content: "已使用";
}
.user-voucher-body .voucher-item.expire .operate:before {
  content: "已过期";
}
.user-voucher-body .voucher-item.invalid .operate {
  color: #999;
}
.user-game .ug-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 45px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.user-game .ug-head .item {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  position: relative;
  cursor: pointer;
}
.user-game .ug-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.user-game .ug-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.user-game .ug-head .item.active:after {
  display: block;
}
.user-game .ug-head .item.badge:before {
  content: "";
  position: absolute;
  z-index: 23;
  top: 12.5%;
  right: 25%;
  background-color: red;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}
.user-game .ug-body {
  background-color: #f8f8f8;
  min-height: calc(100vh - 40px - 45px - env(safe-area-inset-top));
  margin-top: 45px;
  position: relative;
}
.user-game .ug-body .voucher-item {
  margin-top: 10px;
  display: flex;
  border-radius: 8px;
  background: center no-repeat;
  background-size: 100% 100%;
}
.user-game .ug-body .voucher-item:first-child {
  margin-top: 0;
}
.user-game .ug-body .voucher-item .amounts {
  width: 26.811%;
  flex-shrink: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
}
.user-game .ug-body .voucher-item .amounts .amount {
  font-size: 0.32rem;
}
.user-game .ug-body .voucher-item .amounts .amount-meet {
  margin-top: 5px;
  font-size: 12px;
}
.user-game .ug-body .voucher-item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  padding: 10px 15px 10px 15px;
}
.user-game .ug-body .voucher-item .info .name {
  color: #000;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-game .ug-body .voucher-item .info .time {
  color: #999;
}
.user-game .ug-body .voucher-item .info .game {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-game .ug-body .voucher-item .info .use {
  color: #f5842d;
}
.user-game .ug-body .voucher-item .operate {
  width: 14.492%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.35;
  color: #f5842d;
}
.user-game .ug-body .voucher-item .operate:before {
  content: "立即使用";
  display: block;
  width: 20px;
  text-align: center;
}
.user-game .ug-body .voucher-item.usable .operate:before {
  content: "立即使用";
}
.user-game .ug-body .voucher-item.used .operate:before {
  content: "已使用";
}
.user-game .ug-body .voucher-item.expire .operate:before {
  content: "已过期";
}
.user-game .ug-body .voucher-item.invalid .operate {
  color: #999;
}
.user-game .ug-body > .swiper-container > .swiper-wrapper > .swiper-slide {
  height: auto;
}
.user-game .ug-type {
  position: relative;
  min-height: calc(100vh - 40px - 45px - env(safe-area-inset-top));
  display: none;
  flex-direction: column;
}
.user-game .ug-type.active {
  display: flex;
}
.user-game .ug-main {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
}
.user-game .ug-extra {
  background-color: #fff;
  z-index: 1234;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 10px solid #f8f8f8;
}
.user-game .ug-extra-item {
  display: none;
}
.user-game .ug-extra-item.active {
  display: block;
}
.user-game .ug-tips {
  margin: 10px 10px 0 10px;
  background-color: #fff;
  color: #ff3c3c;
  font-size: 13px;
  padding: 7.5px;
  line-height: 1.5;
}
.user-game .ug-game {
  padding: 0 10px 10px 10px;
}
.user-game .ug-game .item {
  display: block;
  padding: 12.5px;
  background-color: #fff;
  margin-top: 10px;
}
.user-game .ug-game .item .body {
  display: flex;
  align-items: stretch;
}
.user-game .ug-game .item .body .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.user-game .ug-game .item .body .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.user-game .ug-game .item .body .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
}
.user-game .ug-game .item .body .data .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
}
.user-game .ug-game .item .body .data .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
  margin-top: 2.5px;
}
.user-game .ug-game .item .body .data .desc {
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}
.user-game .ug-game .item .body .data .desc .special {
  color: red;
}
.user-game .ug-game .item .body .opt {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}
.user-game .ug-game .item .body .opt .tips {
  font-size: 13px;
  color: #ff3c3c;
  line-height: 1;
}
.user-game .ug-game .item .body .opt .btn {
  width: 1.75rem;
  border-radius: 0.25rem;
  background: #fcf15d;
  font-size: 0.28rem;
  line-height: 1;
  text-align: center;
  padding: 7.5px 0;
  color: #3d3d3d;
}
.user-game .ug-game .item .foot {
  margin-top: 7.5px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: flex-end;
}
.user-game .ug-game .item .foot .btn {
  min-width: 1.75rem;
  border-radius: 0.25rem;
  background: #fcf15d;
  font-size: 0.28rem;
  line-height: 1;
  text-align: center;
  padding: 7.5px 15px;
  color: #3d3d3d;
  margin-left: 10px;
  position: relative;
}
.user-game .ug-game .item .foot .btn:first-child {
  background: #0256ff;
  color: #fff;
}
.user-game .ug-game .item .foot .btn .tips {
  margin: 0;
  position: absolute;
  top: -10px;
  right: 0;
  height: 25px;
  transform: translateY(-100%);
  background: #3c4046;
  padding: 2.5px 7.5px;
  line-height: 1;
  border-radius: 4px;
  color: #fff;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 2.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.user-game .ug-game .item .foot .btn .tips:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #3c4046;
}
.user-game .ug-empty {
  flex: 1;
  height: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  padding: 10px;
}
.user-game .ug-empty img {
  display: block;
  max-height: 33.333vh;
}
.user-game .ug-empty p {
  font-size: 16px;
  padding: 10px;
  color: #ccc;
  text-align: center;
}
.user-game .favorite-game .item {
  padding: 12.5px;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.user-game .favorite-game .item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.user-game .favorite-game .item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.user-game .favorite-game .item .subject {
  flex: 1;
  width: 0;
  border-bottom: 1px solid #dedede;
  padding-bottom: 0.2rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.user-game .favorite-game .item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.user-game .favorite-game .item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.user-game .favorite-game .item .desc {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.user-game .favorite-game .item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.user-game .favorite-game .item .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.24rem;
  color: #ff5e00;
}
.user-game .favorite-game .item .tag {
  display: inline-block;
  padding-left: 5px;
}
.user-game .favorite-game .item .tag:first-child {
  padding-left: 0;
}
.user-game .favorite-game .item .tag:nth-child(1) {
  color: #ff4200;
}
.user-game .favorite-game .item .tag:nth-child(2) {
  color: #75d100;
}
.user-game .favorite-game .item .tag:nth-child(3) {
  color: #2893ff;
}
.user-game .favorite-game .item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.user-game .favorite-trade {
  padding: 5px;
}
.user-game .favorite-trade .item {
  display: block;
  margin-top: 10px;
  background-color: #fff;
  padding: 10px 12.5px;
  border-radius: 4px;
  border-bottom: 1px solid #ccc;
}
.user-game .favorite-trade .item:first-child {
  margin-top: 0;
}
.user-game .favorite-trade .item .info {
  display: flex;
  align-items: center;
}
.user-game .favorite-trade .item .info .icon {
  width: 75px;
  height: 55px;
  margin-right: 10px;
}
.user-game .favorite-trade .item .info .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.user-game .favorite-trade .item .info .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.user-game .favorite-trade .item .info .text .name {
  font-size: 0.3rem;
  color: #000;
}
.user-game .favorite-trade .item .info .text .desc {
  margin-top: 5px;
}
.user-game .favorite-trade .item .data {
  display: flex;
  align-items: center;
}
.user-game .favorite-trade .item .data .time {
  flex: 1;
  width: 0;
  color: #ccc;
  font-size: 12px;
  padding-top: 5px;
}
.user-game .favorite-trade .item .data .price {
  display: flex;
  align-items: center;
}
.user-game .favorite-trade .item .data .price .amount {
  text-decoration: line-through;
  color: #ccc;
  font-size: 12px;
  margin-right: 10px;
}
.user-game .favorite-trade .item .data .price .price {
  color: #ffa21c;
  font-size: 0.3rem;
}
.user-game .hot-reserve {
  margin-top: 10px;
  padding: 10px;
}
.user-game .hot-reserve-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-game .hot-reserve-head .title img {
  display: block;
  height: 17.5px;
}
.user-game .hot-reserve-head .opt {
  font-size: 15px;
  color: #ccc;
}
.user-game .hot-reserve-body {
  margin-top: 7.5px;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}
.user-game .hot-reserve-body .item {
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-top: 10px;
}
.user-game .hot-reserve-body .item:first-child {
  margin-top: 0;
}
.user-game .hot-reserve-body .item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.user-game .hot-reserve-body .item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.user-game .hot-reserve-body .item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
}
.user-game .hot-reserve-body .item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.user-game .hot-reserve-body .item .desc {
  width: 100%;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.user-game .hot-reserve-body .item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.user-game .hot-reserve-body .item .time {
  background-color: #ff3c3c;
  color: #fff;
  border-radius: 4px;
  padding: 1px 3.5px;
  font-size: 13px;
}
.user-game .hot-reserve-body .item .btn {
  padding: 6.5px 11.5px;
  border-radius: 8px;
  background: #ff3c3c;
  font-size: 13px;
  line-height: 1;
  color: #fff;
  text-align: center;
  transition: all 0.25s;
}
.user-game .hot-reserve-body .item .btn:before {
  content: "预约";
}
.user-game .hot-reserve-body .item .btn.active {
  opacity: 0.75;
}
.user-game .hot-reserve-body .item .btn.active:before {
  content: "取消";
}
.user-game .like-game {
  margin-top: 10px;
  padding: 10px;
}
.user-game .like-game-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-game .like-game-head .title img {
  display: block;
  height: 17.5px;
}
.user-game .like-game-head .opt {
  font-size: 15px;
  color: #ccc;
}
.user-game .like-game-body {
  margin-top: 7.5px;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}
.user-game .like-game-body .swiper-container {
  padding-bottom: 23px;
}
.user-game .like-game-body .swiper-pagination {
  bottom: 0;
}
.user-game .like-game-body .item {
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-top: 10px;
}
.user-game .like-game-body .item:first-child {
  margin-top: 0;
}
.user-game .like-game-body .item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.user-game .like-game-body .item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.user-game .like-game-body .item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
}
.user-game .like-game-body .item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.user-game .like-game-body .item .desc {
  width: 100%;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.user-game .like-game-body .item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.user-game .like-game-body .item .tag {
  display: inline-block;
  padding-left: 5px;
}
.user-game .like-game-body .item .tag:first-child {
  padding-left: 0;
}
.user-game .like-game-body .item .tag:nth-child(1) {
  color: #ff4200;
}
.user-game .like-game-body .item .tag:nth-child(2) {
  color: #75d100;
}
.user-game .like-game-body .item .tag:nth-child(3) {
  color: #2893ff;
}
.user-game .like-game-body .item .btn {
  width: 1.1rem;
  border-radius: 8px;
  background: #ff3c3c;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.user-gift-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 40px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 5px 12.5px;
}
.user-gift-head .input {
  flex: 1;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background-color: #f8f8f8;
  border-radius: 10px;
}
.user-gift-head .input input {
  flex: 1;
  background: none;
  border: none;
  padding: 7.5px;
}
.user-gift-head .input .icon {
  padding-left: 7.5px;
}
.user-gift-head .input .close {
  padding-right: 7.5px;
}
.user-gift-body {
  min-height: calc(100vh - 40px - 50px - env(safe-area-inset-top));
  margin-top: 40px;
  padding: 12.5px;
}
.user-gift-body .item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-gift-body .item:first-child {
  border: none;
  padding-top: 0;
}
.user-gift-body .item .icon {
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.user-gift-body .item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.user-gift-body .item .text {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.user-gift-body .item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.user-gift-body .item .desc {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.user-gift-body .item .code {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-gift-body .item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  font-size: 0.28rem;
  line-height: 0.5rem;
  text-align: center;
  color: #ffc000;
  border: 1px solid #ffc000;
}
.user-bill-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
}
.user-bill-head .items {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.user-bill-head .item {
  width: 33.333%;
  position: relative;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.user-bill-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.user-bill-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.user-bill-head .item.active:after {
  display: block;
}
.user-bill-head .thead {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}
.user-bill-head .thead .th {
  width: 33.333%;
  font-size: 13px;
  text-align: center;
}
.user-bill-body {
  min-height: calc(100vh - 40px - 75px - env(safe-area-inset-top));
  margin-top: 75px;
  padding: 12.5px;
  position: relative;
}
.user-bill-body .type-item {
  display: none;
}
.user-bill-body .type-item.active {
  display: block;
}
.user-bill-body .bill-item {
  display: flex;
  border-radius: 8px;
  background: center no-repeat;
  background-size: 100% 100%;
  border-top: 1px solid #eee;
  padding: 10px 0;
}
.user-bill-body .bill-item:first-child {
  border-top: none;
  padding-top: 0;
}
.user-bill-body .bill-item .td {
  width: 33.333%;
  font-size: 13px;
  text-align: center;
}
.user-bill-body .bill-item .time {
  font-size: 12px;
}
.user-bill-body .bill-item .amount {
  color: red;
}
.user-credit-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
}
.user-credit-head .items {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.user-credit-head .item {
  width: 50%;
  position: relative;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.user-credit-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.user-credit-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.user-credit-head .item.active:after {
  display: block;
}
.user-credit-head .thead {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}
.user-credit-head .thead .th {
  width: 33.333%;
  font-size: 13px;
  text-align: center;
}
.user-credit-body {
  min-height: calc(100vh - 40px - 75px - env(safe-area-inset-top));
  margin-top: 75px;
  padding: 12.5px;
  position: relative;
}
.user-credit-body .type-item {
  display: none;
}
.user-credit-body .type-item.active {
  display: block;
}
.user-credit-body .credit-item {
  display: flex;
  border-radius: 8px;
  background: center no-repeat;
  background-size: 100% 100%;
  border-top: 1px solid #eee;
  padding: 10px 0;
}
.user-credit-body .credit-item:first-child {
  border-top: none;
  padding-top: 0;
}
.user-credit-body .credit-item .td {
  width: 33.333%;
  font-size: 13px;
  text-align: center;
}
.user-credit-body .credit-item .time {
  font-size: 12px;
}
.user-credit-body .credit-item .amount {
  color: red;
}
.user-profile {
  background-color: #f8f8f8;
  min-height: calc(100vh - 40px - env(safe-area-inset-top));
}
.user-profile .group {
  margin-top: 15px;
}
.user-profile .group:first-child {
  margin-top: 0;
}
.user-profile .item {
  background-color: #fff;
  border-top: 1px solid #eee;
  display: flex;
  padding: 15px;
  align-items: center;
  position: relative;
}
.user-profile .item:first-child {
  border-top: none;
}
.user-profile .item .name {
  color: #000;
  flex: 1;
  width: 0;
}
.user-profile .item .name span {
  color: red;
  padding-left: 5px;
}
.user-profile .item .operate {
  text-align: right;
  display: flex;
  align-items: center;
  margin-left: auto;
}
.user-profile .item .operate .icon {
  display: block;
  margin-left: 7.5px;
}
.user-profile .item .avatar {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.user-profile .item input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.user-btn {
  margin: 25px;
  border-radius: 20px;
  padding: 10px;
  background: #ffc000;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.user-input {
  border-bottom: 1px solid #eee;
  padding: 7.5px 0;
  display: flex;
  align-items: center;
}
.user-input .name {
  width: 75px;
  flex-shrink: 0;
  color: #000;
  font-size: 0.3rem;
}
.user-input input,
.user-input .value {
  flex: 1;
  border: none;
  background: none;
  padding: 7.5px;
  font-size: 16px;
}
.user-input .close {
  padding: 5px;
}
.user-input .password {
  padding: 5px;
}
.user-input .btn {
  background-color: #ffc000;
  border-radius: 10px;
  padding: 5px 7.5px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
}
.user-input .btn.disable {
  background-color: #ccc;
}
.user-nickname {
  padding: 12.5px;
}
.user-qq {
  padding: 12.5px;
}
.user-realname {
  padding: 12.5px;
}
.user-phoneremove {
  padding: 12.5px;
}
.user-phoneremove-tips {
  color: #ffc000;
  text-align: center;
  padding-bottom: 10px;
}
.user-phonereplace {
  padding: 12.5px;
}
.user-phonereplace-tips {
  color: #ffc000;
  text-align: center;
  padding-bottom: 10px;
}
.user-password {
  padding: 10px;
}
.user-password .tips {
  display: block;
  margin-top: 15px;
  text-align: center;
  color: orange;
  font-size: 15px;
}
.user-password-phone {
  padding: 10px;
}
.user-password-phone .tips {
  display: block;
  margin-top: 15px;
  text-align: center;
  color: orange;
  font-size: 15px;
}
.user-getter {
  position: fixed;
  z-index: 917;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.user-getter .ut-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 10px env(safe-area-inset-bottom) 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.user-getter .ut-head {
  text-align: right;
  color: #666;
  padding: 10px 10px 0 10px;
}
.user-getter .ut-head:before {
  content: "暂不";
}
.user-getter .ut-body {
  color: #333;
  padding: 25px 10px;
  font-size: 15px;
}
.user-getter .ut-body .free {
  color: green;
}
.user-getter .ut-body .warn {
  color: red;
}
.user-getter .ut-foot {
  padding: 10px;
}
.user-getter .ut-foot .btn {
  display: block;
  background-color: #ffc000;
  width: 50%;
  margin: auto;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
  text-align: center;
}
.user-getter .ut-foot .btn:before {
  content: "继续";
}
.user-getted {
  position: fixed;
  z-index: 917917917918;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-getted .container {
  width: 85%;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
}
.user-getted .head {
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.user-getted .body {
  padding: 10px;
  font-size: 15px;
}
.user-getted .close {
  margin-top: 2.5vh;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
}
.feedback-index .feedback-head {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.feedback-index .feedback-head-item {
  text-align: center;
  width: 50%;
  padding: 10px;
  position: relative;
  transition: all 0.25s;
  font-size: 0.28rem;
}
.feedback-index .feedback-head-item:after {
  content: "";
  display: block;
  height: 2px;
  width: 15px;
  background-color: #ffc000;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s;
  opacity: 0;
}
.feedback-index .feedback-head-item.active {
  font-weight: bold;
  color: #000;
  font-size: 15px;
}
.feedback-index .feedback-head-item.active:after {
  opacity: 1;
}
.feedback-index .feedback-body {
  background-color: #eee;
}
.feedback-index .feedback-body .swiper-container {
  height: 100%;
}
.feedback-index .feedback-body-item {
  height: 100%;
}
.feedback-index .feedback-block {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
}
.feedback-index .feedback-block:last-child {
  margin-bottom: 0;
}
.feedback-index .feedback-operate {
  background-color: #fff;
  padding: 15px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 15px);
}
.feedback-index .feedback-operate .btn {
  background-color: #ffc000;
  border-radius: 20px;
  padding: 7.5px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
}
.feedback-index .feedback-operate .btn.disable {
  background-color: #ccc;
}
.feedback-index .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.feedback-index .input-item .name {
  color: #000;
  font-size: 0.28rem;
  width: 150px;
}
.feedback-index .input-item .value {
  flex: 1;
  width: 0;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}
.feedback-index .input-item .value > input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
}
.feedback-index .input-device .value {
  text-align: right;
}
.feedback-index .input-game .value {
  text-align: right;
  color: red;
}
.feedback-index .input-type {
  display: block;
}
.feedback-index .input-type .name {
  width: auto;
}
.feedback-index .input-type .value {
  width: auto;
  margin-top: 5px;
}
.feedback-index .input-type .value label {
  float: left;
  width: 50%;
}
.feedback-index .input-type .value label input {
  margin-right: 5px;
}
.feedback-index .input-content {
  margin-top: 10px;
}
.feedback-index .input-content textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
  resize: none;
}
.account-login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
}
.account-login .body {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}
.account-login .body .logo {
  padding-bottom: 15px;
}
.account-login .body .logo img {
  display: block;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.account-login .body .input-item {
  position: relative;
  padding: 10px 0;
}
.account-login .body .input-item .label {
  font-size: 0.3rem;
  color: #000;
}
.account-login .body .input-item .input {
  margin-top: 5px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.account-login .body .input-item .input input {
  border: none;
  flex: 1;
  font-size: 16px;
  padding: 5px;
}
.account-login .body .input-item .input .assist {
  padding: 5px;
}
.account-login .body .input-item .input .select {
  margin-left: 5px;
  padding: 5px;
  position: relative;
}
.account-login .body .input-item .input .select select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.account-login .body .operate {
  width: 100%;
}
.account-login .body .operate .item {
  float: left;
}
.account-login .body .operate .item:last-child {
  float: right;
  color: #f5842d;
}
.account-login .body .btn {
  background-color: #ffc000;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.account-login .body .btn.disable {
  background-color: #ccc;
}
.account-login .body .btn-phone {
  color: #000;
  text-align: center;
  margin-top: 12.5px;
  font-size: 0.28rem;
}
.account-phonelogin {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
}
.account-phonelogin .phonelogin-body {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}
.account-phonelogin .phonelogin-body .logo {
  padding-bottom: 15px;
}
.account-phonelogin .phonelogin-body .logo img {
  display: block;
  width: 60%;
  margin: 0 auto;
}
.account-phonelogin .phonelogin-body .body {
  margin: 40px 0 25px;
}
.account-phonelogin .phonelogin-body .input {
  padding: 5px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
}
.account-phonelogin .phonelogin-body .input:before {
  font-size: 15px;
  display: block;
  color: #666;
  content: "手机号";
}
.account-phonelogin .phonelogin-body .input input {
  flex: 1;
  width: 0;
  padding: 7.5px 15px;
  border: none;
  font-size: 17px;
  color: #999;
}
.account-phonelogin .phonelogin-body .code {
  padding: 5px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.account-phonelogin .phonelogin-body .code:before {
  font-size: 15px;
  display: block;
  color: #666;
  content: "验证码";
}
.account-phonelogin .phonelogin-body .code input {
  flex: 1;
  width: 0;
  padding: 7.5px 15px;
  font-size: 17px;
  border: none;
  color: #999;
}
.account-phonelogin .phonelogin-body .code-btn {
  padding: 7.5px;
  font-size: 14px;
  color: #ffc000;
  white-space: nowrap;
}
.account-phonelogin .phonelogin-body .code-btn.disable {
  color: #ccc;
}
.account-phonelogin .phonelogin-body .tips {
  text-align: center;
  color: #999;
  margin-top: 15px;
  font-size: 14px;
}
.account-phonelogin .phonelogin-body .btn {
  background-color: #ffc000;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.account-phonelogin .phonelogin-body .btn.disable {
  background-color: #ccc;
}
.account-phonelogin .phonelogin-body .btn-phone {
  color: #ffc000;
  text-align: center;
  margin-top: 12.5px;
  font-size: 0.28rem;
}
.account-phonelogin .phonelogin-users {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.account-phonelogin .phonelogin-users .mask {
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.account-phonelogin .phonelogin-users .body {
  position: relative;
  z-index: 666;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
}
.account-phonelogin .phonelogin-users .title {
  font-size: 0.3rem;
  color: #000;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0;
}
.account-phonelogin .phonelogin-users .users {
  flex: 1;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
  max-height: 33vh;
}
.account-phonelogin .phonelogin-users .users .user {
  padding: 10px;
  text-align: center;
  border-top: 1px solid #eee;
  font-size: 0.28rem;
}
.account-phonelogin .phonelogin-password {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.account-phonelogin .phonelogin-password .body {
  width: 75%;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}
.account-phonelogin .phonelogin-password .title {
  font-size: 17px;
  text-align: center;
  color: #000;
}
.account-phonelogin .phonelogin-password input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #eee;
  margin-top: 15px;
}
.account-phonelogin .phonelogin-password .btn {
  background-color: #ffc000;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 15px;
}
.account-phonelogin .phonelogin-password .btn.disable {
  background-color: #ccc;
}
.account-register {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
}
.account-register-btn {
  color: #f5842d;
}
.account-register .body {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}
.account-register .body .logo {
  padding-bottom: 15px;
}
.account-register .body .logo img {
  display: block;
  width: 50%;
}
.account-register .body .input-item {
  position: relative;
  padding: 10px 0;
}
.account-register .body .input-item .label {
  font-size: 0.3rem;
  color: #000;
}
.account-register .body .input-item .input {
  margin-top: 5px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.account-register .body .input-item .input input {
  border: none;
  flex: 1;
  font-size: 16px;
  padding: 5px;
}
.account-register .body .input-item .input .assist {
  padding: 5px;
}
.account-register .body .input-item .input .select {
  margin-left: 5px;
  padding: 5px;
  position: relative;
}
.account-register .body .input-item .input .select select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.account-register .body .btn {
  background-color: #ffc000;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.account-register .body .btn.disable {
  background-color: #ccc;
}
.account-agreement {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.account-agreement .agreement {
  margin-right: 5px;
  border: 2px solid #ccc;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2.5px;
  position: relative;
}
.account-agreement .agreement.checked {
  border: 2px solid #ffc000;
}
.account-agreement .agreement.checked:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffc000;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.account-agreement a {
  color: #f5842d;
  text-align: center;
}
.account-phone {
  padding: 10px;
}
.account-phone .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.account-phone .input-item .label {
  width: 50px;
  margin-right: 10px;
  color: #333;
  font-size: 15px;
}
.account-phone .input-item .input {
  flex: 1;
  width: 0;
  border: none;
  display: flex;
  align-items: center;
}
.account-phone .input-item .input input {
  width: 100%;
  padding: 7.5px;
  border: none;
  font-size: 16px;
  color: #333;
}
.account-phone .input-item .input .assist {
  padding: 5px;
}
.account-phone .input-item .btn {
  margin-left: 10px;
  background-color: #ffc000;
  border-radius: 8px;
  padding: 5px 7.5px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.account-phone .input-item .btn.disable {
  background-color: #ccc;
}
.account-phone .operate {
  background-color: #ffc000;
  border-radius: 20px;
  padding: 7.5px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.account-phone .operate.disable {
  background-color: #ccc;
}
.account-forget {
  padding: 10px;
}
.account-forget .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.account-forget .input-item .label {
  width: 50px;
  margin-right: 10px;
  font-size: 15px;
  color: #333;
}
.account-forget .input-item .input {
  flex: 1;
  width: 0;
  border: 1px solid #eee;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.account-forget .input-item .input input {
  width: 100%;
  padding: 7.5px;
  border: none;
  font-size: 16px;
  color: #333;
}
.account-forget .input-item .input .assist {
  padding: 5px;
}
.account-forget .input-item .btn {
  margin-left: 10px;
  background-color: #ffc000;
  border-radius: 8px;
  padding: 5px 7.5px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.account-forget .input-item .btn.disable {
  background-color: #ccc;
}
.account-forget .operate {
  background-color: #ffc000;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.account-forget .operate.disable {
  background-color: #ccc;
}
.voucher-index {
  padding-top: 50px;
}
.voucher-index .voucher-head {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  padding: 5px 0 5px 10px;
}
.voucher-index .voucher-head .input {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 5px;
}
.voucher-index .voucher-head .input .icon {
  padding: 5px;
  font-size: 18px;
}
.voucher-index .voucher-head .input input {
  flex: 1;
  width: 0;
  overflow: hidden;
  padding: 5px;
  font-size: 15px;
  border: none;
  background: none;
}
.voucher-index .voucher-head .input .empty {
  padding: 5px;
  font-size: 18px;
}
.voucher-index .voucher-head .btn {
  padding: 7.5px 10px;
  color: #ffc000;
  font-size: 15px;
}
.voucher-index .voucher-body {
  padding: 10px;
}
.voucher-index .voucher-title {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000000;
  margin-bottom: 7.5px;
}
.voucher-index .voucher-title .more {
  font-size: 13px;
  flex: 1;
  text-align: right;
}
.voucher-index .voucher-main {
  margin-top: 10px;
}
.voucher-index .lateplay-main {
  margin-bottom: 10px;
}
.voucher-index .lateplay-main .items {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  display: flex;
  margin: 0 -5px;
}
.voucher-index .lateplay-main .items .item {
  width: 60%;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
}
.voucher-index .lateplay-main .items .item .icon {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 7.5px;
}
.voucher-index .lateplay-main .items .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.voucher-index .lateplay-main .items .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.voucher-index .lateplay-main .items .item .name {
  font-size: 15px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-index .lateplay-main .items .item .desc {
  color: #999;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-index .lateplay-main .items .item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.voucher-index .lateplay-main .items .item .voucher {
  color: #999999;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-index .lateplay-main .items .item .voucher span {
  color: #ffc000;
  font-size: 15px;
  padding-right: 5px;
}
.voucher-list .item {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.voucher-list .item:first-child {
  margin-top: 0;
}
.voucher-list .item .icon {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
}
.voucher-list .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.voucher-list .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  padding: 0 7.5px;
}
.voucher-list .item .info .name {
  font-size: 15px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-list .item .info .desc {
  color: #999;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
}
.voucher-list .item .info .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.voucher-list .item .info .tags {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
}
.voucher-list .item .info .tag {
  margin-right: 5px;
}
.voucher-list .item .info .tag:nth-child(1) {
  color: #ff5e00;
}
.voucher-list .item .info .tag:nth-child(2) {
  color: #ffa21c;
}
.voucher-list .item .info .tag:nth-child(3) {
  color: #00a7ff;
}
.voucher-list .item .operate {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  text-align: center;
}
.voucher-list .item .operate .count {
  font-size: 18px;
  color: #fe8900;
}
.voucher-list .item .operate .amount {
  font-size: 13px;
  margin-top: 2.5px;
}
.voucher-list .item .operate .btn {
  padding: 2.5px;
  background-color: #ffc000;
  color: #fff;
  border-radius: 12px;
  margin-top: 2.5px;
  font-size: 13px;
}
.voucher-lateplay {
  padding: 10px;
}
.gift-gifts-top {
  display: flex;
  align-items: center;
  margin-right: -5px;
}
.gift-gifts-top .my {
  color: #ff3c3c;
  padding: 5px;
}
.gift-gifts {
  min-height: calc(100vh - env(safe-area-inset-top) - 40px);
  background-color: #f6f7f9;
}
.gift-gifts .search {
  display: block;
  background-color: #fff;
  padding: 7.5px 10px;
}
.gift-gifts .search .container {
  padding: 5px 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: #f7f7f7;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.gift-gifts .search .container .icon {
  color: #939494;
  margin-right: 10px;
}
.gift-gifts .search .container .text {
  color: #d1d1d1;
}
.gift-items {
  padding: 0 10px 10px 10px;
}
.gift-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.gift-item .icon {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  margin-right: 10px;
}
.gift-item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.gift-item .data {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.gift-item .data .name {
  color: #3d3d3d;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gift-item .data .branch {
  color: red;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}
.gift-item .data .value {
  margin-top: 2.5px;
  color: #3d3d3d;
}
.gift-item .data .value span {
  color: #ff3c3c;
}
.gift-item .opt {
  margin-left: 5px;
  background-color: #ff3c3c;
  color: #fff;
  font-size: 13px;
  padding: 7.5px 10px;
  border-radius: 20px;
  line-height: 1.1;
}
.nav-view-gift-search {
  background: transparent;
}
.nav-view-gift-search .nav-view-head {
  border-bottom-color: transparent;
  background-color: #f6f7f9;
}
.nav-view-gift-search .nav-view-head > .container {
  padding-right: 0;
  height: 45px;
}
.nav-view-gift-search .nav-view-head .title {
  padding: 0 10px;
}
.nav-view-gift-search .nav-view-body {
  background-color: #f6f7f9;
}
.gift-search-input {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 5px);
  right: 10px;
  left: 52px;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 400;
  border-radius: 8px;
  padding: 3.5px 10px 3.5px 10px;
}
.gift-search-input .icon {
  font-size: 18px;
  color: #939494;
}
.gift-search-input input {
  margin-left: 5px;
  margin-right: 5px;
  flex: 1;
  width: 0;
  overflow: hidden;
  background-color: #fff;
  padding: 5px;
  border: none;
  font-size: 16px;
}
.gift-search-input .clear {
  margin-right: 10px;
  font-size: 15px;
}
.gift-search-input .btn {
  color: #000;
  background-color: #fcf15d;
  border-radius: 10px;
  padding: 5px 10px;
  line-height: 1.1;
  font-size: 14px;
}
.gift-search .history {
  padding: 10px;
}
.gift-search .history .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gift-search .history .title > .text {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: bold;
}
.gift-search .history .title .opt {
  padding: 5px;
  color: rgba(61, 61, 61, 0.6);
  display: flex;
  align-items: center;
}
.gift-search .history .title .opt .icon {
  margin-right: 5px;
  font-size: 16px;
}
.gift-search .history .items {
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
  max-height: 65vh;
  overflow-y: auto;
}
.gift-search .history .item {
  display: inline-block;
  line-height: 1.1;
  padding: 7.5px 12.5px;
  background-color: #fff;
  color: rgba(61, 61, 61, 0.8);
  margin-left: 10px;
  border-radius: 8px;
  margin-top: 5px;
}
.gift-search .history .item:first-child {
  margin-left: 0;
}
.gift-detail {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.gift-detail .gift-mask {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
  opacity: 0;
}
.gift-detail .gift-container {
  position: relative;
  z-index: 666;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.gift-detail.appear-active .gift-mask,
.gift-detail.appear-done .gift-mask,
.gift-detail.enter-active .gift-mask,
.gift-detail.enter-done .gift-mask {
  opacity: 1;
}
.gift-detail.appear-active .gift-container,
.gift-detail.appear-done .gift-container,
.gift-detail.enter-active .gift-container,
.gift-detail.enter-done .gift-container {
  opacity: 1;
  transform: translateY(0);
}
.gift-detail.exit-active .gift-mask {
  opacity: 0;
}
.gift-detail.exit-active .gift-container {
  opacity: 0;
  transform: translateY(100%);
}
.gift-detail.exit-done {
  display: none;
}
.gift-detail .gift-top {
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.gift-detail .gift-top .icon {
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 10px;
}
.gift-detail .gift-top .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.gift-detail .gift-top .text {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gift-detail .gift-top .name {
  display: flex;
}
.gift-detail .gift-top .name .named {
  font-weight: bold;
  font-size: 15px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gift-detail .gift-top .name .special {
  display: flex;
  align-items: stretch;
  margin-left: 10px;
  overflow: hidden;
  border-radius: 4px;
  line-height: 1.1;
}
.gift-detail .gift-top .name .special .label {
  background: linear-gradient(
    180deg,
    #525058 0%,
    #525058 0%,
    #2d2b37 100%,
    #2d2b37 100%
  );
  color: #fff;
  padding: 2.5px 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  white-space: nowrap;
}
.gift-detail .gift-top .name .special .value {
  background: linear-gradient(
    106deg,
    #fcf7df 3%,
    #fcf7df 3%,
    #f3d08c 75%,
    #fdefbe 96%,
    #fdefbe 96%
  );
  color: #d69c27;
  padding: 2.5px 5px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  white-space: nowrap;
}
.gift-detail .gift-top .time {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.gift-detail .gift-main {
  flex: 1;
  height: 0;
  margin-top: 10px;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.gift-detail .gift-desc .title {
  font-size: 0.3rem;
  color: #000;
}
.gift-detail .gift-desc .main {
  margin-top: 7.5px;
}
.gift-detail .gift-bottom {
  background-color: #ffc000;
  padding: 7.5px;
  text-align: center;
  font-size: 0.3rem;
  color: #fff;
  border-radius: 20px;
}
.gift-game {
  position: fixed;
  z-index: 111;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.25);
}
.gift-game .mask {
  position: fixed;
  z-index: 122;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
  opacity: 0;
}
.gift-game .container {
  position: relative;
  z-index: 133;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 50vh;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  padding: 12.5px 12.5px env(safe-area-inset-bottom) 12.5px;
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.gift-game.appear-active .mask,
.gift-game.appear-done .mask,
.gift-game.enter-active .mask,
.gift-game.enter-done .mask {
  opacity: 1;
}
.gift-game.appear-active .container,
.gift-game.appear-done .container,
.gift-game.enter-active .container,
.gift-game.enter-done .container {
  opacity: 1;
  transform: translateY(0);
}
.gift-game.exit-active .mask {
  opacity: 0;
}
.gift-game.exit-active .container {
  opacity: 0;
  transform: translateY(100%);
}
.gift-game.exit-done {
  display: none;
}
.gift-game .game {
  display: flex;
  align-items: center;
}
.gift-game .game .icon {
  width: 65px;
  height: 65px;
  overflow: hidden;
  border-radius: 8px;
}
.gift-game .game .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.gift-game .game .data {
  flex: 1;
  width: 0;
  padding-left: 7.5px;
  padding-right: 5px;
}
.gift-game .game .data .name {
  color: #3d3d3d;
}
.gift-game .game .data .desc {
  color: #787878;
  font-size: 13px;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
}
.gift-game .game .data .count {
  color: rgba(61, 61, 61, 0.7);
  font-size: 15px;
}
.gift-game .game .data .count span {
  color: #ff3c3c;
}
.gift-game .game .opt {
  background: #fcf15d;
  color: #3d3d3d;
  padding: 7.5px 12.5px;
  border-radius: 20px;
  line-height: 1;
}
.gift-game .nav {
  margin: 5px 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gift-game .nav .item {
  font-size: 16px;
  padding: 5px;
  margin-left: 5%;
  margin-right: 5%;
  color: rgba(61, 61, 61, 0.7);
  position: relative;
  cursor: pointer;
}
.gift-game .nav .item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.25s;
  height: 3px;
}
.gift-game .nav .item.active {
  color: #3d3d3d;
}
.gift-game .nav .item.active:after {
  background-color: #fcf15d;
}
.gift-game .groups {
  flex: 1;
  height: 0;
  overflow-y: auto;
}
.gift-game .group {
  margin-top: 12.5px;
  padding-bottom: 25px;
  display: none;
}
.gift-game .group.active {
  display: block;
}
.gift-game .item {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
}
.gift-game .item .icon {
  width: 55px;
}
.gift-game .item .icon img {
  display: block;
  width: 100%;
}
.gift-game .item .info {
  flex: 1;
  width: 0;
  padding-left: 7.5px;
  padding-right: 5px;
}
.gift-game .item .name {
  display: flex;
}
.gift-game .item .name .named {
  font-weight: bold;
  font-size: 15px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gift-game .item .name .special {
  display: flex;
  align-items: stretch;
  margin-left: 10px;
  overflow: hidden;
  border-radius: 4px;
  line-height: 1.1;
}
.gift-game .item .name .special .label {
  background: linear-gradient(
    180deg,
    #525058 0%,
    #525058 0%,
    #2d2b37 100%,
    #2d2b37 100%
  );
  color: #fff;
  padding: 2.5px 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  white-space: nowrap;
}
.gift-game .item .name .special .value {
  background: linear-gradient(
    106deg,
    #fcf7df 3%,
    #fcf7df 3%,
    #f3d08c 75%,
    #fdefbe 96%,
    #fdefbe 96%
  );
  color: #d69c27;
  padding: 2.5px 5px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  white-space: nowrap;
}
.gift-game .item .desc {
  margin-top: 2.5px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gift-game .item .desc span {
  color: red;
  margin-right: 5px;
}
.gift-game .item .opt {
  background: #fcf15d;
  color: #3d3d3d;
  padding: 7.5px 12.5px;
  border-radius: 20px;
  line-height: 1;
  text-align: center;
  margin-left: 15px;
}
.gift-game .item .opt:before {
  content: "领取";
}
.gift-game .item .opt.nothing {
  background: #ccc;
}
.gift-game .item .opt.nothing:before {
  content: "已领完";
}

.qa-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 15px env(safe-area-inset-bottom, 15px) 15px;
}
.qa-operate .btn {
  padding: 10px;
  background-color: #ffc000;
  color: #fff;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
}
.qa-form {
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.qa-form .mask {
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
  opacity: 0;
}
.qa-form .form {
  position: relative;
  z-index: 666;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.qa-form .form .head {
  display: flex;
  align-items: center;
}
.qa-form .form .head .title {
  flex: 1;
  font-size: 0.28rem;
  color: #333;
}
.qa-form .form .head .btn {
  padding: 5px 10px;
  background-color: #ffc000;
  color: #fff;
  text-align: center;
  font-size: 0.28rem;
  border-radius: 4px;
}
.qa-form .form .head .btn.disable {
  background-color: #eee;
  color: #999;
}
.qa-form .form .input {
  margin-top: 10px;
  overflow: hidden;
}
.qa-form .form .input textarea {
  resize: none;
  width: 100%;
  border-radius: 4px;
  padding: 7.5px;
  border: 1px solid #ccc;
  font-size: 0.28rem;
  margin-bottom: env(safe-area-inset-bottom);
}
.qa-form .form .input textarea:focus {
  margin-bottom: 0;
}
.qa-form.enter-active .mask,
.qa-form.enter-done .mask {
  opacity: 1;
}
.qa-form.enter-active .form,
.qa-form.enter-done .form {
  opacity: 1;
  transform: translateY(0);
}
.qa-form.exit-active .mask {
  opacity: 0;
}
.qa-form.exit-active .form {
  opacity: 0;
  transform: translateY(100%);
}
.qa-index {
  padding-bottom: calc(env(safe-area-inset-bottom) + 45px);
}
.qa-index .qa-game {
  background-color: #fff;
  position: fixed;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0 10px;
}
.qa-index .qa-game .icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.qa-index .qa-game .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.qa-index .qa-game .info {
  flex: 1;
  overflow: hidden;
}
.qa-index .qa-game .name {
  font-size: 0.28rem;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qa-index .qa-game .desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
}
.qa-index .qa-items {
  margin-top: 80px;
  padding: 10px;
}
.qa-index .qa-item {
  display: block;
  margin-top: 10px;
}
.qa-index .qa-item:first-child {
  margin-top: 0;
}
.qa-index .qa-q {
  display: flex;
  align-items: center;
}
.qa-index .qa-q .icon {
  color: cornflowerblue;
  font-weight: bold;
  margin-right: 7.5px;
}
.qa-index .qa-q .text {
  flex: 1;
  width: 0;
  overflow: hidden;
  font-size: 0.28rem;
}
.qa-index .qa-as {
  background-color: #f8f8f8;
  padding: 7.5px;
  margin-left: 20px;
  margin-top: 5px;
}
.qa-index .qa-a {
  display: flex;
}
.qa-index .qa-a .icon {
  color: #ffc000;
  font-weight: bold;
  margin-right: 7.5px;
}
.qa-index .qa-a .text {
  flex: 1;
  width: 0;
  overflow: hidden;
  font-size: 0.26rem;
}
.qa-index .qa-more {
  margin-top: 5px;
}
.qa-index .qa-more span {
  color: #ffc000;
}
.qa-detail .qa {
  padding-bottom: calc(env(safe-area-inset-bottom) + 45px);
}
.qa-detail .qa-q {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
}
.qa-detail .qa-q .avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 10px;
}
.qa-detail .qa-q .avatar img {
  display: block;
  width: 100%;
  height: 100%;
}
.qa-detail .qa-q .main {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.qa-detail .qa-q .info {
  display: flex;
  align-items: center;
}
.qa-detail .qa-q .name {
  font-size: 0.28rem;
  flex: 1;
  width: 0;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qa-detail .qa-q .desc {
  display: flex;
  align-items: center;
}
.qa-detail .qa-q .desc .icon {
  color: cornflowerblue;
  font-weight: bold;
  margin-right: 7.5px;
}
.qa-detail .qa-q .desc .text {
  flex: 1;
  width: 0;
  overflow: hidden;
  font-size: 0.28rem;
}
.qa-detail .qa-info {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  display: flex;
}
.qa-detail .qa-info .icon {
  color: #ffc000;
  font-weight: bold;
  margin-right: 7.5px;
}
.qa-detail .qa-info .text {
  flex: 1;
  width: 0;
  overflow: hidden;
  font-size: 0.26rem;
}
.qa-detail .qa-info span {
  color: #ffc000;
}
.qa-detail .qa-as {
  padding-left: 20px;
}
.qa-detail .qa-a {
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
}
.qa-detail .qa-a .avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 10px;
}
.qa-detail .qa-a .avatar img {
  display: block;
  width: 100%;
  height: 100%;
}
.qa-detail .qa-a .main {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.qa-detail .qa-a .info {
  display: flex;
  align-items: center;
}
.qa-detail .qa-a .name {
  font-size: 0.28rem;
  flex: 1;
  width: 0;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qa-detail .qa-a .desc {
  display: flex;
  align-items: center;
}
.qa-detail .qa-a .desc .icon {
  color: cornflowerblue;
  font-weight: bold;
  margin-right: 7.5px;
}
.qa-detail .qa-a .desc .text {
  flex: 1;
  width: 0;
  overflow: hidden;
  font-size: 0.28rem;
  margin-top: 5px;
}
.recovery-record {
  padding: 5px;
  color: #000;
}
.recovery-index {
  padding: 10px;
}
.recovery-index .recovery-step {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.recovery-index .recovery-step img {
  display: block;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}
.recovery-index .recovery-explain,
.recovery-index .recovery-tips {
  margin-top: 5px;
}
.recovery-index .recovery-explain .title,
.recovery-index .recovery-tips .title {
  font-weight: bold;
  font-size: 0.28rem;
  color: #000;
}
.recovery-index .recovery-explain .item,
.recovery-index .recovery-tips .item {
  position: relative;
  padding-left: 17.5px;
  margin-top: 5px;
  font-size: 13px;
}
.recovery-index .recovery-explain .item:first-child,
.recovery-index .recovery-tips .item:first-child {
  margin-top: 0;
}
.recovery-index .recovery-explain .item:before,
.recovery-index .recovery-tips .item:before {
  content: "";
  display: block;
  background-color: #fcf15d;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 5px;
}
.recovery-index .recovery-title {
  margin-top: 5px;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: bold;
}
.recovery-index .recovery-items .item {
  margin-top: 7.5px;
  display: flex;
  align-items: center;
}
.recovery-index .recovery-items .item .icon {
  width: 65px;
  height: 65px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 7.5px;
}
.recovery-index .recovery-items .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.recovery-index .recovery-items .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.recovery-index .recovery-items .item .info .name {
  color: #000;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.recovery-index .recovery-items .item .info .desc {
  margin-top: 2.5px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.recovery-index .recovery-items .item .info .desc span {
  color: red;
}
.recovery-index .recovery-items .item .price {
  padding-left: 5px;
  font-size: 12px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.recovery-index .recovery-items .item .price span {
  color: red;
  font-size: 0.28rem;
}
.recovery-record .record-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.recovery-record .record-head .item {
  flex: 1;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  color: #333;
}
.recovery-record .record-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.recovery-record .record-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.recovery-record .record-head .item.active:after {
  display: block;
}
.recovery-record .record-body {
  margin-top: 45px;
  padding: 12.5px;
  position: relative;
}
.recovery-record .record-body .type-item {
  display: none;
}
.recovery-record .record-body .type-item.active {
  display: block;
}
.recovery-record .record-body .record-item {
  margin-top: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-top: 1px solid #eee;
  position: relative;
  padding-top: 7.5px;
}
.recovery-record .record-body .record-item:first-child {
  margin-top: 0;
}
.recovery-record .record-body .record-item .icon {
  width: 65px;
  height: 65px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 7.5px;
}
.recovery-record .record-body .record-item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.recovery-record .record-body .record-item .subject {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}
.recovery-record .record-body .record-item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.recovery-record .record-body .record-item .info .name {
  color: #000;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-record .record-body .record-item .info .desc {
  margin-top: 2.5px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-record .record-body .record-item .info .desc span {
  color: red;
}
.recovery-record .record-body .record-item .operate {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 5px;
  color: #666;
  font-size: 13px;
}
.recovery-record .record-body .record-item .price {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-record .record-body .record-item .price span {
  color: red;
  font-size: 0.28rem;
}
.recovery-record .record-body .record-item .time {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-record .record-body .record-item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: auto;
  right: -15px;
  top: 7.5px;
  color: #ffffff;
  background-color: #ccc;
  transform: rotate(45deg);
  width: 65px;
  text-align: center;
  font-size: 10px;
}
.recovery-record .record-body .record-item.can:after {
  content: "待赎回";
  background-color: brown;
}
.recovery-record .record-body .record-item.ed:after {
  content: "已赎回";
}
.recovery-record .record-body .record-item.expire:after {
  content: "已过期";
}
.recovery-confirm {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.recovery-confirm .confirm-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  background: #fff center top no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: 75vh;
}
.recovery-confirm .confirm-head {
  color: #fff;
  padding: 30px 10px;
  text-align: center;
}
.recovery-confirm .confirm-head .title {
  font-weight: bold;
  font-size: 0.3rem;
}
.recovery-confirm .confirm-head .info {
  font-size: 0.26rem;
  margin-top: 5px;
}
.recovery-confirm .confirm-body {
  flex: 1;
  height: 0;
  padding: 10px;
  overflow: hidden;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.recovery-confirm .confirm-body .title {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  color: #000;
  font-weight: bold;
}
.recovery-confirm .confirm-body .title img {
  display: block;
  height: 20px;
  margin-right: 7.5px;
}
.recovery-confirm .confirm-body .content {
  flex: 1;
  margin-top: 10px;
  min-height: 30vh;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.recovery-confirm .confirm-body .content p {
  padding-left: 15px;
  position: relative;
  margin-bottom: 5px;
  font-size: 13px;
  color: #333;
}
.recovery-confirm .confirm-body .content p:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffc000;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.recovery-confirm .confirm-foot {
  display: flex;
  flex-direction: column;
}
.recovery-confirm .confirm-foot .input {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.recovery-confirm .confirm-foot .input input {
  margin-right: 10px;
}
.recovery-confirm .confirm-foot .btns {
  display: flex;
  padding: 0 7.5px;
  margin-top: 5px;
}
.recovery-confirm .confirm-foot .btns .btn {
  flex: 1;
  background-color: #ffc000;
  margin: 0 7.5px;
  border-radius: 20px;
  padding: 10px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-bottom: 7.5px;
}
.recovery-confirm .confirm-foot .btns .btn:last-child {
  background-color: #eee;
}
.recovery-confirm .confirm-foot .btns .btn:last-child.confirm {
  background-color: #ffc000;
}
.recovery-detail {
  padding-bottom: 55px;
  padding-top: calc(85px + 48px);
}
.recovery-detail .recovery-info {
  position: fixed;
  z-index: 22;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 85px;
  padding: 0 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}
.recovery-detail .recovery-info .icon {
  width: 65px;
  height: 65px;
  margin-right: 7.5px;
}
.recovery-detail .recovery-info .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.recovery-detail .recovery-info .info .name {
  color: #000;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-detail .recovery-info .info .desc {
  font-size: 0.26rem;
  color: #666;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-detail .recovery-info .info .desc span {
  color: red;
}
.recovery-detail .recovery-thead {
  position: fixed;
  z-index: 22;
  top: calc(env(safe-area-inset-top) + 48px + 85px);
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid #eee;
}
.recovery-detail .recovery-thead .th {
  flex: 1;
  text-align: center;
}
.recovery-detail .recovery-tbody .tr {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  display: flex;
  align-items: center;
}
.recovery-detail .recovery-tbody .td {
  flex: 1;
  text-align: center;
}
.recovery-detail .recovery-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  height: 55px;
  padding: 0 15px;
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.recovery-detail .recovery-operate .btn {
  background-color: #eee;
  border-radius: 20px;
  padding: 7.5px;
  font-size: 0.26rem;
  color: #fff;
  text-align: center;
}
.recovery-detail .recovery-operate .btn.active {
  background-color: #ffc000;
}
.recovery-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  color: #999;
}
.recovery-empty img {
  display: block;
  width: 50%;
}
.rebate-guide .tips {
  color: #ffc000;
  font-size: 13px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rebate-guide .tips .icon {
  margin-right: 5px;
}
.rebate-guide .list dl {
  border-bottom: 1px solid #ccc;
}
.rebate-guide .list dl dt {
  padding: 10px;
  display: flex;
  align-items: center;
}
.rebate-guide .list dl dt .text {
  flex: 1;
  width: 0;
  color: #333;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rebate-guide .list dl dt .icon {
  font-size: 0.3rem;
}
.rebate-guide .list dl .icon-down {
  display: block;
}
.rebate-guide .list dl .icon-up {
  display: none;
}
.rebate-guide .list dl dd {
  overflow: hidden;
  padding: 10px;
  color: #666;
  font-size: 0.26rem;
  border-top: 1px solid #ccc;
  display: none;
}
.rebate-guide .list dl.active dd {
  display: block;
}
.rebate-guide .list dl.active .icon-down {
  display: none;
}
.rebate-guide .list dl.active .icon-up {
  display: block;
}
.rebate-index .rebate-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.rebate-index .rebate-head .item {
  flex: 1;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  color: #333;
}
.rebate-index .rebate-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.rebate-index .rebate-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.rebate-index .rebate-head .item.active:after {
  display: block;
}
.rebate-index .rebate-body {
  margin-top: 45px;
  position: relative;
}
.rebate-index .rebate-body .type-item {
  display: none;
}
.rebate-index .rebate-body .type-item.active {
  display: block;
}
.rebate-can {
  padding: calc(12.5px + 30px) 12.5px 12.5px 12.5px;
}
.rebate-can .rebate-tips {
  position: fixed;
  z-index: 333;
  top: calc(env(safe-area-inset-top) + 48px + 45px);
  left: 0;
  right: 0;
  padding: 0 5px;
  text-align: center;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 12px;
}
.rebate-can .rebate-item {
  display: flex;
  align-items: center;
}
.rebate-can .rebate-item .icon {
  width: 65px;
  height: 65px;
  margin-right: 10px;
  border-radius: 8px;
  overflow: hidden;
}
.rebate-can .rebate-item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.rebate-can .rebate-item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.rebate-can .rebate-item .info .name {
  color: #333;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rebate-can .rebate-item .info .time {
  font-size: 13px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
}
.rebate-can .rebate-item .info .amount {
  font-size: 13px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
}
.rebate-can .rebate-item .info span {
  color: red;
}
.rebate-can .rebate-item .operate {
  padding: 4px 8px;
  background-color: #ffc000;
  color: #fff;
  border-radius: 12px;
}
.rebate-submit {
  min-height: 100vh;
  background-color: #f8f8f8;
}
.rebate-submit .input-group {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 5px;
}
.rebate-submit .input-item {
  display: flex;
  align-items: center;
  padding: 5px;
}
.rebate-submit .input-item .icon {
  margin-right: 7.5px;
}
.rebate-submit .input-item .icon img {
  display: block;
  height: 25px;
}
.rebate-submit .input-item .name {
  font-size: 0.28rem;
  color: #333;
}
.rebate-submit .input-item .value {
  flex: 1;
  text-align: right;
  font-size: 0.26rem;
  color: #666;
}
.rebate-submit .input-item .value span {
  color: red;
}
.rebate-submit .rebate-content textarea {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: none;
  line-height: 1.5;
  resize: none;
}
.rebate-submit .rebate-operate {
  flex: 1;
  background-color: #ffc000;
  border-radius: 20px;
  padding: 7.5px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin: 10px;
}
.rebate-submit .rebate-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 555;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rebate-submit .rebate-confirm .container {
  background-color: #ffffff;
  width: 75%;
  border-radius: 8px;
  overflow: hidden;
}
.rebate-submit .rebate-confirm .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12.5px;
}
.rebate-submit .rebate-confirm .icon img {
  display: block;
  height: 75px;
}
.rebate-submit .rebate-confirm .title {
  font-size: 0.32rem;
  color: #000;
}
.rebate-submit .rebate-confirm .info {
  font-size: 0.26rem;
  color: #666;
  padding: 15px 0;
}
.rebate-submit .rebate-confirm .info p {
  margin: 5px auto;
}
.rebate-submit .rebate-confirm .btns {
  border-top: 1px solid #eee;
  display: flex;
}
.rebate-submit .rebate-confirm .btns .btn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.rebate-submit .rebate-confirm .btns .btn:first-child {
  border-right: 1px solid #eee;
}
.rebate-submit .rebate-confirm .btns .btn:last-child {
  color: #ffc000;
}
.rebate-success {
  padding: 10px;
}
.rebate-success .icon {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.rebate-success .icon img {
  display: block;
  height: 100px;
}
.rebate-success .title {
  font-size: 0.3rem;
  color: #000;
  display: flex;
  justify-content: center;
}
.rebate-success .info {
  border-bottom: 1px solid #eee;
}
.rebate-success .info dl {
  padding: 7.5px 0;
  display: flex;
  align-items: center;
}
.rebate-success .info dl dt {
  color: #999;
}
.rebate-success .info dl dd {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  text-align: right;
}
.rebate-success .info dl dd span {
  color: #ffc000;
}
.rebate-success .tips {
  margin-top: 12.5px;
}
.rebate-success .tips .title {
  font-size: 0.3rem;
  color: #333;
}
.rebate-success .tips .content {
  margin-top: 10px;
  font-size: 0.26rem;
  color: #666;
}
.rebate-success .tips .content p {
  margin: 2.5px auto;
}
.rebate-success .operate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.rebate-success .operate .btn {
  flex: 1;
  border: 1px solid #ffc000;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.5px;
}
.rebate-success .operate .btn:first-child {
  background-color: #fff;
  color: #ffc000;
}
.rebate-success .operate .btn:last-child {
  background-color: #ffc000;
  color: #fff;
}
.rebate-detail {
  min-height: 100vh;
  background: #f8f8f8 center top no-repeat;
  background-size: contain;
  padding: 25% 7.5% 0 7.5%;
}
.rebate-detail .title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.3rem;
  color: #000;
}
.rebate-detail .title img {
  height: 3px;
  margin: 0 5px;
}
.rebate-detail .info {
  padding-top: 10%;
  font-size: 13px;
}
.rebate-detail .info dl {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.rebate-detail .info dl dt {
  width: 75px;
}
.rebate-detail .info dl dd {
  flex: 1;
  width: 0;
  color: #333;
  font-size: 0.28rem;
  overflow: hidden;
}
.rebate-detail .data {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.rebate-detail .data .time {
  color: #333;
}
.rebate-detail .data .amount {
  flex: 1;
  text-align: right;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rebate-detail .data .amount span {
  color: #ffc000;
  font-size: 0.32rem;
}
.rebate-detail .remind {
  margin-top: 10px;
}
.rebate-detail .remind p {
  position: relative;
  padding-left: 20px;
  font-size: 13px;
  color: #666;
}
.rebate-detail .remind p:before {
  content: "!";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #ffc000;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
}
.rebate-detail .operate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.rebate-detail .operate .btn {
  flex: 1;
  border: 1px solid #ffc000;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.5px;
}
.rebate-detail .operate .btn:first-child {
  background-color: #fff;
  color: #ffc000;
}
.rebate-detail .operate .btn:last-child {
  background-color: #ffc000;
  color: #fff;
}
.rebate-detail .tips {
  font-size: 12px;
  text-align: center;
  color: #999;
  margin-top: 10px;
}
.rebate-record {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding: 10px;
}
.rebate-record .item {
  display: block;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 10px;
}
.rebate-record .item:first-child {
  margin-top: 0;
}
.rebate-record .item .main {
  display: flex;
  align-items: center;
  padding-bottom: 7.5px;
  border-bottom: 1px solid #eee;
}
.rebate-record .item .main .icon {
  width: 65px;
  height: 65px;
  margin-right: 10px;
  border-radius: 8px;
  overflow: hidden;
}
.rebate-record .item .main .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.rebate-record .item .main .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.rebate-record .item .main .info .name {
  color: #333;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rebate-record .item .main .info .time {
  font-size: 13px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
}
.rebate-record .item .main .info .amount {
  font-size: 13px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2.5px;
}
.rebate-record .item .main .info span {
  color: red;
}
.rebate-record .item .operate {
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.rebate-record .item .operate .state {
  flex: 1;
  width: 0;
  overflow: hidden;
  color: #666;
}
.rebate-record .item .operate .state span {
  color: red;
}
.rebate-record .item .operate .btn {
  background-color: #ffc000;
  color: #fff;
  padding: 3.5px 7.5px;
  border-radius: 12px;
}
.rebate-record .item .remark {
  margin-top: 5px;
  color: red;
}
.ptb-index {
  padding: 0.2rem;
}
.ptb-index .ptb-amount {
  background-color: #ffc000;
  background-image: linear-gradient(to right, #f99935, #f7b26f);
  border-radius: 0.2rem;
  padding: 0.3rem;
  color: #fff;
}
.ptb-index .ptb-amount .name {
  font-size: 0.3rem;
}
.ptb-index .ptb-amount .value {
  font-size: 0.5rem;
}
.ptb-index .amount-title {
  font-size: 0.3rem;
  color: #000;
  margin-top: 0.2rem;
}
.ptb-index .amount-title span {
  font-size: 0.28rem;
  color: #999;
}
.ptb-index .amount-item {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ptb-index .amount-item .item {
  width: 30%;
  margin: 0.15rem 0;
  border: 1px solid #ccc;
  padding: 0.1rem;
  color: #ffc000;
  border-radius: 4px;
  text-align: center;
  font-size: 0.4rem;
  transition: all 0.25s;
}
.ptb-index .amount-item .item.active {
  border: 1px solid #ffc000;
}
.ptb-index .amount-input {
  margin-top: 0.2rem;
  color: #ffc000;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
}
.ptb-index .amount-input input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.15rem;
  font-size: 0.3rem;
}
.ptb-index .amount-result {
  font-size: 0.28rem;
  color: #999;
  margin-top: 0.2rem;
}
.ptb-index .amount-result span {
  color: #ffc000;
}
.ptb-index .pay-title {
  margin-top: 0.2rem;
  font-size: 0.29rem;
  color: #999;
}
.ptb-index .pay-type {
  overflow: hidden;
  display: flex;
  align-items: stretch;
  margin: 0.2rem -5px 0 -5px;
}
.ptb-index .pay-type .item {
  flex: 1;
  margin: 0 5px;
  border: 1px solid #ccc;
  color: #ffc000;
  padding: 0.15rem 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.25s;
}
.ptb-index .pay-type .item.active {
  border: 1px solid #ffc000;
}
.ptb-index .pay-type .alipay {
  color: blue;
  font-size: 0.35rem;
  margin-right: 0.3rem;
}
.ptb-index .pay-type .wxpay {
  color: green;
  font-size: 0.37rem;
  margin-right: 0.3rem;
}

.ptb-index .pay-bottom {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  padding: 0.2rem;
}

.ptb-index .pay-bottom .pay-btn {
  width: 80%;
  margin: 0.3rem auto 0;
  padding: 0.16rem;
  border-radius: 8px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #ffc000;
}

.ptb-index .pay-bottom .intro {
  margin-top: 10px;
  text-align: center;
}

.ptb-record {
  padding: 0.2rem;
}

.ptb-record .item {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  padding: 0.2rem;
}
.ptb-record .item .time {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ptb-record .item .amount {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ptb-record .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ptb-record .empty img {
  display: block;
  width: 50%;
}
.message-messages .message-head {
  position: fixed;
  z-index: 917;
  left: 0;
  right: 0;
}
.message-messages .message-head .width {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.message-messages .message-head .item {
  font-size: 17px;
  padding: 5px;
  margin-left: 5%;
  margin-right: 5%;
  color: rgba(61, 61, 61, 0.7);
  position: relative;
  cursor: pointer;
}
.message-messages .message-head .item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.25s;
  height: 3px;
}
.message-messages .message-head .item.active {
  color: #3d3d3d;
}
.message-messages .message-head .item.active:after {
  background-color: #fcf15d;
}
.message-messages .message-body {
  padding-top: 40px;
}
.message-group {
  display: none;
}
.message-group.active {
  display: block;
}
.message-items {
  padding: 10px;
}
.message-items .item {
  display: block;
  margin-top: 10px;
  color: #333;
}
.message-items .item:first-child {
  margin-top: 0;
}
.message-items .item.readed {
  color: #999;
}
.message-items .item .info {
  display: flex;
  align-items: center;
}
.message-items .item .info .title {
  flex: 1;
  width: 0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-items .item .info .time {
  font-size: 13px;
}
.message-items .item .content {
  margin-top: 5px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notice-items {
  padding: 10px;
}
.notice-items .item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.notice-items .item.active:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #ff3c3c;
  margin-right: 10px;
  border-radius: 50%;
}
.notice-items .item .data {
  flex: 1;
  width: 0;
}
.notice-items .item:first-child {
  margin-top: 0;
}
.notice-items .item .time {
  text-align: center;
  color: #999999;
}
.notice-items .item .img {
  margin-top: 5px;
  border-radius: 8px;
  overflow: hidden;
  padding-top: 50%;
  position: relative;
}
.notice-items .item .img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.notice-items .item .title {
  margin-top: 5px;
  color: #3d3d3d;
}
.notice-items .item .desc {
  margin-top: 5px;
  color: rgba(61, 61, 61, 0.7);
}
.message-message {
  padding: 10px;
}
.message-message .title {
  font-size: 16px;
  color: #000;
  text-align: center;
}
.message-message .time {
  margin-top: 5px;
  color: #999999;
  text-align: center;
}
.message-message .content {
  color: rgba(61, 61, 61, 0.7);
  margin-top: 10px;
}

.credit-gift {
  padding: 10px;
}
.credit-gift .item {
  display: flex;
  align-items: center;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #ccc;
}
.credit-gift .item:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}
.credit-gift .item .icon {
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 12px;
}
.credit-gift .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.credit-gift .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}
.credit-gift .item .text {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7.5px;
}
.credit-gift .item .name {
  color: #333;
}
.credit-gift .item .game,
.credit-gift .item .code,
.credit-gift .item .time {
  font-size: 12px;
  color: #666;
  margin-top: 2.5px;
}
.credit-gift .item .btn {
  background-color: #fcf15d;
  padding: 7.5px 10px;
  line-height: 1.25;
  border-radius: 20px;
  margin-bottom: 10px;
}

.credit-game .item {
  background-color: #fff;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.credit-game .item:first-child {
  margin-top: auto;
}
.credit-game .item .icon {
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 12px;
}
.credit-game .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.credit-game .item .info {
  flex: 1;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}
.credit-game .item .info .name {
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-game .item .info .branch {
  color: red;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-game .item .info .game,
.credit-game .item .info .count,
.credit-game .item .info .amount {
  font-size: 12px;
  margin-top: 2.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-game .item .info .amount span {
  color: red;
}
.credit-game .item .btn {
  background-color: #fcf15d;
  padding: 7.5px 10px;
  line-height: 1.25;
  border-radius: 20px;
  margin-bottom: 10px;
}
.credit-game .item .btn.disable {
  background-color: #ccc;
}
.credit-game .detail {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.credit-game .detail .mask {
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.credit-game .detail .container {
  background-color: #fff;
  width: 90%;
  padding: 12.5px;
  border-radius: 8px;
  position: relative;
  z-index: 777;
}
.credit-game .detail .game {
  display: flex;
  align-items: stretch;
  margin-bottom: 25px;
}
.credit-game .detail .game .icon {
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 12px;
}
.credit-game .detail .game .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.credit-game .detail .game .info {
  flex: 1;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.credit-game .detail .game .info .title {
  color: #000;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-game .detail .game .info .name {
  color: #666;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-game .detail .game .info .count {
  font-size: 13px;
  color: #3d3d3d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-game .detail .game .more {
  color: #000;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.credit-game .detail .title {
  color: #000;
  font-size: 16px;
}
.credit-game .detail .content {
  padding: 10px 0;
  font-size: 14px;
  color: #666;
}
.credit-game .detail .btn {
  background-color: #fcf15d;
  padding: 10px 12.5px;
  line-height: 1.25;
  margin: 25px auto 10px auto;
  text-align: center;
  width: 75%;
  border-radius: 40px;
}
.credit-game .detail .btn.disable {
  background-color: #ccc;
  color: #fff;
}
.credit-time .item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
}
.credit-time .item:first-child {
  margin-top: 0;
}
.credit-time .item .voucher {
  background: center no-repeat;
  background-size: 100% 100%;
  width: 225px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.credit-time .item .voucher .info {
  color: #fff;
}
.credit-time .item .voucher .info .type {
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-time .item .voucher .info .desc {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
}
.credit-time .item .voucher .info .time {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-time .item .voucher .price {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.credit-time .item .voucher .price .amount {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-time .item .voucher .price .meet {
  color: red;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-time .item .data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.credit-time .item .data .price {
  font-size: 15px;
  color: #000;
}
.credit-time .item .data .count {
  margin-top: 5px;
  color: #666;
  font-size: 12px;
}
.credit-time .item .data .btn {
  background-color: #ffc000;
  color: #fff;
  padding: 3.5px 10px;
  border-radius: 8px;
  margin-top: 5px;
}
.explain-detail {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.explain-detail .explain-mask {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
  opacity: 0;
}
.explain-detail .explain-container {
  position: relative;
  z-index: 666;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 23vh;
  display: flex;
  flex-direction: column;
  padding: 15px 15px env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.explain-detail.appear-active .explain-mask,
.explain-detail.appear-done .explain-mask,
.explain-detail.enter-active .explain-mask,
.explain-detail.enter-done .explain-mask {
  opacity: 1;
}
.explain-detail.appear-active .explain-container,
.explain-detail.appear-done .explain-container,
.explain-detail.enter-active .explain-container,
.explain-detail.enter-done .explain-container {
  opacity: 1;
  transform: translateY(0);
}
.explain-detail.exit-active .explain-mask {
  opacity: 0;
}
.explain-detail.exit-active .explain-container {
  opacity: 0;
  transform: translateY(100%);
}
.explain-detail.exit-done {
  display: none;
}
.explain-detail .explain-top {
  display: flex;
  padding: 10px 0 10px;
}
.explain-detail .explain-top .text {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.explain-detail .explain-top .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.32rem;
  color: #ffc000;
  margin-bottom: 0.05rem;
  text-align: center;
  letter-spacing: 1px;
}
.explain-detail .explain-top .time {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.explain-detail .explain-main {
  flex: 1;
  height: 0;
  margin-top: 10px;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
  letter-spacing: 1px;
}
.explain-detail .explain-desc .title {
  font-size: 0.3rem;
  color: #000;
}
.explain-detail .explain-desc .main {
  margin-top: 7.5px;
}
.explain-detail .explain-bottom {
  background-color: #ffc000;
  margin: 0 auto;
  padding: 10px;
  width: 280px;
  text-align: center;
  font-size: 0.3rem;
  color: #fff;
  border-radius: 20px;
}
.var-safe-area .index .index-head {
  padding-top: var(--safe-area-inset-top);
}
.var-safe-area .index-good .index-banners .swiper-container {
  margin-top: calc(var(--safe-area-inset-top) + 45px);
}
.var-safe-area .index .index-new .new-head {
  top: calc(var(--safe-area-inset-top) + 48px);
}
.var-safe-area .game-index .game-title {
  padding-top: var(--safe-area-inset-top);
}
.var-safe-area .game-index .game-menu {
  top: calc(var(--safe-area-inset-top) + 45px);
}
.var-safe-area .game-index .game-main {
  padding-top: calc(var(--safe-area-inset-top) + 45px + 50px);
}
.var-safe-area .nav-view-head {
  padding-top: var(--safe-area-inset-top);
}
.var-safe-area .nav-view-body {
  padding-top: calc(var(--safe-area-inset-top) + 48px);
}
.var-safe-area .voucher-index .voucher-head {
  position: fixed;
  top: calc(var(--safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  padding: 5px 0 5px 10px;
}
.view-end {
  color: #666;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-end:before,
.view-end:after {
  width: 10%;
  margin: 0 5%;
  content: "";
  display: block;
  height: 1px;
  background-color: #ccc;
}
.view-img {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 15px;
  background-color: #202737;
}
.view-img .swiper-container {
  height: 100%;
}
.view-img .swiper-pagination-fraction,
.view-img .swiper-pagination-custom,
.view-img .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 5px;
}
.view-img .swiper-pagination-bullet {
  background-color: #fff;
}
.view-img .img {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.view-img .img img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.view-iframe {
  width: 100%;
  height: calc(100vh - 40px - env(safe-area-inset-top));
  position: relative;
  border-top: 1px solid transparent;
  transition: all 0.25s;
  /* overflow: hidden; */
}
/* @media screen and (max-width: 375px) {
  .view-iframe {
    height: calc(100vh - 40px - env(safe-area-inset-top));
  }
} */
.view-iframe .progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background-color: transparent;
  transition: all 0.25s;
}
.view-iframe .progress.loading {
  background-color: #ffc000;
}
.view-iframe .progress.loaded {
  background-color: transparent;
}
.view-iframe iframe {
  width: 100%;
  height: 100%;
}
.root .video-react .video-react-big-play-button {
  opacity: 0;
  display: none;
}
.video-react.video-react-fullscreen .video-react-control-bar {
  bottom: env(safe-area-inset-bottom);
}
.view-video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
.view-video .video-react .video-react-control-bar {
  bottom: 0;
}
.view-video .video-react.video-react-fullscreen .video-react-control-bar {
  bottom: env(safe-area-inset-bottom);
}
.am-modal-transparent {
  width: auto;
  min-width: 75%;
  max-width: 85%;
}
.am-modal-alert-content {
  text-align: left;
}
.ant-image {
  width: 100%;
  height: 100%;
}
.welfare-index {
  width: 100%;
  height: calc(100vh - 40px - env(safe-area-inset-top));
  position: relative;
  border-top: 1px solid transparent;
  transition: all 0.25s;
}
.welfare-index iframe {
  width: 100%;
  height: 100%;
}
.error-boundary {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 80%;
  max-width: 90%;
  max-height: 75%;
  display: flex;
  flex-direction: column;
  color: red;
}
.error-boundary .title {
  font-size: 16px;
  margin-bottom: 12.5px;
}
.error-boundary .desc {
  flex: 1;
  height: 0;
  overflow: auto;
  font-size: 15px;
  cursor: pointer;
}
.error-boundary .desc span {
  text-decoration: underline;
  margin-left: 5px;
}
.error-boundary .info {
  flex: 1;
  height: 0;
  overflow: auto;
}
.error-boundary .option {
  display: flex;
  margin-top: 15px;
}
.error-boundary .option .btn {
  flex: 1;
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  line-height: 1.5;
}
.error-boundary .option .btn:first-child {
  color: blue;
  margin-right: 5px;
}
.error-boundary .option .btn:last-child {
  color: green;
  margin-left: 5px;
}
body > iframe:last-child {
  display: none;
}
.status-bar-background {
  background-image: linear-gradient(
    rgba(204, 204, 204, 0.95),
    rgba(204, 204, 204, 0)
  );
  height: env(safe-area-inset-top);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.guide-guide {
  position: fixed;
  z-index: 917917917917917;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.guide-guide .option {
  width: 65vw;
  margin-top: 5vh;
}
.guide-guide .option .btn img {
  display: block;
  width: auto;
  height: 37.5px;
}
.guide-guide .option .btn:first-child {
  float: left;
}
.guide-guide .option .btn:last-child {
  float: right;
}
.guide-guide .guide1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.guide-guide .guide1 img {
  display: block;
  width: 80%;
}
.guide-guide .guide2 {
  position: relative;
}
.guide-guide .guide2 .option {
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  transform: translateX(-50%);
}
.guide-guide .guide3 {
  padding-top: calc(22.5% + env(safe-area-inset-top));
  padding-left: 15%;
}
.guide-guide .guide3 img {
  display: block;
  width: 85%;
}
.guide-guide .guide3 .go {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 25vh;
}
.guide-guide .guide3 .go img {
  display: block;
  width: auto;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}
.guide-guide .swiper-pagination-bullet {
  border: 2px solid #999999;
}
.guide-guide .swiper-pagination-bullet-active {
  border: 3px solid #fcf15d;
  background-color: #fcf15d;
}
/*# sourceMappingURL=css.css.map */
