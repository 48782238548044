.index-index {
  position: relative;
  overflow: hidden;
  background-color: #f6f7f9;
  min-height: calc(100vh - 65px - env(safe-area-inset-bottom));
}
.index .index-head {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  padding: calc(env(safe-area-inset-top) + 10px) 12px 11px 12px;
  background-color: #f6f7f9;
  transition: all 0.25s;
}
.index .index-head.active {
  background-color: #ccc;
}
.index .index-head .search {
  /* height: 30px; */
  /* margin-left: 10px; */
  display: flex;
  align-items: stretch;
}
.index .index-head .search .search-img {
  width: 27px;
  /* max-width: 32px; */
  height: 27px;
  max-width: fit-content;
}
.index .index-head .search .searched {
  flex: 1;
  width: 0;
  background-color: #fff;
  display: flex;
  padding: 5px 10px;
  line-height: 1.15;
  align-items: center;
  border-radius: 493px;
  margin-right: 10px;
}
.index .index-head .search .searched .icon {
  font-size: 28px;
  margin-right: 10px;
}
.index .index-head .search .searched .text {
  line-height: 1.1;
  color: #d1d1d1;
}
.index .index-head .search .opt {
  display: flex;
  align-items: center;
}
.index .index-head .search .opt .item {
  position: relative;
  /* padding: 2.5px; */
}
.index .index-head .search .opt .item.active:before {
  content: "";
  display: block;
  position: absolute;
  /* top: 5px; */
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background: linear-gradient(143deg, #ff7474 11%, #fa5151 86%);
  border-radius: 50%;
}
.index .index-head .search .opt .icon {
  font-size: 18px;
}
.index .index-head .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  /* height: auto; */
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
}
.index .index-head .nav::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.index .index-head .nav .nav-left {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
}
.index .index-head .nav .nav-left::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.index .index-head .nav .item {
  height: 27px;
  line-height: 27px;
  margin-left: 23px;
  flex-shrink: 0;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  transition: all 0.25s;
}
.index .index-head .nav .item:first-child {
  margin-left: 0;
}
.index .index-head .search .opt .item {
  margin-right: 0;
}
.index .index-head .nav .item-img {
  padding: 0;
  background: none;
  border-radius: unset;
  display: flex;
  align-items: center;
}
.index .index-head .nav .item-img img {
  height: 27px;
}
.index .index-head .nav .item.active {
  font-weight: 700;
  font-size: 18px;
  color: #000;
}
.index .index-body {
  padding-top: calc(env(safe-area-inset-top) + 53px);
  position: relative;
}
.index .index-body-item {
  overflow: hidden;
  display: none;
}
.index .index-body-item.active {
  display: block;
}
.index .index-advertising {
  width: 100%;
  padding-bottom: 9px;
  padding-bottom: calc(env(safe-area-inset-bottom));
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  z-index: 100;
  opacity: 0.8;
}
.adver-main {
  position: relative;
}
.index .index-advertising .adver-img {
  display: block;
  height: fit-content;
}
.index .index-advertising .adver-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.index .index-good .index-banner {
  margin: 0 12px;
  border-radius: 8px;
  overflow: hidden;
  /* padding-top: 54%; */
  padding-top: 450px;
  position: relative;
}
.index-good .index-banner .swiper-pagination {
  padding-right: 12px;
  text-align: right;
}
.index-good .index-banner .swiper-pagination-bullet {
  width: 7px;
  height: 5px;
  margin: 0 5px;
  background: #dbdfe2;
}
.index-good .index-banner .swiper-pagination-bullet-active {
  background-color: #52b8bd;
  width: 12px;
  border-radius: 110px;
}
.index .index-good .index-banner .swiper-container {
  width: 100%;
  height: 450px;
  position: absolute;
  border-radius: 15px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/* .index .index-good .index-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.index-banner-slide {
  position: relative;
}

.index-banner-slide .index-banner-img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.index-banner-slide .index-banner-font {
  width: 100%;
  padding: 8px 16px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.95) 10%,
    rgba(0, 0, 0, 0.7) 70%,
    rgba(0, 0, 0, 0.5),
    rgba(240, 235, 235, 0.12156862745098039)
  );
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
}

.index-banner-slide .index-banner-font .index-banner-font-flex {
  display: flex;
  align-items: center;
}

.index-banner-slide
  .index-banner-font
  .index-banner-font-flex
  .index-banner-font-name {
  font-weight: 700;
  font-size: 0.32rem;
}

.index-banner-slide
  .index-banner-font
  .index-banner-font-flex
  .index-banner-font-topup {
  margin-left: 8px;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 0.2rem;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.7);
}

.index-banner-slide .index-banner-font .index-banner-font-label {
  /* margin-top: 5px; */
  font-size: 0.24rem;
  color: rgba(255, 255, 255, 0.7);
}

.index-banner-slide .index-banner-font .index-banner-font-label .label {
  margin-right: 5px;
}

.index-banner-slide .index-banner-font .index-banner-font-label .line {
  width: 1px;
  height: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.7);
}

.index-banner-slide .index-banner-font .index-banner-font-welfare {
  /* margin-top: 9px; */
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
}

.index-banner-slide .index-banner-font .index-banner-font-welfare .welfare {
  margin-right: 4px;
  padding: 2px 9px;
  background: #3b3635;
  border-radius: 3px;
  font-size: 0.2rem;
  color: #eaa877;
}
.index-banner-slide
  .index-banner-font
  .index-banner-font-welfare
  .welfare:nth-child(1) {
  color: #eaa877;
}
.index-banner-slide
  .index-banner-font
  .index-banner-font-welfare
  .welfare:nth-child(2) {
  color: #8ba37c;
}
.index-banner-slide
  .index-banner-font
  .index-banner-font-welfare
  .welfare:nth-child(3) {
  color: #7d9bc2;
}
.index .index-new {
  margin-top: -10px;
  position: relative;
  background: #f5f6f8;
}
.index .index-new .new-banner {
  margin: 10px 12px 5px;
  border-radius: 8px;
  overflow: hidden;
  padding-top: 54%;
  position: relative;
}
.index .index-new .new-banner .swiper-container {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.index .index-new .new-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.index .index-new .new-head {
  margin: 17px 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f7f9;
}
.index .index-new .new-head .item {
  font-size: 16px;
  padding: 5px 0 4px;
  /* margin-left: 5%;
  margin-right: 5%; */
  color: rgba(61, 61, 61, 0.7);
  position: relative;
  cursor: pointer;
  font-weight: 500;
}
.index .index-new .new-head .item:first-child {
  margin-right: 105px;
}
.index .index-new .new-head .item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.25s;
  height: 4px;
  border-radius: 249px;
}
.index .index-new .new-head .item.active {
  color: #3d3d3d;
  font-weight: 500;
}
.index .index-new .new-head .item.active:after {
  background-color: #fcf15d;
}
.index .index-new .new-body {
  position: relative;
}
.index .index-new .new-body-item {
  display: none;
  padding: 0 10px 26px;
  margin-top: 24px;
}
.index .index-new .new-body-item.active {
  display: block;
}
.index .index-new .new-new {
  padding: 16px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.index .index-new .new-new dl {
  margin-bottom: 0.28rem;
}
.index .index-new .new-new dl:last-child {
  margin-bottom: 0;
}
.index .index-new .new-new dl dt {
  margin-bottom: 12px;
  font-size: 0.36rem;
  color: #000;
  font-weight: bold;
  color: #333333;
}
.index .index-new .new-new-item {
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
}
.index .index-new .new-new-item:last-child {
  margin-bottom: 0;
}
.index .index-new .new-new-item .data {
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-top: 6px;
}
.index .index-new .new-new-item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.index .index-new .new-new-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
}
.index .index-new .new-new-item .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-radius: 0px 15px 0px 10px;
}
.index .index-new .new-new-item .icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: deeppink;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  max-width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  padding: 1px 2.5px;
}
.index .index-new .new-new-item .info {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index .index-new .new-new-item .info .name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.32rem;
  font-weight: 700;
  color: #333;
}
.index .index-new .new-new-item .info .name .name-left {
  margin-right: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}
.index .index-new .new-new-item .info .name .versions {
  margin-left: 8px;
  padding: 2px 5px 1px;
  border-radius: 2px;
  background: #f3f5f9;

  font-size: 0.2rem;
  font-weight: 700;
  color: #86878b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.index .index-new .new-new-item .info .desc {
  display: flex;
  align-items: center;

  font-size: 0.23rem;
  color: #666;
}
.index .index-new .new-new-item .info .tags {
  display: flex;
  align-items: center;

  font-size: 0.23rem;
  color: #666;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.index .index-new .new-new-item .info .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.index .index-new .new-new-item .info .tag {
  margin-top: 5px;
  margin-left: 10px;
  padding: 2px 4px 1px;
  border-radius: 4px;
  color: #666;
  font-size: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #e99157;
  border: 0.5px solid #e99157;
}
.index .index-new .new-new-item .info .tag:nth-child(1) {
  margin-left: 0;
  color: #e99157;
  border-color: #e99157;
}
.index .index-new .new-new-item .info .tag:nth-child(2) {
  color: #85bc69;
  border-color: #85bc69;
}
.index .index-new .new-new-item .info .tag:nth-child(3) {
  color: #859dd1;
  border-color: #859dd1;
}
.index .index-new .new-new-item .info .subscribe-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  border-radius: 4px;
  font-size: 13px;
}
.index .index-new .new-new-item .info .subscribe-time .time-time {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 18px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 60, 60, 0.6);
  font-size: 0.2rem;
  font-weight: 500;
  color: #fff;
  background-color: #ff3c3c;
}
.index .index-new .new-new-item .info .subscribe-time .reserves {
  font-weight: 500;
  font-size: 0.2rem;
  color: #999999;
}
.index .index-new .new-new-item .info .subscribe-time .reserves span {
  font-size: 0.24rem;
  color: #ff3c3c;
}
.index .index-new .new-new-item .opt {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.index .index-new .new-new-item .opt .time {
  width: 38px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ff3c3c;
  font-size: 0.24rem;
  color: #ffffff;
}

.index .index-new .new-new-item .media {
  display: block;
  /* padding-top: 50%; */
  position: relative;
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
}
.index .index-new .new-new-item .media .container {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.index .index-new .new-new-item .media img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.index .index-new .new-new-item .media .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 32px;
}
.index .index-new .new-week {
  padding: 10px 10px 0 10px;
}
.index .index-new .new-week-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.2rem;
  border-radius: 8px;
  position: relative;
}
.index .index-new .new-week-item .index {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}
.index .index-new .new-week-item .icon {
  margin-right: 0.2rem;
  width: 1.25rem;
  height: 1.25rem;
}
.index .index-new .new-week-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.index .index-new .new-week-item .subject {
  flex: 1;
  width: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.index .index-new .new-week-item .text {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.index .index-new .new-week-item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.index .index-new .new-week-item .info1 {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.065rem;
  margin-top: 0.065rem;
}
.index .index-new .new-week-item .info2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ff5e00;
  font-size: 0.24rem;
}
.index .index-new .new-week-item .tag {
  display: inline-block;
  margin-right: 5px;
}
.index .index-new .new-week-item .tag:nth-child(1) {
  color: #ff4200;
}
.index .index-new .new-week-item .tag:nth-child(2) {
  color: #75d100;
}
.index .index-new .new-week-item .tag:nth-child(3) {
  color: #2893ff;
}
.index .index-new .new-week-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.index .index-new .new-reserve {
  padding: 10px;
}
.index .index-new .new-reserve-item {
  margin-bottom: 10px;
  overflow: hidden;
  background: #fff;
  padding: 0.2rem;
  border-radius: 8px;
}
.index .index-new .new-reserve-item .subject {
  display: flex;
  align-items: center;
}
.index .index-new .new-reserve-item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.index .index-new .new-reserve-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.index .index-new .new-reserve-item .text {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.index .index-new .new-reserve-item .name {
  margin-bottom: 0.05rem;
  display: flex;
  align-items: center;
}
.index .index-new .new-reserve-item .name .named {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-size: 0.3rem;
  color: #282828;
}
.index .index-new .new-reserve-item .name .date {
  margin: 0 5px;
  padding: 0 3.5px;
  color: #fff;
  background-color: deeppink;
  border-radius: 4px;
  font-size: 0.24rem;
  font-weight: normal;
  white-space: nowrap;
}
.index .index-new .new-reserve-item .info1 {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.065rem;
  margin-top: 0.065rem;
  display: flex;
  align-items: center;
  font-size: 0.26rem;
}
.index .index-new .new-reserve-item .reserve {
  margin: 0 5px;
  color: red;
  font-weight: bold;
}
.index .index-new .new-reserve-item .info2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ff5e00;
  font-size: 0.24rem;
}
.index .index-new .new-reserve-item .tag {
  display: inline-block;
  margin-right: 5px;
}
.index .index-new .new-reserve-item .tag:nth-child(1) {
  color: #ff4200;
}
.index .index-new .new-reserve-item .tag:nth-child(2) {
  color: #75d100;
}
.index .index-new .new-reserve-item .tag:nth-child(3) {
  color: #2893ff;
}
.index .index-new .new-reserve-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.index .index-new .new-reserve-item .medias {
  margin-top: 0.15rem;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  display: flex;
  margin-left: -0.2rem;
}
.index .index-new .new-reserve-item .media {
  flex-shrink: 0;
  width: 25%;
  padding-left: 0.2rem;
  position: relative;
}
.index .index-new .new-reserve-item .media:last-child {
  margin-right: 0;
}
.index .index-new .new-reserve-item .media .container {
  position: relative;
}
.index .index-new .new-reserve-item .media img {
  width: 100%;
}
.index .index-new .new-reserve-item .media .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 32px;
}
.index .index-video {
  position: fixed;
  z-index: 999;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  bottom: calc(env(safe-area-inset-bottom) + 65px);
}
.index .index-video .video-operate {
  position: fixed;
  z-index: 917917;
  top: calc(env(safe-area-inset-top) + 48px + 10px);
  right: calc(env(safe-area-inset-right) + 10px);
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  max-width: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.25s;
}
.index .index-video .video-operate.active {
  max-width: 222px;
  max-height: 222px;
  opacity: 1;
}
.index .index-video .video-operate .item {
  padding: 5px;
  margin: 5px auto;
}
.index .index-video .video-body {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.index .index-video .video-item {
  display: flex;
  flex-direction: column;
}
.index .index-video .video-item .body {
  flex: 1;
  height: 0;
  overflow: hidden;
  position: relative;
}
.index .index-video .video-item video {
  width: 100%;
  height: 100%;
}
.index .index-video .video-item .play {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  font-size: 64px;
  color: #fff;
  background: center no-repeat;
  background-size: contain;
}
.index .index-video .video-item .operate {
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.index .index-video .video-item .operate .item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
}
.index .index-video .video-item .operate .item .icon {
  font-size: 24px;
  margin-bottom: 5px;
}
.index .index-video .video-item .operate .item .icon.active {
  color: red;
}
.index .index-video .video-item .game {
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
}
.index .index-video .video-item .game > .icon {
  width: 65px;
  height: 65px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 10px;
}
.index .index-video .video-item .game > .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.index .index-video .video-item .game .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.index .index-video .video-item .game .info .name {
  font-size: 15px;
  color: #fff;
}
.index .index-video .video-item .game .info .desc {
  margin-top: 2.5px;
  font-size: 13px;
}
.index .index-video .video-item .game .info .tags {
  display: flex;
  align-items: center;
}
.index .index-video .video-item .game .info .tag {
  font-size: 12px;
  margin-right: 5px;
}
.index .index-video .video-item .game .info .tag:nth-child(1) {
  color: #ff4200;
}
.index .index-video .video-item .game .info .tag:nth-child(2) {
  color: #75d100;
}
.index .index-video .video-item .game .info .tag:nth-child(3) {
  color: #2893ff;
}
.index .index-video .video-item .game .btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.index .index-video .video-item .game .btn .icon {
  width: 35px;
  height: 35px;
  background-color: #ffc000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  margin-bottom: 2.5px;
}

.index-float {
  position: fixed;
  z-index: 444;
  bottom: calc(env(safe-area-inset-bottom) + 65px);
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  transition: all 0.25s;
  display: none;
}
.index-float .item {
  padding: 5px;
  display: flex;
  justify-content: center;
}
.index-float .item img {
  display: block;
  width: 55px;
  height: 55px;
}
.index-float .item img.btwan {
  animation: index-float-size 1.5s 0s infinite;
}
@keyframes index-float-size {
  0% {
    transform: scale(1);
  }
  85% {
    transform: scale(1);
  }
  95% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.index-float .item.first img {
  width: 50px;
  height: 50px;
}
.index-good {
  background: #f5f6f8;
}
.index-good .index-banner {
  overflow: hidden;
  position: relative;
  margin: 0 10px;
  padding-top: 50%;
}
.index-good .index-banner .swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.index-good .index-banner .swiper-slide {
  width: 100%;
  height: 100%;
}
.index-good .index-banner .banner {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  background: center no-repeat;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
}
.index-good .index-banner .banner .game {
  position: absolute;
  z-index: 22;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), #000000);
  display: flex;
  align-items: center;
  padding: 10px;
}
.index-good .index-banner .banner .game .icon {
  width: 65px;
  height: 65px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 10px;
}
.index-good .index-banner .banner .game .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.index-good .index-banner .banner .game .text {
  flex: 1;
  width: 0;
  overflow: hidden;
  color: #fff;
}
.index-good .index-banner .banner .game .text .name {
  font-size: 15px;
}
.index-good .index-banner .banner .game .text .info1 {
  font-size: 13px;
}
.index-good .index-banner .banner .game .text .info2 {
  color: #2893ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.index-good .index-banner .banner .game .text .info2 .tag {
  margin-right: 5px;
  display: inline;
}
.index-good .index-banner .banner .game .text .info2 .tag:nth-child(1) {
  color: #ff4200;
}
.index-good .index-banner .banner .game .text .info2 .tag:nth-child(2) {
  color: #75d100;
}
.index-good .index-banner .banner .game .text .info2 .tag:nth-child(3) {
  color: #2893ff;
}
.index-good .index-msg {
  margin: 15px 12px 10px 12px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 5px 7.5px;
}
.index-good .index-msg .icon {
  font-size: 16px;
}
.index-good .index-msg .data {
  flex: 1;
  width: 0;
  padding-left: 10px;
  padding-right: 5px;
  height: 25px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 25px;
}
.index-good .index-menus {
  overflow: hidden;
  position: relative;
}
.index-good .index-menus .container {
  margin: auto 12px;
  padding: 16px 0 13px 0;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  display: flex;
}
.index-good .index-menus .container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.index-good .index-menus .container .scrollbar {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 2px;
  width: 24px;
  transform: translateX(-50%);
  background-color: #eee;
  overflow: hidden;
}
.index-good .index-menus .container .scrollbar .thumb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #ffc000;
  transform: translateX(-50%);
}
.index-good .index-menus .menu {
  width: 20%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-good .index-menus .menu .icon {
  /* height: 37.35px; */
  width: 0.96rem;
  height: 0.96rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-good .index-menus .menu .icon img {
  display: block;
  width: 0.96rem;
  height: 0.96rem;
  margin: 0 auto;
}
.index-good .index-menus .menu.badge .icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  transform: translate(50%, 0);
  background-color: red;
  border-radius: 50%;
}
.index-good .index-menus .menu .name {
  /* font-size: 12px; */
  font-size: 0.24rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: #333;
  margin-top: 10px;
  font-weight: 700;
}
.index-good .index-activity_top {
  display: block;
  margin: 16px 12px 0;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding: 10%;
}
.index-good .index-activity_top img {
  display: block;
  width: 100%;
  height: 100%;
}
.index-good .index-activity_top .swiper-container {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.index-good .index-activity_top .swiper-container .swiper-pagination {
  bottom: 5px;
}
.index-good .index-activity_top .swiper-container .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
}
.index-good .index-tips {
  color: #ccc;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  padding: 20px 12px 32px;
}
.index-good .index-tips .text {
  text-align: center;

  font-size: 10px;
  color: #999999;
}
.index-good .index-tips:before {
  content: "";
  width: 33px;
  height: 1px;
  background-color: #ccc;
}
.index-good .index-tips:after {
  content: "";
  width: 33px;
  height: 1px;
  background-color: #ccc;
}
.index-good .index-datas {
  margin-top: -5px;
}
.index-good .index-data {
  margin: 16px 12px;
  display: block;
  overflow: hidden;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.index-good .data-head {
  display: flex;
  align-items: center;
}
.index-good .data-head .text {
  /* font-size: 18px; */
  font-size: 0.36rem;
  font-weight: bold;
  color: #333;

  flex: 1;
}
.index-good .data-head .more {
  color: #999;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.index-good .data-head .more .arrows {
  display: inline-block;
  width: 5.05px;
  height: 5.05px;
  margin-left: 2px;
  border-top: 0.5px solid #999;
  border-right: 0.5px solid #999;
  transform: rotate(45deg);
}
.index-good .recommendGames .items {
  display: grid;
  justify-content: space-between;
  /* 四列 每列的宽度*/
  grid-template-columns: 1.4rem 1.4rem 1.4rem 1.4rem;
  /* 列与列间的距离 */
  grid-column-gap: 8px;

  /* justify-content: space-between; */
}
.index-good .recommendGames .item {
  /* width: 25%; */
  width: 1.4rem;
  margin-top: 16px;
  text-align: center;
  position: relative;
}
.index-good .recommendGames .item .icon {
  width: 1.4rem;
  height: 1.4rem;
  /* margin: 0 auto; */
}
.index-all-discount {
  padding: 0 9px 0 5px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 15px 0px 10px;
  background: #ff3c3c;
  /*  */
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
}
.index-good .recommendGames .item .icon img {
  display: block;
  width: 100%;
  border-radius: 15px;
}
.index-good .recommendGames .item .name {
  margin-top: 10px;
  font-size: 0.28rem;
  overflow: hidden;
  white-space: nowrap;
  color: #333;
  font-weight: 700;
}
.index-good .recommendGames .item .info {
  margin-top: 5px;
  font-size: 0.23rem;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999;
  text-align: center;
}
.index-good .recommendGames .item .branch {
  margin-top: 2.5px;
  font-size: 12px;
  color: #66554d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid rgba(102, 85, 77, 0.4);
  border-radius: 8px;
  font-weight: bold;
}
.index-good .columnGames .columnGames-bigImg img {
  width: 100%;
  height: auto;
  /* max-height: 163px; */
  margin-top: 16px;
  display: block;
  object-fit: cover;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.index-good .columnGames .item {
  width: 100%;
  padding-left: 0;
  margin-top: 16px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: relative;
}
.index-good .columnGames a:last-child {
  display: block;
  padding-bottom: 16px;
}
.index-column-discount {
  width: 45px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 20px;
  border-radius: 242px 0px 0px 242px;
  background: #ff3c3c;
  text-align: center;
  font-size: 0.24rem;
  font-weight: 500;
  color: #ffffff;
}
.index-good .columnGames .item .icon {
  width: 70px;
  height: 70px;
  margin-right: 8px;
  position: relative;
}
.index-good .columnGames .item .icon img {
  width: 70px;
  height: 70px;
  max-width: fit-content;
  border-radius: 15px;
}
.index-good .columnGames .item .game-info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.index-good .columnGames .item .name {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  font-weight: bold;
  color: #333;
}
.index-good .columnGames .item .name .versions {
  margin-left: 8px;
  padding: 2px 5px 1px;
  border-radius: 2px;
  background: #f3f5f9;

  font-size: 0.2rem;
  font-weight: bold;
  color: #86878b;
}
.index-good .columnGames .item .info {
  /* margin-top: 5px; */
  display: flex;
  align-items: center;

  font-size: 0.23rem;
  color: #666666;
}
.index-good .columnGames .item .info .circle {
  margin: 0 5px;
  width: 2px;
  height: 2px;
  background-color: #666;
}
.index-good .columnGames .item .info .desc {
  margin-top: 5px;
  margin-left: 10px;
  padding: 2px 4px 1px;
  border-radius: 4px;
  color: #666;
  font-size: 0.2rem;
  border: 0.5px solid rgba(153, 153, 153, 0.6);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #e99157;
  border-color: #e99157;
}
.index-good .columnGames .item .info .desc:first-child {
  margin-left: 0;
  color: #e99157;
  border-color: #e99157;
}
.index-good .columnGames .item .info .desc:nth-child(2) {
  color: #85bc69;
  border-color: #85bc69;
}
.index-good .columnGames .item .info .desc:nth-child(3) {
  color: #859dd1;
  border-color: #859dd1;
}
.index-good .index-new-game-promotion {
  /* height: 330px; */
  margin: 16px 12px;
}
.index-good .index-new-game-promotion .index-new-game-promotion-top {
  position: relative;
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-top
  .new-game-welfare {
  width: 100%;
  padding: 8px 0 4px 12px;
  position: absolute;
  bottom: -1px;

  font-size: 0.32rem;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.index-good .index-new-game-promotion .index-new-game-promotion-img {
  width: 100%;
  height: 222px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.cut-off-rule-img {
  width: 100%;
  height: auto;
  display: block;
}
.index-good .index-new-game-promotion .index-new-game-promotion-font {
  padding: 0 12px 12px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.index-good .index-new-game-promotion .index-new-game-promotion-font .item {
  width: 100%;
  padding-top: 9px;
  padding-left: 0;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .icon {
  width: 70px;
  height: 70px;
  margin-right: 8px;
  border-radius: 15px;
  position: relative;
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .icon
  img {
  width: 70px;
  height: 70px;
  max-width: fit-content;
  border-radius: 15px;
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .name {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  font-weight: bold;
  color: #ffffff;
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .name
  .game-name {
  /* flex: 1; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .name
  .versions {
  margin-left: 8px;
  padding: 2px 5px 1px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.15);

  font-size: 0.2rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .info {
  /* margin-top: 5px; */
  display: flex;
  align-items: center;

  font-size: 0.2rem;
  color: rgba(255, 255, 255, 0.8);
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .info
  .circle {
  margin: 0 5px;
  width: 2px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.8);
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .info
  .desc {
  margin-left: 9px;
  margin-top: 3px;
  padding: 2px 4px 1px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.2rem;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.index-good
  .index-new-game-promotion
  .index-new-game-promotion-font
  .item
  .info
  .desc:first-child {
  margin-left: 0;
}
.index-good .index-data .projectGames-head,
.index-good .index-data .columnGames-head {
  padding: 16px 16px 0;
}
.index-good .projectGames .data-body {
  margin-top: 10px;
}
.index-good .projectGames img {
  display: block;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.index-good .hotCategory .index-data {
  background: none;
  padding-left: 5px;
  padding-right: 5px;
}
.index-good .hotCategory .items {
  margin-left: -10px;
}
.index-good .hotCategory .item {
  padding-left: 10px;
  padding-top: 12.5px;
  float: left;
  width: 25%;
}
.index-good .hotCategory .item .container {
  background-color: #fff;
  color: #282828;
  font-size: 0.28rem;
  border-radius: 25px;
  text-align: center;
  padding: 7.5px 0;
}
.index-good .index-data .selective-classification {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
}
.index-good .index-data .selective-classification .item {
  width: 23%;
  height: 26px;
  padding: 0;
  margin-top: 16px;
  line-height: 26px;
  background: #f6f7f9;
  border-radius: 167px;
  text-align: center;
}
.index-good .index-data .selective-classification .item:not(:nth-child(4n)) {
  margin-right: calc(8% / 3);
}
.index-good .index-data .selective-classification .item .container {
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
}
.index-index.invasion .index-body {
  margin-top: 0;
}
.theme-dark .index-head .background {
  background-color: #000;
}

.tips-third-main {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.tips-third-main .tips-third-top {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.tips-third-main .tips-third-top .icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 7.5px;
}

.tips-third-main .tips-third-top .icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.tips-third-main .tips-third-top .info {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
}

.tips-third-main .tips-third-top .info .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
}

.tips-third-main .tips-third-top .info .url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  color: #666;
  margin-top: 5px;
}

.tips-third-main .tips-font {
  margin-top: 5px;
  font-size: 0.2rem;
  text-align: center;
}

.desktop-icon {
  position: absolute;
  top: 1.2rem;
  left: 0.72rem;
  color: #fff;
  font-size: 0.24rem;
  animation: shrink 2s ease-out; /* 调用动画 */
}

.desktop-icon .desktop-icon-img img {
  width: 1rem;
  display: block;
  margin: 0 auto;
  border-radius: 11px;
}

.desktop-icon .name {
  width: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes shrink {
  0% {
    transform: scale(1.5); /* 从放大两倍开始 */
  }

  100% {
    transform: scale(1); /* 在中间位置恢复到正常大小 */
  }
}

.link-swiper-tips {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  padding: 30px 27px 25px;
  background-color: #fff;
}

.link-swiper-tips .link-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
  font-size: 0.32rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}

.link-swiper-tips .link-tips .link-tips-img {
  width: 18px;
  height: 18px;
  display: inline-block;
}

.link-swiper-tips .link-copy {
  margin-top: 8px;
  padding: 10px;
  text-align: center;
  border-radius: 9px;
  background: #f34902;
  font-size: 0.32rem;
  font-weight: 500;
  color: #f6f7fd;
}
