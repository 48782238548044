.dialog-common-content .dialog-mask {
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog-common-content .dialog-main {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 70vh;
  padding: 0 16px calc(env(safe-area-inset-bottom));
  overflow-y: scroll;
  background-color: #fff;
  z-index: 400;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: all 0.25s;
}

.dialog-common-content .dialog-main .dialog-top {
  padding: 26px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.dialog-common-content .dialog-main .dialog-top img {
  margin-right: 30px;
}

.dialog-common-content .dialog-content {
  padding-bottom: 20px;
}

.dialog-common-content .dialog-content .cancel-btn {
  width: fit-content;
  margin: 12px auto 0;
  font-size: 0.28rem;
  font-weight: 500;
  color: #666666;
}
