.flb-not-game {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  font-size: 0.24rem;
  font-weight: normal;
  color: rgba(61, 61, 61, 0.7);
  text-align: center;
}

.flb-not-content .server-mask {
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.flb-not-content .server-main {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80vh;
  padding: 0 16px calc(env(safe-area-inset-bottom));
  overflow-y: scroll;
  background-color: #fff;
  z-index: 400;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: all 0.25s;
}

.flb-not-content.flb-not-voucher .server-mask {
  z-index: 10000002;
}

.flb-not-content.flb-not-voucher .server-main {
  z-index: 10000003;
}

.flb-not-content .server-main .gift-top {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  padding-top: 20px;
  padding-bottom: 12px;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.flb-not-content .server-main .gift-top .down-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flb-not-content .server-main .gift-top .down-title .title {
  flex: 1;
  margin-right: -46px;
  text-align: center;
}

.flb-not-content .server-main .gift-top .close {
  margin-right: 30px;
  width: 16px;
  height: 16px;
}

.flb-not-content .server-main .game-server {
  padding-top: 60px;
  padding-bottom: 20px;
}

.flb-not-content .game-server .tips {
  color: #999;
  text-align: center;
}

.flb-not-content .game-server .container {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 3.34rem 3.34rem;
  /* 列与列间的距离 */
  grid-column-gap: 9px;
  margin-top: 16px;
}
.flb-not-content .game-server .item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 9px 12px 8px;
  font-size: 13px;
  background-color: #f5f6f8;
  border-radius: 5px;
}

.flb-not-content .game-server .item .item-left {
  flex: 1 1;
  /* margin-right: 16px; */
  overflow: hidden;
}

.flb-not-content .game-server .item .time {
  margin-bottom: 4px;
  text-align: left;
  font-size: 0.28rem;
  font-weight: bold;
  color: #333333;
}
.flb-not-content .game-server .item .name {
  flex: 1;
  text-align: left;
  font-size: 0.24rem;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flb-not-content .game-server .item .operate {
  width: 1.02rem;
  height: 0.42rem;
  line-height: 0.42rem;
  border-radius: 207px;
  color: #3d3d3d;
  background-color: #fcf15d;
  text-align: center;
  font-size: 0.24rem;
}
.flb-not-content .game-server .item .operate.disable {
  background-color: #ccc;
  color: #fff;
}

.not-game-input {
  margin-top: 16px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.not-game-input .flb-search-input {
  height: 36px;
  flex: 1 1;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 518px;
  padding: 0 16px;
}
.not-game-input .flb-search-input .icon {
  width: 17px;
  height: 17px;
}
.not-game-input .flb-search-input input {
  margin-left: 0;
  margin-right: 0;
  flex: 1;
  width: 0;
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 5px;
  border: none;
  font-size: 0.24rem;
  font-weight: 500;
  color: #3d3d3d;
}
.not-game-input .flb-search-input .clear {
  margin-right: 10px;
  font-size: 15px;
}
.not-game-input .btn {
  margin-left: 16px;
  font-size: 0.32rem;
  font-weight: 500;
  color: #3d3d3d;
}

.credit-index .credit-head {
  position: fixed;
  z-index: 111;
  left: 0;
  right: 0;
}
.credit-index .credit-head .width {
  position: relative;
  padding: 24px 16px 25px;
  background: #fff;
}
.credit-index .credit-head .summary {
  border-radius: 8px;
  position: relative;
  padding: 20px 0 22px;
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  background: linear-gradient(
    282deg,
    rgba(255, 231, 214, 0.52) -9%,
    #ffefe3 40%,
    rgba(255, 201, 176, 0.3) 88%,
    #ffded3 115%
  );
}
.credit-index .credit-head .summary .item {
  text-align: center;
}

.credit-index .credit-head .summary .item .icon img {
  display: block;
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}
.credit-index .credit-head .summary .item .value {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: #ff3c3c;
}
.credit-index .credit-head .summary .item .name {
  margin-top: 4px;
  text-align: center;
  color: #3d3d3d;
}
.credit-index .credit-head .tips {
  margin-top: 8px;
  padding: 7px 11px;
  background-color: #fff3f3;
  color: #ff3c3c;
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
}
.credit-index .credit-head .tips .text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.credit-index .credit-head .tips .icon {
  margin-left: 5px;
}
.credit-index .credit-head .type {
  margin-top: 26px;
  padding: 0 4px;
  background-color: #fff;
  position: relative;
  z-index: 33;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.credit-index .credit-head .type .item {
  padding-bottom: 4px;
  font-size: 0.28rem;
  font-weight: 500;
  transition: all 0.25s;
  color: #999999;
  position: relative;
  text-align: center;
}
.credit-index .credit-head .type .item:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 30px;
  height: 3px;
  margin: 0 auto;
  background: #fcf15d;
  transition: all 0.25s;
  opacity: 0;
}
.credit-index .credit-head .type .item.active {
  color: #3d3d3d;
}
.credit-index .credit-head .type .item.active:before {
  opacity: 1;
}
.credit-index .credit-body {
  padding: 0 13px;
}
.credit-index .credit-body .type-item {
  display: none;
}
.credit-index .credit-body .type-item.active {
  display: block;
}

.credit-ptb .item {
  display: flex;
  align-items: center;
  padding: 13px 5px;
  border-bottom: 0.5px solid rgba(216, 216, 216, 0.5);
}
.credit-ptb .item:first-child {
  padding-top: 0;
}
.credit-ptb .item:last-child {
  border-bottom: none;
}

.credit-ptb .item .icon {
  width: 32px;
  height: 32px;
  margin-right: 20px;
}
.credit-ptb .item .icon img {
  display: block;
  height: 100%;
  margin: auto;
}
.credit-ptb .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.25;
}
.credit-ptb .item .ptb {
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
}
.credit-ptb .item .credit {
  margin-top: 4px;
  font-size: 0.24rem;
  color: #3d3d3d;
}
.credit-ptb .item .credit span {
  color: #ff3c3c;
}
.credit-ptb .item .btn {
  height: 26px;
  line-height: 26px;
  padding: 0 19px;
  background-color: #fcf15d;
  border-radius: 253px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #3d3d3d;
}

.not-game-main {
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  grid-column-gap: 6.666%;
}

.not-game-main .item {
  margin-bottom: 19px;
}

.not-game-main .item .icon {
  width: 1.4rem;
  height: 1.4rem;
  margin: 0 auto;
  position: relative;
}

.not-game-main .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.not-game-main .item .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 15px;
}

.not-game-main .item .name {
  margin-top: 5px;
  font-size: 0.26rem;
  font-weight: 500;
  color: #3d3d3d;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.not-game-main .item .branch {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  margin: 2px auto 0;
  padding: 0 5px;
  border-radius: 2px;
  background-color: #f3f5f9;
  font-size: 0.2rem;
  color: #86878b;
  text-align: center;
}

.not-game-main .item .branch p {
  overflow: hidden;
  white-space: nowrap;
}

.no-data {
  font-size: 0.24rem;
  color: rgba(61, 61, 61, 0.7);
  text-align: center;
}
