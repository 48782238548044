.classify-banner .banners .swiper-container {
  padding-bottom: 36px;
}
.classify-banner .banners .swiper-pagination-bullet {
  width: 7px;
  height: 5px;
}
.classify-banner .banners .swiper-pagination-bullet-active {
  background-color: #ffc000;
  width: 12px;
  border-radius: 110px;
}
.classify-banner .banners .banner {
  display: block;
  /* box-shadow: 0 0 5px #ccc; */
  background-color: #fff;
  border-radius: 10px;
}
.classify-banner .banners .banner .img {
  padding-top: 56.22641509%;
  border-radius: 13px;
  position: relative;
  overflow: hidden;
}
.classify-banner .banners .banner .img img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.classify-banner .banners .banner .entity {
  padding: 12px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.classify-banner .banners .banner .icon {
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 6px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
.classify-banner .banners .banner .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.classify-banner .banners .banner .data {
  flex: 1;
  width: 0;
}
.classify-banner .banners .banner .name {
  /* margin-bottom: 4px; */
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
}
.classify-banner .banners .banner .name .branch {
  padding: 2px 5px 1px;
  margin-left: 6px;
  font-weight: bold;
  border-radius: 2px;
  background-color: #f3f5f9;
  font-size: 0.2rem;
  color: #86878b;
}
.classify-banner .banners .banner .desc {
  /* margin-bottom: 0.05rem; */
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: #666666;
}
.classify-banner .banners .banner .desc .line {
  width: 1px;
  height: 10px;
  margin: 0 5px;
  background-color: #666;
}

.classify-banner .banners .banner .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.classify-banner .banners .banner .tag {
  display: inline;
  padding-left: 10px;

  font-size: 0.22rem;
}
.classify-banner .banners .banner .tag:first-child {
  padding-left: 0;
}
.classify-banner .banners .banner .tag:nth-child(1) {
  color: #e99157;
}
.classify-banner .banners .banner .tag:nth-child(2) {
  color: #85bc69;
}
.classify-banner .banners .banner .tag:nth-child(3) {
  color: #859dd1;
}

.game-list-global-type {
  padding: 10px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  background: #fff !important;
  border-radius: 8px;
  position: relative;
}
.game-list-global-type .discount {
  width: 45px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 0;
  line-height: 20px;
  border-radius: 242px 0px 0px 242px;
  background: #ff3c3c;
  text-align: center;
  font-size: 0.24rem;
  font-weight: 500;
  color: #ffffff;
}
.game-list-global-type .ranking-img {
  position: absolute;
  top: -4px;
  left: -3px;
  width: 31px;
  height: 30px;
  z-index: 99;
}
.game-list-global-type .icon {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.game-list-global-type .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.game-list-global-type .icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: deeppink;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  max-width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  padding: 1px 2.5px;
}
.game-list-global-type .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
}
.game-list-global-type .name {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 0.05rem;

  font-size: 0.28rem;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
}
.game-list-global-type .name .branch {
  display: inline-block;
  margin-left: 6px;
  padding: 2px 5px 1px;
  border-radius: 2px;
  background-color: #f3f5f9;

  font-size: 0.2rem;
  color: #86878b;
  white-space: nowrap;
  font-weight: bold;
}
.game-list-global-type .desc {
  display: flex;
  align-items: center;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;

  font-size: 0.22rem;
}
.game-list-global-type .desc .line {
  width: 1px;
  height: 10px;
  margin: 0 5px;
  background-color: #666;
}
.game-list-global-type .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.game-list-global-type .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-list-global-type .tag {
  display: inline;
  margin-right: 10px;
  color: #85bc69;
}
.game-list-global-type .tag:first-child {
  margin-left: 0;
}
.game-list-global-type .tag:nth-child(1) {
  color: #e99157;
}
.game-list-global-type .tag:nth-child(2) {
  color: #85bc69;
}
.game-list-global-type .tag:nth-child(3) {
  color: #859dd1;
}

.game-detail {
  min-height: calc(100vh - env(safe-area-inset-top) - 48px);
  position: relative;
  padding-bottom: 25px;
  background-color: #f5f5f5;
}
.game-detail .game-body {
  position: relative;
}

.game-detail .game-info-content {
  background-color: #fff;
  padding: 17px 12px 12px;
}
.game-detail .game-info {
  display: flex;
  align-items: center;
}
.game-detail .game-info .icon {
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 10px;
  position: relative;
}
.game-detail .game-info .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.game-detail .game-info .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 15px;
}

.game-detail .game-info .text {
  flex: 1;
  width: 0;
  margin-left: 7.5px;
  margin-right: 7.5px;
}
.game-detail .game-info .name {
  display: flex;
  align-items: center;
  /* margin-bottom: 0.1rem; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.32rem;
  font-weight: bold;
  color: #333333;
}

.game-detail .game-info .name .branch {
  margin-left: 8px;
  padding: 2px 5px 1px;
  border-radius: 2px;
  background: #f3f5f9;
  font-size: 0.2rem;
  font-weight: bold;
  line-height: normal;
  color: #86878b;
}

.game-detail .game-info .desc {
  /* margin-bottom: 0.1rem; */
  margin-top: 9px;
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.game-detail .game-info .desc .line {
  width: 1px;
  height: 10px;
  margin: 0 5px;
  background-color: #666;
}

.game-detail .game-info .info {
  flex: 1 1;
  padding-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.game-detail .game-info .tags {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-detail .game-info .tags .tag {
  display: inline;
  padding: 0 1px 0 3px;
  margin-right: 10px;
  font-size: 0.2rem;
  color: #e99157;
  border-radius: 4px;
  box-sizing: border-box;
  border: 0.5px solid #e99157;
}
.game-detail .game-info .tags .tag:nth-child(1) {
  color: #e99157;
  border: 0.5px solid #e99157;
}
.game-detail .game-info .tags .tag:nth-child(2) {
  color: #85bc69;
  border: 0.5px solid #85bc69;
}
.game-detail .game-info .tags .tag:nth-child(3) {
  margin-right: 0;
  color: #859dd1;
  border: 0.5px solid #859dd1;
}

.game-detail .game-tag {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.game-detail .game-tag .tag-every {
  padding: 3px 5px;
  margin-right: 8px;
  border-radius: 2px;
  background-color: #f6f6f6;
  font-size: 0.2rem;
  font-weight: 500;
  color: #999999;
}

.game-detail .game-tag .tag-every:last-child {
  margin-right: 0;
}

.game-detail .game-tab {
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game-detail .game-tab .line {
  width: 1px;
  height: 23px;
  background-color: #d8d8d8;
}

.game-detail .game-tab .tab-every {
  width: fit-content;
  max-width: 30%;
  text-align: center;
}

.game-detail .game-tab .tab-every .every-top {
  font-size: 0.32rem;
  font-weight: bold;
  color: #333333;
}

.game-detail .game-tab .tab-every .every-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 0.24rem;
  color: #666666;
}

.game-detail .game-tab .tab-every .every-bottom .bottom-info {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.game-detail .game-648 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 8px;
  margin-top: 16px;
  border-radius: 10px;
  background-color: #f7f8fa;
}

.game-detail .game-648 .left {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  font-weight: bold;
  color: #3d3d3d;
}

.game-detail .game-648 .left img {
  display: block;
  width: 43px;
  margin-right: 8px;
}

.game-detail .game-648 .btn {
  width: 62px;
  height: 26px;
  border-radius: 253px;
  background: rgba(243, 174, 0, 0.2);
  text-align: center;
  line-height: 26px;
  font-size: 0.24rem;
  font-weight: 500;
  color: #f3ae00;
}

.game-detail .game-nav {
  padding-top: 6px;
  padding-left: 22px;
  padding-right: 42px;
  background-color: #fff;
}
.game-detail .game-nav.fixed {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  padding-top: calc(env(safe-area-inset-top) + 10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #202737;
}
.game-detail .game-nav-item {
  /* margin-right: 35px; */
  padding-bottom: 20px;
  position: relative;
  transition: all 0.25s;
  text-align: center;
  /* font-size: 0.28rem; */
  font-size: 16px;
  color: #ffffff;
}
.game-detail .game-nav-item:nth-child(2) {
  margin-left: -30px;
}
/* .game-detail .leftIcon {
  margin-right: 38px;
} */
.game-detail .game-nav-item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 14px;
  height: 2px;
  background-color: #fec106;
  opacity: 0;
  transition: all 0.25s;
}
.game-detail .game-nav-item.active {
  /* font-size: 0.36rem; */
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.game-detail .game-nav-item.active:after {
  opacity: 1;
}
.game-detail .game-nav-item .text {
  display: flex;
  align-items: center;
  position: relative;
}
.game-detail .game-nav-item .text .count {
  margin-left: 4px;
  font-size: 0.18rem;
  font-weight: 500;
  color: #ffffff;
}
.game-detail .game-main {
  padding-top: calc(env(safe-area-inset-top) + 53px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
}
.game-detail .game-main-item {
  min-height: 222px;
  padding: 12.5px;
  height: 0;
  overflow: hidden;
}
.game-detail .game-main-item.active {
  height: auto;
}
.game-detail .game-main-index {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.game-detail .game-main-server {
  background-color: #fff;
}
.game-detail .game-index {
  height: auto;
  display: block;
}
.game-detail .game-explain {
  overflow: hidden;
}
.game-detail .game-explain .item {
  overflow: hidden;
  background-color: #fff;
  margin-top: 8px;
  padding: 12px 12px 8px;
}

.game-detail .game-explain .itemTwo,
.game-detail .game-explain .itemThree {
  padding-bottom: 10px;
}

.game-detail .game-explain .item .head {
  color: #ffc000;
  display: flex;
  align-items: center;
}
.game-detail .game-explain .item .head .subject {
  flex: 1;
  display: flex;
  align-items: center;
}
.game-detail .game-explain .item .head .title {
  margin-right: 4px;
  font-size: 0.32rem;
  font-weight: bold;
  color: #333333;
}

.game-detail .game-explain .item .head .icon-tips {
  padding: 0 4px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #f3d08c;
  text-align: center;
  font-size: 0.2rem;
  color: #ffffff;
}

.game-detail .game-explain .item .head .info {
  border: 1px solid #ffc000;
  padding: 2.5px 5px;
  margin-right: 5px;
  font-size: 12px;
}
.game-detail .game-explain .item .head .tips {
  color: #ffc000;
}
.game-detail .game-explain .item .head .operate {
  color: #ffc000;
}

.game-detail .game-explain .item .main-container {
  margin-top: 12px;
  padding: 8px 13px 8px 8px;
  background-color: #f8f8f8;
}

.game-detail .game-explain .item .body {
  max-height: 2.52rem;
  position: relative;
  overflow: hidden;
  transition: all 0.25s;
}
.game-detail .game-explain .item .bodyTwo {
  max-height: 1.68rem;
}
.game-detail .game-explain .item .bodyThree {
  max-height: 1.68rem;
  padding-right: 22px;
}
.game-detail .game-explain .item .body.more {
  max-height: 2048px;
  /* padding-bottom: 25px; */
}

.game-detail .game-explain .item .body .more,
.game-detail .game-explain .item .bodyTwo .more {
  display: block;
  position: absolute;
  z-index: 22;
  width: 20px;
  height: 20px;
}

.game-detail .game-explain .item .body .more {
  right: 0;
  bottom: 5px;
  background-color: #f8f8f8;
}

.game-detail .game-explain .item .bodyTwo .more {
  right: 13px;
  bottom: 0;
  background-color: #fff;
}

.game-detail .game-explain .item .bodyThree .more {
  right: 13px;
  bottom: 0;
  background-color: #fff;
}

.game-detail .game-explain .item .body .entity {
  font-size: 0.26rem;
  font-weight: normal;
  line-height: 21px;
  color: #3d3d3d;
}

.game-detail .game-explain .item .body .ty-one {
  font-size: 0.26rem;
  line-height: 21px;
  color: #673f1c;
}

.game-vip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin: 8px 12px 0;
  border-radius: 10px;
  background-color: #fff;
}

.game-vip .vip-left {
  display: flex;
  align-items: center;
}

.game-vip .vip-left .title {
  font-size: 0.26rem;
  font-weight: 500;
  color: rgba(61, 61, 61, 0.8);
}

.game-vip img {
  width: 44px;
}

.game-detail .game-main-comment {
  padding: 0;
}

.game-detail .game-main-gift {
  background-color: #eee;
}
.game-detail .game-gift {
  padding: 0 10px 10px 10px;
}
.game-detail .game-gift .groups {
  overflow: hidden;
}
.game-detail .game-gift .group {
  margin-top: 12.5px;
}
.game-detail .game-gift .group > .name {
  font-size: 17px;
  font-weight: bold;
  color: #000;
  line-height: 1.25;
}
.game-detail .game-gift .item {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}
.game-detail .game-gift .item .info {
  flex: 1;
  width: 0;
}
.game-detail .game-gift .item .name {
  display: flex;
}
.game-detail .game-gift .item .name .named {
  font-weight: bold;
  font-size: 15px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-detail .game-gift .item .name .special {
  display: flex;
  align-items: stretch;
  margin-left: 10px;
  overflow: hidden;
  border-radius: 4px;
  line-height: 1.1;
}
.game-detail .game-gift .item .name .special .label {
  background: linear-gradient(
    180deg,
    #525058 0%,
    #525058 0%,
    #2d2b37 100%,
    #2d2b37 100%
  );
  color: #fff;
  padding: 2.5px 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  white-space: nowrap;
}
.game-detail .game-gift .item .name .special .value {
  background: linear-gradient(
    106deg,
    #fcf7df 3%,
    #fcf7df 3%,
    #f3d08c 75%,
    #fdefbe 96%,
    #fdefbe 96%
  );
  color: #d69c27;
  padding: 2.5px 5px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  white-space: nowrap;
}
.game-detail .game-gift .item .desc {
  margin-top: 2.5px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-detail .game-gift .item .operate {
  text-align: center;
  margin-left: 15px;
  background-color: #ff3c3c;
  border-radius: 10px;
  padding: 5px 10px;
  color: #fff;
}
.game-detail .game-gift .item .operate:before {
  content: "立即领取";
}
.game-detail .game-gift .item .operate.nothing {
  background: #ccc;
}
.game-detail .game-gift .item .operate.nothing:before {
  content: "已领完";
}
.game-trade .item {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.game-trade .icon {
  width: 80px;
  height: 55px;
  margin-right: 10px;
}
.game-trade .icon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-trade .data {
  flex: 1;
  width: 0;
  line-height: 1.25;
}
.game-trade .text {
  flex-direction: column;
  justify-items: center;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-trade .time {
  color: #ccc;
  font-size: 13px;
  margin-top: 2.5px;
}
.game-trade .price {
  margin-top: 2.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.game-trade .price .amount {
  text-decoration: line-through;
  color: #ccc;
  font-size: 12px;
  margin-right: 10px;
}
.game-trade .price .priced {
  color: #ff3c3c;
  font-size: 0.3rem;
}

.game-detail .game-tools .tools-every {
  margin-bottom: 10px;
  padding: 20px 0 0;
  background-color: #fff;
  border-radius: 8px;
}

.game-detail .game-tools .tools-every .tools-top {
  padding: 0 15px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.8px solid #f3f3f3;
}

.game-detail .game-tools .tools-every .tools-top .top-left {
  display: flex;
  align-items: center;
}

.game-detail .game-tools .tools-every .tools-top .top-left .icon {
  width: 0.64rem;
  height: 0.64rem;
  max-width: 0.64rem;
  margin-right: 15px;
}

.game-detail .game-tools .tools-every .tools-top .top-left .name {
  font-size: 0.28rem;
  font-weight: 500;
  color: #333333;
}

.game-detail .game-tools .tools-every .tools-top .top-left .title {
  font-size: 0.24rem;
  font-weight: normal;
  color: #999999;
}

.game-detail .game-tools .tools-every .tools-top .top-btn {
  padding: 5px 13px;
  border-radius: 253px;
  background-color: rgba(243, 174, 0, 0.2);
  font-size: 0.24rem;
  font-weight: 500;
  color: #f3ae00;
}

.game-detail .game-tools .introduce {
  padding: 12px 15px;
  font-size: 0.24rem;
  font-weight: normal;
  line-height: 19px;
  color: #999999;
}

.game-detail .game-tools .operation {
  padding: 12px 0;
  text-align: center;
  font-size: 0.2rem;
  font-weight: normal;
  color: #999999;
}

.game-detail .game-voucher {
  padding: 12px 16px;
}

.game-detail .game-voucher .monthcard {
  display: block;
  position: relative;
  padding-top: 23.323%;
}
.game-detail .game-voucher .monthcard img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.game-detail .game-voucher .circulation {
  margin: 12px 0;
  font-size: 0.22rem;
  color: #ff3c3c;
}

.game-detail .game-voucher .item {
  margin-bottom: 15px;
  display: flex;
  overflow: hidden;
  background: center no-repeat;
  background-size: 100% 100%;
  border-radius: 5px;
}

.game-detail .game-voucher .item:last-child {
  margin-bottom: env(safe-area-inset-bottom);
}
.game-detail .game-voucher .item .amounts {
  width: 30%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.game-detail .game-voucher .item .amounts .amount {
  font-size: 0.48rem;
  font-weight: bold;
}
.game-detail .game-voucher .item .amounts .amount-meet {
  margin-top: 5px;
  font-size: 0.24rem;
}
.game-detail .game-voucher .item .info {
  flex: 1;
  padding: 10px 10px 10px 15px;
}
.game-detail .game-voucher .item .info .name {
  font-size: 0.32rem;
  font-weight: 500;
  color: #3d3d3d;
}
.game-detail .game-voucher .item .info .game {
  font-size: 0.28rem;
  color: rgba(61, 61, 61, 0.9);
}
.game-detail .game-voucher .item .info .time {
  margin-top: 1px;
  color: #999;
  font-size: 0.24rem;
  color: rgba(61, 61, 61, 0.6);
}

.game-detail .game-voucher .item .info .use {
  color: #f5842d;
}
.game-detail .game-voucher .item .operate {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 35px;
  text-align: center;
  line-height: 1.25;
  color: #fff;
  background: #ff3c3c;
}
.game-detail .game-voucher .item .operate.disable {
  background: linear-gradient(118deg, #dedede 27%, #bebebe 79%);
  color: #3d3d3d;
}
.game-detail.no-video {
  padding-top: calc(env(safe-area-inset-top) + 48px);
}
.game-detail.no-video .game-video {
  display: none;
}
.game-detail .game-skeleton {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.game-detail .game-skeleton:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 60%
  );
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s game-loading ease-in-out infinite;
}
.game-detail .game-skeleton .a {
  padding-top: 60%;
  background-color: #eee;
}
.game-detail .game-skeleton .b {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}
.game-detail .game-skeleton .b img {
  width: 75%;
}
@keyframes game-loading {
  to {
    background-position-x: -20%;
  }
}

.game-ios-guide {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.game-ios-guide .giu-container {
  background-color: #fff;
  padding-bottom: 12.5px;
  border-radius: 12px;
  box-shadow: 0 0 3px #ccc;
  min-width: 75%;
  max-width: 85%;
}
.game-ios-guide .giu-container .giu-titlle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 26px 0;

  font-size: 0.36rem;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #3d3d3d;
}
.game-ios-guide .giu-container .giu-titlle .title-font {
  flex: 1;
  text-align: center;
}
.game-ios-guide .giu-container .giu-tips {
  padding: 3px 3px 8px;
  margin: 26px 16px 20px;
  border-radius: 4px;
  background: #fff3f3;

  font-size: 0.28rem;
  text-align: center;
  color: #333333;
}
.game-ios-guide .giu-container .btn {
  flex: 1;
  background-color: #fcf15d;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 55px 0;
  border-radius: 20px;
  color: #3d3d3d;
  transition: all 0.1s;
  flex-direction: column;
  text-align: center;
  padding: 11.5px 0;
}

.down-title {
  flex: 1 1;

  font-size: 0.36rem;
  font-weight: bold;
  text-align: center;
  color: #3d3d3d;
  letter-spacing: 1px;
}

.down-desc {
  font-size: 0.28rem;
  font-weight: bold;
  text-align: left;
  color: #3d3d3d;
}

.down-desc .down-every {
  margin-top: 15px;
}

.down-desc .down-schedule-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.down-desc .down-schedule-img .schedule-img {
  position: relative;
  width: 1rem;
  height: 1rem;
}

.down-desc .down-schedule-img .schedule-img .gameImg,
.down-desc .down-schedule-img .schedule-img .black {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
}

.down-desc .down-schedule-img .schedule-img .black {
  z-index: 99;
}

/* 下载相关 */
.game-super {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.game-super .gs-container {
  background-color: #fff;
  padding: 12.5px;
  border-radius: 12px;
  box-shadow: 0 0 3px #ccc;
  min-width: 75%;
}
.game-super .gs-container .item {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 5px;
}
.game-super .gs-container .item:before {
  content: "";
  display: block;
  flex: 1;
}
.game-super .gs-container .item:nth-child(1):before {
  content: "打包中";
}
.game-super .gs-container .item:nth-child(2):before {
  content: "签名中";
}
.game-super .gs-container .item:nth-child(3):before {
  content: "下载中";
}
.game-super .gs-container .item .icon.normal {
  display: block;
}
.game-super .gs-container .item .icon.ing {
  display: none;
}
.game-super .gs-container .item .icon.ed {
  display: none;
}
.game-super .gs-container .item.ing .icon.normal {
  display: none;
}
.game-super .gs-container .item.ing .icon.ing {
  display: block;
}
.game-super .gs-container .item.ing .icon.ed {
  display: none;
}
.game-super .gs-container .item.ed .icon.normal {
  display: none;
}
.game-super .gs-container .item.ed .icon.ing {
  display: none;
}
.game-super .gs-container .item.ed .icon.ed {
  display: block;
}
.game-super .gs-container .udid {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.game-super .gs-container .udid .udidd {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 15px;
  font-size: 12px;
}
.game-super .gs-container .udid .btn {
  font-size: 12px;
  color: orangered;
}
.game-super .gs-container .tips {
  color: orangered;
  opacity: 0.75;
  margin-top: 10px;
  text-align: center;
}

.game-new-super {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.6);
}

.game-new-super .new-super-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 11px 12px 30px;
  border-radius: 15px 15px 0px 0px;
}

.game-new-super .cancel-btn {
  width: 100%;
  padding-right: 5px;
  font-size: 0.28rem;
  color: #5788fe;
  text-align: right;
}

.game-new-super .super-game-msg {
  width: 100%;
  padding: 7px 8px;
  margin-top: 11px;
  display: flex;
  align-items: center;
  background: #f1f1f1;
}

.game-new-super .super-game-msg img {
  width: 0.76rem;
  height: 0.76rem;
  border-radius: 10px;
}

.game-new-super .super-game-msg .name {
  margin-left: 8px;
  font-size: 0.3rem;
  font-weight: 500;
  color: #3d3d3d;
  font-weight: bold;
}

.game-new-super .super-game-progress {
  width: 271px;
  height: 35px;
  margin-top: 24px;
  border-radius: 184px;
  background: #dddddd;
  text-align: center;
  line-height: 35px;
  position: relative;
}

.game-new-super .super-game-progress .progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 184px;
  background: #76a8f1;
}

.game-new-super .super-game-progress .font {
  position: relative;
  z-index: 99;
  font-size: 0.32rem;
  color: #ffffff;
}

.game-new-super .tips {
  margin-top: 12px;
  font-size: 0.24rem;
  font-weight: 500;
  text-align: center;
  color: #e76259;
}

.game-new-super .udid-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-size: 0.24rem;
  text-align: center;
  color: #3d3d3d;
}

.game-new-super .udid-msg p {
  margin-left: 28px;
  font-size: 0.28rem;
  color: #5788fe;
}

.nosign-game-tips {
  padding: 16px 17px;
  margin-top: 5px;
  border-radius: 4px;
  background: rgba(196, 196, 196, 0.3);
  font-size: 0.28rem;
  font-weight: 500;
  text-align: center;
  color: #333333;
}

.nosign-tips-img {
  margin-top: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nosign-tips-img img {
  width: 48%;
}

.nosign-game .down-desc {
  font-size: 0.32rem;
  font-weight: 500;
  color: #333333;
}

.super-game-container {
  max-width: 100vw;
  height: auto;
  max-height: 100vh;
  padding: 0 0 40px;
  border-radius: 16px 16px 0 0;
  background-color: #fff;
}

.super-game-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 0.4rem;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 1px;
}

.super-game-tips {
  margin-top: 16px;
  font-size: 0.28rem;
  font-weight: 500;
  text-align: center;
  color: #333333;
}

.super-game-tips .title {
  margin-bottom: 8px;
  font-size: 0.36rem;
  font-weight: bold;
  color: #383838;
  text-align: left;
}

.super-game-tips .tips-step-one {
  display: flex;
  justify-content: space-between;
}

.super-game-tips .tips-step-one .step-every {
  width: 48%;
}

.super-game-tips .tips-step-one .step-every .step-eveery-font {
  font-size: 0.28rem;
  font-weight: 500;
  color: #666666;
  text-align: left;
}

.super-game-tips .tips-step-one .step-every .serial {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  line-height: 0.4rem;
  border-radius: 50%;
  background-color: #6589ef;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
}

.super-game-tips .tips-step-one .step-every img {
  width: 100%;
}

.super-game-btn {
  padding: 10px;
  margin: 17px 19px 0;
  border-radius: 21.5px;
  background: #5676ee;
  font-size: 0.32rem;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
}
