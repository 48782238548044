.comment-container {
  min-height: calc(100vh - env(safe-area-inset-top) - 45px);
  padding: 21px 16px;
  background-color: #fff;
}

.game-detail .game-qa {
  background-color: #f4f6f9;
  padding: 11px 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.game-detail .game-qa .text {
  flex: 1;
}
.game-detail .game-qa .info1 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.26rem;
  font-weight: 500;
  color: #3d3d3d;
}
.game-detail .game-qa .info2 {
  width: 100%;
  margin-top: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.22rem;
  color: #999999;
}
.game-detail .game-qa .operate {
  font-size: 0.24rem;
  color: #ff3c3c;
}

.game-detail .game-comment {
  background-color: #f8f8f8;
}
.game-detail .game-comment .comment-title {
  margin-top: 18px;
  font-size: 0.28rem;
  font-weight: bold;
  color: #3d3d3d;
  background-color: #fff;
}
.game-detail .game-comment .comment-item {
  display: block;
  padding-top: 16px;
  background-color: #fff;
  position: relative;
}
.game-detail .game-comment .comment-item:first-child {
  margin-top: 0;
}
.game-detail .game-comment .comment-item:last-child .comment-main-content {
  border-bottom: none;
}
.game-detail .game-comment .comment-reward {
  position: absolute;
  z-index: 11;
  right: 15px;
  top: 5px;
  background: #fff center no-repeat;
  background-size: cover;
  width: 67px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  color: #5574ff;
}
.game-detail .game-comment .comment-user {
  display: flex;
  align-items: center;
}
.game-detail .game-comment .comment-user .avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 50%;
}
.game-detail .game-comment .comment-user .avatar img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-detail .game-comment .comment-user .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.game-detail .game-comment .comment-user .info .name {
  font-size: 0.28rem;
  font-weight: bold;
  color: #3d3d3d;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-detail .game-comment .comment-user .info .name img {
  margin-left: 5px;
  height: 25px;
}
.game-detail .game-comment .comment-user .info .count {
  font-size: 0.22rem;
  color: #999999;
}
.game-detail .game-comment .comment-user .time {
  font-size: 0.26rem;
  color: #999999;
}
.game-detail .game-comment .comment-main-content {
  margin-left: 40px;
  padding-bottom: 17px;
  border-bottom: 1.25px solid rgba(216, 216, 216, 0.5);
}
.game-detail .game-comment .comment-content {
  margin-top: 9px;
}
.game-detail .game-comment .comment-content .content {
  max-height: 100px;
  overflow: hidden;
  transition: all 0.25s;
  font-size: 0.26rem;
  color: #3d3d3d;
}
.game-detail .game-comment .comment-content .ctrl:after {
  content: "全文";
  color: #ffc000;
  display: none;
}
.game-detail .game-comment .comment-content.switchable .ctrl:after {
  display: block;
}
.game-detail .game-comment .comment-content.open .content {
  max-height: 999px;
}
.game-detail .game-comment .comment-content.open .ctrl:after {
  content: "收起";
}
.game-detail .game-comment .comment-img {
  margin-top: 11px;
  display: flex;
  flex-wrap: wrap;
}
.game-detail .game-comment .comment-img .item {
  width: 1.86rem;
  height: 1.28rem;
  margin-top: 5px;
  margin-right: 5px;
}
.game-detail .game-comment .comment-img .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.game-detail .game-comment .comment-reply {
  margin-top: 12px;
  padding: 10px 3px 11px 8px;
  border-radius: 8px;
  background: #f9f9f8;
}
.game-detail .game-comment .comment-reply .reply-item {
  display: flex;
  margin-top: 5px;
  font-size: 0.26rem;
}
.game-detail .game-comment .comment-reply .reply-item .reply-user {
  color: #fa8a22;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.game-detail .game-comment .comment-reply .reply-item .reply-content {
  flex: 1;
  width: 0;
  word-break: break-all;
  color: #3d3d3d;
}
.game-detail .game-comment .comment-reply .reply-more {
  margin-top: 5px;
  color: #ffc000;
}
.game-detail .game-comment .comment-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.game-detail .game-comment .comment-info .device {
  flex: 1;
  width: 0;
  overflow: hidden;
  font-size: 0.22rem;
  color: #999999;
}
.game-detail .game-comment .comment-info .count {
  display: flex;
  align-items: center;
}
.game-detail .game-comment .comment-info .count .like {
  margin-left: 26px;
}
.game-detail .game-comment .comment-info .count .item {
  display: flex;
  align-items: center;
}
.game-detail .game-comment .comment-info .count .item .icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.game-detail .game-comment .comment-info .count .icon {
  margin-right: 5px;
}
