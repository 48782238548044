.game-hangup {
  min-height: calc(100vh - env(safe-area-inset-top) - 48px);
  padding: 24px 20px;
  background-color: #f5f5f5;
}

.game-hangup .no-unable-hangup {
  padding: 47px 19px 32px;
  border-radius: 20px;
  background-color: #e4e5e9;
}

.game-hangup .no-unable-hangup .title {
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #333333;
}

.game-hangup .no-unable-hangup .tips {
  margin-top: 15px;
  font-size: 0.24rem;
  color: #333333;
  text-align: center;
}

.game-hangup .no-unable-hangup .character {
  margin-top: 36px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-hangup .no-unable-hangup .character img {
  display: block;
  width: 2.06rem;
}

.game-hangup .no-unable-hangup .character span {
  margin: 0 10px;
  font-size: 0.24rem;
  color: #333333;
}

.game-hangup .no-unable-hangup .character-every {
  margin-top: 12px;
  padding: 25px 21px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  background-color: rgba(246, 247, 249, 0.8);
}

.game-hangup .no-unable-hangup .character-every img {
  width: 0.76rem;
  height: 0.76rem;
  max-width: 0.76rem;
  margin-right: 20px;
  display: block;
}

.game-hangup .no-unable-hangup .character-every .every-title {
  font-size: 0.32rem;
  font-weight: 500;
  color: #333333;
}

.game-hangup .no-unable-hangup .character-every .every-tips {
  font-size: 0.24rem;
  font-weight: normal;
  color: #666666;
}

.game-hangup .unable-hangup .unable-container .unable-main {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #e4e5e9;
  background-size: cover;
  background-repeat: no-repeat;
}

.game-hangup .unable-hangup .unable-container .unable-main .main-top {
  padding: 22px 20px 22px 14px;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.game-hangup
  .unable-hangup
  .unable-container
  .unable-main
  .main-top
  .main-equipment-info
  .info-name {
  font-size: 0.36rem;
  font-weight: 500;
}

.game-hangup
  .unable-hangup
  .unable-container
  .unable-main
  .main-top
  .main-equipment-info
  .info-name
  img {
  width: 0.32rem;
  max-width: 0.32rem;
}

.game-hangup
  .unable-hangup
  .unable-container
  .unable-main
  .main-top
  .main-equipment-info
  .time {
  margin-top: 4px;
  font-size: 0.24rem;
  font-weight: 500;
}

.game-hangup
  .unable-hangup
  .unable-container
  .unable-main
  .main-top
  .main-equipment-info
  .status {
  width: fit-content;
  margin-top: 10px;
  padding: 5px 12px;
  border-radius: 13px;
  background-color: #010101;
  font-size: 0.24rem;
  font-weight: 500;
}

.game-hangup
  .unable-hangup
  .unable-container
  .unable-main
  .main-top
  .main-equipment-info
  .status.green {
  color: #69c98e;
}

.game-hangup
  .unable-hangup
  .unable-container
  .unable-main
  .main-top
  .main-equipment-info
  .status.red {
  color: #ff3c3c;
}

.game-hangup .unable-hangup .unable-container .unable-main .main-top .faq {
  font-size: 0.48rem;
  color: #666;
}

.game-hangup
  .unable-hangup
  .unable-container
  .unable-main
  .main-top
  .faq.white {
  color: #fff;
}

.game-hangup .unable-hangup .unable-container .unable-btns {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.game-hangup .unable-hangup .unable-container .unable-btns .btns-every {
  padding: 12px 0;
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  font-weight: 500;
  color: #333333;
}

.game-hangup .unable-hangup .unable-container .unable-btns .btns-every img {
  width: 0.3rem;
  max-width: 0.3rem;
  margin-right: 5px;
}

.game-hangup .unable-hangup .unable-container .unable-btns .line {
  width: 1px;
  height: 40px;
  background-color: #f3f3f3;
}

.game-hangup .unable-hangup .unable-container .main-no-game {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-hangup .unable-hangup .unable-container .main-no-game img {
  width: 1.6rem;
}

.game-hangup .unable-hangup .unable-container .main-no-game .title {
  font-size: 0.36rem;
  font-weight: 500;
  color: #999999;
}

.game-hangup .unable-hangup .unable-container .main-no-game .sel-btn {
  margin-top: 12px;
  padding: 5px 10px;
  border-radius: 13px;
  border: 1px solid #f3ae00;
  font-size: 0.24rem;
  font-weight: normal;
  color: #f3ae00;
}

.game-hangup .hangup-btn {
  width: 100%;
  padding: 14px 0;
  margin-top: 50px;
  border-radius: 175px;
  background-color: #fcf15d;
  font-size: 0.3rem;
  font-weight: 500;
  color: #3d3d3d;
  text-align: center;
}

.game-hangup .hangup-btn.unable-big-btn {
  width: 80%;
  margin: 31px auto 0;
}

.game-hangup .check-btn {
  width: fit-content;
  margin: 20px auto 0;
  font-size: 0.24rem;
  font-weight: normal;
  color: #666666;
}

.hangup-small-btn {
  padding: 5px 13px;
  border-radius: 253px;
  background-color: rgba(243, 174, 0, 0.2);
  font-size: 0.24rem;
  font-weight: 500;
  color: #f3ae00;
}

.my-equipment-list .sel-equ {
  display: flex;
  align-items: center;
}

.my-equipment-list .sel-equ .equ-name {
  margin-right: 4px;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  font-weight: 500;
  color: #222;
  cursor: pointer;
}

.my-equipment-list .sel-equ .equ-name img {
  width: 13px;
  max-width: 13px;
}

.my-equipment-list .all-equipment {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 9999;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  gap: 8px;
  background-color: #f4f4f4;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}

.my-equipment-list .all-equipment .all-every {
  min-width: 75px;
  height: 39px;
  font-size: 0.32rem;
  font-weight: normal;
  color: #1a1a1a;
  text-align: center;
  line-height: 39px;
}

.my-equipment-list .all-equipment .all-every.active {
  font-weight: bold;
  color: #000;
}

.update-euq-name {
  padding: 0 24px;
}

.update-euq-name input {
  width: 100%;
  height: 40px;
  padding: 0 9.5px;
  border-radius: 5px;
  opacity: 1;
  background-color: #f6f6f6;
  border: none;
}

.update-euq-name p {
  margin-top: 12px;
  font-size: 0.24rem;
  font-weight: normal;
  color: #999999;
}
