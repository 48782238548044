.user-setting {
  min-height: calc(100vh - env(safe-area-inset-top) - 40px);
  padding-top: 4px;
  background-color: #f5f6f8;
}

.user-setting .item {
  display: block;
  padding: 0 20px;
  background-color: #fff;
}

.user-setting .item .item-main {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(216, 216, 216, 0.5);
  font-size: 0.24rem;
  color: #999999;
}

.user-setting .item .item-main .name {
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
  display: flex;
  align-items: center;
}

.user-setting .item .item-main .name img {
  display: block;
  width: 22px;
  margin-right: 8px;
}
