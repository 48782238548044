.user-index {
  background-color: #fff;
  min-height: calc(100vh - env(safe-area-inset-bottom) - 65px);
  padding: 12.5px 15px 10px;
}
.user-index .user-head {
  padding-top: env(safe-area-inset-top);
  background: center no-repeat;
  background-size: cover;
}
.user-index .user-head .container {
  position: relative;
  padding: 20px 6px 0;
}
.user-index .user-head .operate {
  position: absolute;
  right: 3%;
  top: 6%;
  z-index: 999;
}
.user-index .user-head .operate .item {
  color: #585858;
  font-size: 20px;
  padding: 2.5px;
  position: relative;
}
.user-index .user-head .operate .item.active:before {
  content: "";
  display: block;
  position: absolute;
  top: 10%;
  right: 10%;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
}
.user-index .user-head .user {
  display: flex;
  align-items: center;
}
.user-index .user-head .user .icon {
  width: 71px;
  height: 71px;
  margin-right: 7px;
  position: relative;
}
.user-index .user-head .user .icon img {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-index .user-head .user .icon .vip {
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  font-size: 10px;
  background: linear-gradient(
    180deg,
    #525058 0%,
    #525058 0%,
    #2d2b37 100%,
    #2d2b37 100%
  );
  line-height: 1.1;
  text-align: center;
  border-radius: 20px;
  color: #f2ebdb;
}
.user-index .user-head .user .icon .vip img {
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.user-index .user-head .user .info {
  flex: 1;
  width: 0;
}
.user-index .user-head .user .name {
  display: flex;
  align-items: flex-start;
}
.user-index .user-head .user .name .named {
  max-width: 120px;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #3d3d3d;
  font-weight: 500;
}
.user-index .user-head .user .name .growth {
  display: flex;
  margin-left: 5px;
}
.user-index .user-head .user .name .growth img {
  display: block;
  height: 22px;
}
.user-index .user-head .user .name .growth .receive_icon {
  margin-top: 4px;
  position: relative;
  z-index: 11;
  transform: translate(-25%, 50%);
}
.user-index .user-head .user .name .growth .receive_icon img {
  height: 20px;
  max-width: unset;
}
.user-index .user-head .user .account {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999999;
  margin-top: -3px;
}
.user-index .user-head .user .phone {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999999;
}
.user-index .user-head .user .empty {
  font-size: 0.32rem;
  font-weight: bold;
}
.user-index .user-vip {
  margin-top: 10px;
}
.user-index .user-vip img {
  display: block;
  width: 100%;
}
.user-index .user-economy {
  margin-top: 32px;
  padding: 0 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.user-index .user-economy .line {
  width: 1px;
  height: 23px;
  background-color: #d8d8d8;
}

.user-index .user-economy .item {
  /* flex: 1;
  width: 33.333%; */
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-index .user-economy .item .value {
  position: relative;
  font-weight: 900;
  font-size: 0.32rem;
  color: #333333;
}

.user-index .user-economy .item .value span {
  font-weight: 500;
  font-size: 0.24rem;
  color: #666666;
}

.user-index .user-economy .item .name {
  margin-top: 8px;
  font-size: 0.24rem;
  font-weight: normal;
  color: #666666;
}

.user-index .user-economy .item .option {
  border: 1px solid #eee;
  border-radius: 10px;
  width: 75%;
  text-align: center;
}
.user-index .user-economy .item:nth-child(1) .option {
  color: #dcb047;
  border-color: #dcb047;
}
.user-index .user-economy .item:nth-child(2) .option {
  color: #3dc9a4;
  border-color: #3dc9a4;
}
.user-index .user-economy .item:nth-child(3) .option {
  color: #e09af4;
  border-color: #e09af4;
}
.user-index .user-economy .item:nth-child(4) .option {
  color: #6c8af7;
  border-color: #6c8af7;
}
.user-index .user-economy .item.active .value:after {
  content: "";
  display: block;
  position: absolute;
  top: 2.5px;
  right: -10px;
  width: 5px;
  height: 5px;
  background-color: orangered;
  border-radius: 50%;
}

.user-index .user-he-entrance {
  display: flex;
  padding: 18px 14px;
  margin-top: 19px;
  border-radius: 15px;
  background: linear-gradient(102deg, #fcf7df 0%, #f3d08c 74%, #fdefbe 95%);
}

.user-index .user-he-entrance .user-h5-every {
  width: 50%;
  display: flex;
  align-items: center;
}

.user-index .user-he-entrance .user-h5-every .icon {
  width: 24px;
  margin-right: 9px;
}

.user-index .user-he-entrance .user-h5-every .title {
  width: 64px;
  display: block;
}

.user-index .user-he-entrance .user-h5-every .card {
  width: 48px;
  display: block;
}

.user-index .user-he-entrance .user-h5-every .tips {
  margin-top: 6px;
  font-size: 0.22rem;
  font-weight: normal;
  color: #714c39;
}

.user-index .user-banner {
  display: block;
  margin-top: 10px;
  padding-top: 19.3%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.user-index .user-banner .swiper-container {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.user-index .user-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-index .user-hangup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 20px 22px;
  border-radius: 15px;
  background: linear-gradient(90deg, #e7e8fe 0%, #e2d2fe 70%, #acc2fd 100%);
}

.user-index .user-hangup .hangup-left {
  display: flex;
  align-items: center;
  font-size: 0.22rem;
  font-weight: normal;
  color: #4f40f0;
}

.user-index .user-hangup .hangup-left img {
  width: 48px;
  display: block;
  margin-right: 21px;
}

.user-index .user-general {
  margin-top: 23.5px;
}
.user-index .user-general .title {
  font-size: 0.36rem;
  font-weight: bold;
  color: #333;
}

.user-index .user-general .items {
  border-radius: 8px;
  margin-top: 10px;
}
.user-index .user-general .item {
  float: left;
  width: 25%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.user-index .user-general .item .icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.user-index .user-general .item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.user-index .user-general .item .text {
  margin-top: 5px;
  font-size: 0.26rem;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-index .user-general .item.active:after {
  content: "";
  position: absolute;
  z-index: 23;
  top: 12.5%;
  right: 25%;
  background-color: red;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}
.user-index .user-more {
  margin-top: 10px;
}
.user-index .user-more .title {
  font-size: 0.36rem;
  font-weight: bold;
  color: #333;
}

.user-index .user-more .items {
  border-radius: 8px;
  margin-top: 10px;
}
.user-index .user-more .item {
  float: left;
  width: 25%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.user-index .user-more .item .icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.user-index .user-more .item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.user-index .user-more .item-favorite .icon img {
  max-width: 75%;
  max-height: 75%;
}
.user-index .user-more .item .text {
  margin-top: 5px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-index .user-more .item.active:after {
  content: "";
  position: absolute;
  z-index: 23;
  top: 7.5%;
  right: 17.5%;
  background-color: red;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}
