.search-index {
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #f6f7f9;
}

.ranking-font {
  margin: 27px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.24rem;
  font-weight: 500;
  color: #fa8a22;
}

.ranking-font .icon {
  width: 16px;
  height: 15px;
  margin-right: 5px;
}

.apply-new-game {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 36px;
  width: 175px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 22px;
  background: #fcf15d;

  font-size: 0.28rem;
  color: #3d3d3d;
  cursor: pointer;
}

.apply-new-game .icon {
  width: 16px;
  height: 16px;
  margin-left: 3px;
}
.search-index .search-cover {
  min-height: calc(100vh - env(safe-area-inset-top) - 48px);
  position: relative;
  overflow: hidden;
  display: none;
  padding: 10px;
}
.search-index .search-cover.active {
  display: block;
}
.search-index .search-cover .title {
  font-size: 0.3rem;
  font-weight: bold;
}
.search-index .search-cover .kws {
  margin-top: 10px;
}
.search-index .search-cover .kws .kw {
  background-color: #eee;
  padding: 3.5px 6.5px;
  border-radius: 8px;
  float: left;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.search-index .search-cover .kws .kw .icon {
  margin-right: 5px;
}
.search-index .search-cover .kws .kw:first-child .icon {
  color: red;
}
.search-index .search-cover .kws .kw:nth-child(2) .icon {
  color: green;
}
.search-index .search-cover .groups {
  margin-top: 10px;
}
.search-index .search-cover .groups .group {
  float: left;
  width: 50%;
}
.search-index .search-cover .groups .group:first-child {
  border-right: 1px solid #eee;
  padding-right: 5px;
}
.search-index .search-cover .groups .group:last-child .title {
  padding-left: 10px;
}
.search-index .search-cover .games {
  margin-top: 10px;
}
.search-index .search-cover .games .game {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
}
.search-index .search-cover .games .game .index {
  width: 25px;
  text-align: center;
  font-size: 0.28rem;
  font-weight: bold;
}
.search-index .search-cover .games .game:nth-child(1) .index,
.search-index .search-cover .games .game:nth-child(2) .index,
.search-index .search-cover .games .game:nth-child(3) .index {
  color: red;
}
.search-index .search-cover .games .game .icon {
  flex-shrink: 0;
  width: 0.88rem;
  height: 0.88rem;
  border-radius: 0.2rem;
  overflow: hidden;
  margin-right: 0.12rem;
}
.search-index .search-cover .games .game .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.search-index .search-cover .games .game .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search-index .search-cover .games .game .name {
  font-size: 0.26rem;
  color: #000;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-index .search-cover .games .game .branch {
  color: red;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}
.search-index .search-cover .games .game .desc {
  font-size: 0.24rem;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-index .search-result {
  min-height: calc(100vh - env(safe-area-inset-top) - 48px);
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 10px 10px 0;
  display: none;
}
.search-index .search-result.active {
  display: block;
}
.search-index .search-result .item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}
.search-index .search-result .item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.search-index .search-result .item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.search-index .search-result .item .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
}
.search-index .search-result .item .icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: deeppink;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  max-width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  padding: 1px 2.5px;
}
.search-index .search-result .item .subject {
  flex: 1;
  width: 0;
  border-bottom: 1px solid #dedede;
  padding-bottom: 0.2rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.search-index .search-result .item .data {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.search-index .search-result .item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  color: #282828;
  margin-bottom: 0.05rem;
}
.search-index .search-result .item .desc {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.search-index .search-result .item .desc .branch {
  display: inline;
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.search-index .search-result .item .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.24rem;
  color: #ff5e00;
  margin-bottom: 0.05rem;
}
.search-index .search-result .item .tag {
  display: inline-block;
  padding-left: 5px;
  color: #75d100;
}
.search-index .search-result .item .tag:first-child {
  padding-left: 0;
}
.search-index .search-result .item .tag:nth-child(1) {
  color: #ff4200;
}
.search-index .search-result .item .tag:nth-child(2) {
  color: #75d100;
}
.search-index .search-result .item .tag:nth-child(3) {
  color: #2893ff;
}
.search-index .search-result .item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.search-index-btn {
  font-size: 0.32rem;
  color: #3d3d3d;
}
.search-index-input .swiper-container {
  width: 100%;
  height: 28px;
  margin: 0;
}
.search-index-input {
  padding: 4.5px 10px 4.5px 15px;
  border: none;
  background-color: #f7f7f7;
  border-radius: 299px;
  display: flex;
  align-items: center;
}
.search-index-input .search-index-input-icon {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}
.search-index-input .search-index-input-main {
  position: relative;
  flex: 1;
  height: 28px;
}
.search-index-input-main-swiper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.search-index-input-main-swiper-slide {
  width: 100%;
  height: 28px;
  text-align: left;
}
.search-index-input-main-swiper-slide div {
  width: 100%;
  height: 28px;
  line-height: 28px;
  color: #d1d1d1;
  font-size: 12px;
}
.search-index-input-main-input {
  width: 100%;
  height: 28px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 77;
  color: #d1d1d1;
  font-size: 12px;
  flex: 1;
  border: none;
  background: none;
  margin-right: 5px;
}
.search-index-input .close {
  padding: 5px;
  font-size: 16px;
}
.nav-view {
  min-height: 100vh;
  background-color: #fff;
  display: none;
}
.nav-view.active {
  display: block;
}
.nav-view-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  transition: all 0.1s;
  padding-top: env(safe-area-inset-top);
}
.nav-view-head .dark {
  color: #666;
}
.nav-view-head .container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 11px 5px 21px;
  height: 48px;
  /* overflow: hidden; */
}
.nav-view-head .back {
  font-size: 20.5px;
  color: #3d3d3d;
}
.nav-view-head .operate {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.nav-view-head .operate .anticon {
  font-size: 18px;
}
.nav-view-head .title {
  flex: 1;
  width: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.36rem;
  color: #282828;
  /* padding: 0 12px; */
}
.nav-view-body {
  padding-top: calc(env(safe-area-inset-top) + 48px);
  position: relative;
  min-height: calc(100vh);
}
.nav-view.invasion .nav-view-head {
  background-color: transparent;
  color: #fff;
  border-bottom: none;
}
.nav-view.invasion .nav-view-head.dark {
  color: #666;
}
.nav-view.invasion .nav-view-head.bright {
  color: #fff;
}
.nav-view.invasion .nav-view-head .title {
  color: #fff;
}
.nav-view.invasion .nav-view-body {
  padding-top: 0;
}
.search-empty {
  padding: 39px 0 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.28rem;
  color: #666;
  text-align: center;
}
.search-empty img {
  display: block;
  width: 50%;
}

.apply-container {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;

  padding: 15px 20px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: fade-in;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}

.apply-mask {
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.75;
}

.apply-close {
  margin-top: 15px;
  padding: 8px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10003;
}

.apply-close img {
  width: 18px;
}

.apply-main {
  padding: 38px 30px 35px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  z-index: 10003;
}

.apply-title {
  margin-bottom: 28px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  color: #3d3d3d;
}

.apply-btn {
  margin: 35px auto 0;
  width: 175px;
  height: 40px;
  border-radius: 22px;
  background: #fcf15d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d3d3d;
}

.apply-game-dialog .label {
  font-size: 0.28rem;
  color: #999999;
}

.apply-game-dialog .apply-input {
  width: 100%;
  margin-top: 12px;
  padding-bottom: 3px;
  border: none;
  border-bottom: 1px solid rgba(216, 216, 216, 0.5);
  background: none;
  font-size: 0.28rem;
  font-weight: 500;
  color: #3d3d3d;
}

.apply-game-dialog .tips-font {
  margin-top: 4px;
  font-size: 0.2rem;
  color: #ff3c3c;
}

.service-content-detail {
  height: calc(100vh - env(safe-area-inset-top) - 48px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-content-detail .font {
  text-align: center;
  font-size: 0.32rem;
  color: #3d3d3d;
  font-weight: 500;
}

.service-content-detail .qrcode-img {
  width: 80%;
  margin: 0 auto;
}
