.article-detail {
  padding-bottom: calc(env(safe-area-inset-bottom) + 75px);
  background-color: #f6f7f9;
}

.article-detail .article-top {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-bottom) + 48px);
  left: 0;
  right: 0;
  padding: 0 11px;
  background-color: #f6f7f9;
}

.article-detail .article-head {
  margin-top: 20px;
  padding: 18px 20px;
  border-radius: 8px;
  background: linear-gradient(116deg, #004de9 4%, #009feb 50%, #0090eb 96%);
  font-size: 0.28rem;
  color: #ffffff;
}

.article-detail .article-head .head-every {
  display: flex;
  margin-bottom: 14px;
}

.article-detail .article-head .head-every:last-child {
  margin-bottom: 0;
}

.article-detail .article-head .head-every .title {
  white-space: nowrap;
}

.article-detail .article-head .head-every .name {
  font-size: 0.3rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
}

.article-detail .article-head .head-every .branch {
  height: 16px;
  padding: 0 5px;
  margin-left: 8px;
  line-height: 16px;
  background: rgba(243, 245, 249, 0.4);
  font-size: 0.2rem;
  color: rgba(250, 251, 255, 0.8);
  border-radius: 2px;
}

.article-detail .article {
  padding: 0 11px 8px;
  font-size: 0.28rem;
}

.article-detail .article-content-title {
  margin-bottom: 12px;
  font-size: 0.32rem;
  font-weight: bold;
  color: #262525;
}

.article-detail .content {
  padding: 1px 14px;
  /* line-height: 23px; */
  background-color: #fff;
  border-radius: 10px;
  /* font-size: 0.28rem; */
  color: #3d3d3d;
}

.article-detail .content p {
  margin: 14px 0;
}

.article-detail .service-contact {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 14px 14px;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.article-detail .service-contact .contact {
  display: block;
  width: 93.35%;
  height: 44px;
  margin: 0 auto;
  border-radius: 22px;
  background-color: #fcf15d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.32rem;
  font-weight: 500;
  color: #3d3d3d;
}

.article-index .article-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.article-index .article-head .item {
  flex: 1;
  position: relative;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.article-index .article-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.article-index .article-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.article-index .article-head .item.active:after {
  display: block;
}
.article-index .article-body {
  margin-top: 45px;
  padding: 12.5px;
  position: relative;
}
.article-index .article-body .type-item {
  display: none;
}
.article-index .article-body .type-item.active {
  display: block;
}
.article-index .article-body .type-item .item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: center no-repeat;
  background-size: 100% 100%;
  border-top: 1px solid #eee;
  padding: 10px 0;
}
.article-index .article-body .type-item .item:first-child {
  border-top: none;
  padding-top: 0;
}
.article-index .article-body .type-item .item:after {
  content: ">";
  font-size: 0.3rem;
}
.article-index .article-body .type-item .item .icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.article-index .article-body .type-item .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.article-index .article-body .type-item .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.article-index .article-body .type-item .item .title {
  font-size: 0.28rem;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.article-index .article-body .type-item .item .time {
  font-size: 13px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
